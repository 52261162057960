import React,{useState,useEffect} from "react";
import stripe from '../../Images/home4/stripe-logo.svg'
import visa from '../../Images/New/visa.svg'
import master from '../../Images/New/mastercard.svg'
import express from '../../Images/New/expresscard.svg'
import logo from '../../Images/footerlogo.png'
import { useDispatch } from 'react-redux';
import  Axios from "axios";
import { useNavigate,NavLink } from 'react-router-dom';
import { Hotelapitoken,ApiEndPoint } from '../GlobalData/GlobalData';
import { fetchHotels,fetchHotelsSearh, } from '../../Redux/Actions/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
function Footer2 () {
	const [isLoading, setIsLoading] = useState(false);
	const [userLocation,setUserLocation]=useState([]);
	var token = Hotelapitoken();
	var apiendpoint=ApiEndPoint();
	const navigation=useNavigate();
	const Dispatch=useDispatch();
	useEffect(()=>{
	  getUserLocation();
	},[]);
	function getUserLocation() {
	  // Fetch user's location data
	  fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041")
		.then(response => response.json())
		.then(data => {
		  setUserLocation(data);
		  // Handle location data as needed
		})
		.catch(error => {
		  console.error('Error fetching user location:', error);
		});
	}
  
	const Searchhotels = async (name) => {
		setIsLoading(true);
		const currentDate = new Date();
		const twoDaysLater = new Date(currentDate);
		twoDaysLater.setDate(currentDate.getDate() + 2);
		const threeDaysLater = new Date(currentDate);
		threeDaysLater.setDate(currentDate.getDate() + 3);
		var destination_name='';
		 var country='';
		 var lat='';
		 var lon='';
		 var pin='';
		if(name==='Makkah'){
		 destination_name="Makkah";
		 country="Saudi Arabia";
		 lat=21.4240968;
		 lon=39.81733639999999;
		 pin="SA";
		}else if(name==='Madinah'){
		 destination_name="Madinah";
		 country="Saudi Arabia";
		 lat=24.4672132;
		 lon=39.6024496;
		 pin="SA";
		}else if(name==='Riyadh'){
		 destination_name="Riyadh";
		 country="Saudi Arabia";
		 lat=24.7135517;
		 lon=46.6752957;
		 pin="SA";
		}else if(name==='Jeddah'){
		  destination_name="Jeddah";
		  country="Saudi Arabia";
		  lat=21.5291545;
		  lon=39.1610863;
		  pin="SA";
		 }
		// Define your API URL, authToken, and dataToSend as shown in the previous example
		const FormData = {
		   "token":token,
		  "currency_slc": 'AFN',
		  "currency_slc_iso": 'AF',
		  "destination_name":destination_name,
		  "country":country,
		  "lat":  lat,
		  "long": lon,
		  "pin": pin,
		  "cityd":destination_name,
		  "country_code": pin,
		  "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
		  "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
		  "slc_nationality":userLocation.country.name,
		  "adult": 2,
		  "child": 0,
		  "room": 1,
		  "Adults": [2],
		  "children": [0],
		  "child_ages1": [],
		  "rooms_counter": [1],
		  "child_ages2": [],
		 
		};
		try {
		
		 Dispatch(fetchHotelsSearh(FormData));
		  const response = await Axios.post(apiendpoint+'/api/search/hotels/new_Live',FormData, {
			headers: {
			  "Access-Control-Allow-Origin": "*",
			
			} ,
			
		  });
		  window.scrollTo(0, 0);
		  sessionStorage.removeItem('FlightCheckOut');
		  navigation('/hotels');
		  Dispatch(fetchHotels(response.data));
		  // Handle the API response here
		  setIsLoading(false);
		} catch (error) {
		  // Handle errors here
		  setIsLoading(false);
		  console.error('Error:', error);
		}
	  };
  return (
    <>
      <footer class="bg-dark pt-5">
	<div class="container">
	
		<div class="row g-4">

			<div class="col-lg-3">
					<img style={{height:'10em'}} src={logo} alt="logo"/>
				
			</div>

			<div class="col-lg-8 ">
				<div class="row g-4">
				<div class="col-6 col-md-4">
				<p class="my-3 text-body-secondary">Al Hijaz Rooms- Largest Market Place to Search Hotel Rooms in Makkah and Madina.</p>
				<p class="mb-2"><a href="#" class="text-body-secondary text-primary-hover"><i class="bi bi-telephone me-2"></i>0121 777 2522</a> </p>
				<p class="mb-0"><a href="#" class="text-body-secondary text-primary-hover"><i class="bi bi-envelope me-2"></i>info@alhijazrooms.com</a></p>
					</div>
					<div class="col-6 col-md-4">
						<h5 class="text-white mb-2 mb-md-4">Page</h5>
						<ul class="nav flex-column text-primary-hover">
							<li class="nav-item"><NavLink className="nav-link text-body-secondary text-decoration-none" to="/about-us">About us</NavLink></li>
							<li class="nav-item"><NavLink className="nav-link text-body-secondary text-decoration-none" to="/contact-us">Contact us</NavLink></li>
							<li class="nav-item"><NavLink className="nav-link text-body-secondary py-1 text-decoration-none" to="/terms_and_conditions">Terms and conditions</NavLink></li>
							<li class="nav-item"><NavLink className="nav-link text-body-secondary py-1 pe-0 text-decoration-none" to="/complaint_policy">Complaint Policy</NavLink></li>
						</ul>
					</div>			
					<div class="col-6 col-md-4">
						<h5 class="text-white mb-2 mb-md-4">Global Site</h5>
						<ul class="nav flex-column text-primary-hover">
							<li class="nav-item" ><a style={{cursor:'pointer'}} onClick={()=>Searchhotels('Makkah')} class="nav-link text-body-secondary  text-decoration-none" >Makkah</a></li>
							<li class="nav-item"><a style={{cursor:'pointer'}} onClick={()=>Searchhotels('Madinah')} class="nav-link text-body-secondary text-decoration-none" >Madinah</a></li>
							<li class="nav-item"><a style={{cursor:'pointer'}} onClick={()=>Searchhotels('Riyadh')} class="nav-link text-body-secondary text-decoration-none" >Riyadh</a></li>
							<li class="nav-item"><a style={{cursor:'pointer'}} onClick={()=>Searchhotels('Jeddah')} class="nav-link text-body-secondary text-decoration-none" >Jeddah</a></li>
		
						</ul>
					</div>

					{/* <div class="col-6 col-md-4">
						<h5 class="text-white mb-2 mb-md-4">Booking</h5>
						<ul class="nav flex-column text-primary-hover">
							<li class="nav-item"><a class="nav-link text-body-secondary" href="#"><i class="fa-solid fa-hotel me-2"></i>Hotel</a></li>
							<li class="nav-item"><a class="nav-link text-body-secondary" href="#"><i class="fa-solid fa-plane me-2"></i>Flight</a></li>
							<li class="nav-item"><a class="nav-link text-body-secondary" href="#"><i class="fa-solid fa-globe-americas me-2"></i>Tour</a></li>
							<li class="nav-item"><a class="nav-link text-body-secondary" href="#"><i class="fa-solid fa-car me-2"></i>Cabs</a></li>
						</ul>
					</div> */}
				</div>
			</div>

		</div>

		{/* <div class="row mt-5">
			<h5 class="mb-2 text-white">Top Links</h5>
			<ul class="list-inline text-primary-hover lh-lg">
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Flights</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Hotels</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Tours</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Cabs</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">About</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Contact us</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Blogs</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Services</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Detail page</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Services</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Policy</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Testimonials</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Newsletters</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Podcasts</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Protests</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">NewsCyber</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Education</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Sports</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Tech and Auto</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Opinion</a></li>
				<li class="list-inline-item"><a href="#" class="text-body-secondary">Share Market</a></li>
			</ul>
		</div> */}

		<div class="row g-4 justify-content-between mt-0 mt-md-2">

			<div class="col-sm-7 col-md-6 col-lg-4">
				<h5 class="text-white mb-2">Payment & Security</h5>
				<ul class="list-inline mb-0 mt-3">
					<li class="list-inline-item"> <a ><img src={stripe} class="h-30px" alt=""/></a></li>
					<li class="list-inline-item"> <a ><img src={visa} class="h-30px" alt=""/></a></li>
					<li class="list-inline-item"> <a ><img src={master} class="h-30px" alt=""/></a></li>
					<li class="list-inline-item"> <a ><img src={express} class="h-30px" alt=""/></a></li>
				</ul>
			</div>

			<div class="col-sm-5 col-md-6 col-lg-3 text-sm-end">
				<h5 class="text-white mb-2">Follow us on</h5>
				<ul class="list-inline mb-0 mt-3">
					<li class="list-inline-item"> <a target="blank" class="btn btn-sm px-2 bg-facebook mb-0" href="https://www.facebook.com/profile.php?id=61564229820968"><i class="fab fa-fw fa-facebook-f"></i></a> </li>
					<li class="list-inline-item"> <a target="blank" class="btn btn-sm shadow px-2 bg-instagram mb-0" href="https://www.instagram.com/alhijazrooms"><i class="fab fa-fw fa-instagram"></i></a> </li>
					{/* <li class="list-inline-item"> <a class="btn btn-sm shadow px-2 bg-twitter mb-0" href="#"><i class="fab fa-fw fa-twitter"></i></a> </li> */}
					<li class="list-inline-item"> <a target="blank" class="btn btn-sm shadow px-2 bg-linkedin mb-0" href="https://www.linkedin.com/in/alhijaz-rooms-190727322/"><i class="fab fa-fw fa-linkedin-in"></i></a> </li>
				</ul>	
			</div>
		</div>

		<hr class="mt-4 mb-0"/>

		<div class="row">
			<div class="container">
				<div class="d-lg-flex justify-content-between align-items-center py-3 text-center text-lg-start">
					<div class="text-body-secondary text-primary-hover"> Copyrights ©{moment().format('YYYY')} AlHijaz Rooms.  </div>
					<div class="nav mt-2 mt-lg-0">
						<ul class="list-inline text-primary-hover mx-auto  mb-0">
							<li class="list-inline-item me-0 me-2"><NavLink class="nav-link text-body-secondary py-1 text-decoration-none" to="/privacy_policy">Privacy policy</NavLink></li>
							<li class="list-inline-item me-0  me-2"><NavLink class="nav-link text-body-secondary py-1 text-decoration-none" to="/terms_and_conditions">Terms and conditions</NavLink></li>
							<li class="list-inline-item me-0  me-2"><NavLink class="nav-link text-body-secondary py-1 pe-0 text-decoration-none" to="/complaint_policy">Complaint Policy</NavLink></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
    </>
  )
}

export default Footer2
