export function Hotelapitoken(){
    // r9fdvwRyF35JUnD6xXdRiDELANYjtfASzPmyGol4-1PN461EY50LbXcqkfEfISsOJDrnFDJbuMzPuxTz37zFWGWBVemQGhi2SYLrr-MZ75vJSAiV73z94UOVrDz5P6R-0KjFqr9XR6P2857snQbcKTUn9YNqjBOQQIkXENeO7tmjxdTJs2KUVoXqo6fFyT9TTq99eKe288N-wyanZXxOsfABWPjtSom2oKLVz6vJnn1WeQwHSp7VnzPUqq53rn80eFXNBSMIiEXBdDmlsokRYSa0evDrQKluhnIzMYkRiazxtnkb-z5Xj0tQReTTHsLz1sgnit2mRGGzP4EIdBK8TiLuEN7GD1kmOT3CMreL7ELrI4yxmEbnYyflICtG-ySk3aZkk8iM9mRZlA7CS10Zuj-C0HEBOFW8vMzy4Eq2CET5WN62S1xe0HPAOrDVwO6jDvVpKEMwm-NiyyjkU8oTTlgYpN77pXtfFjKPTF0julnAMC6cPzxZOGBIkRv0
    return 'rz6tgS0tlmw7aSb4hquHHn7NGQ44y8vA88pJaeEH-MRtaeH0lvcyDnhWkFAbhq9NwXRuW5CXp9aPjel92eOZumKV8Tf8N6AyUMdiu-I1jWxYx8Q9nypyYsJXkyPZ1iE-ClroFTec5rzV5Yiw0WZUKCKsgDjnvGVtwKV4s5vzdLgev7W8wjGMqIGk9YL8JCwiSlQmSSAqGLR-gfVR1uWHS9CFpDexv3T1Jfj2PNHzR6IGtJgbZIO5pJ3si03xR7K5CjKDndEEMgKoenRdWUl5exJyllX6lroeDGz66e2HEgqAJuYH-mkYez2oJkQuBPbsELcdi9SfCOv0UJLYbKGjfHwZVk7ySmrOVDEnBoNW1TRR02wKoBG5J5gUKh3n-iJ8O3BWvJD6uHIImlQAFvIKiv-1Y5uld6N0dLF0GS5NHt4nXZsDPxk92tBSaAQTY5d2ffDeNbROQ-TFiAA0WJQiIux0DHu3CZBAMw8fQvmwjaoMV5E6FebFE1CKvfmp'
};
export function CustomerDomainName(){
    return 'https://admin.alhijazrooms.com/'
};
export function FlightSearchToken(){
    return 'rz6tgS0tlmw7aSb4hquHHn7NGQ44y8vA88pJaeEH-MRtaeH0lvcyDnhWkFAbhq9NwXRuW5CXp9aPjel92eOZumKV8Tf8N6AyUMdiu-I1jWxYx8Q9nypyYsJXkyPZ1iE-ClroFTec5rzV5Yiw0WZUKCKsgDjnvGVtwKV4s5vzdLgev7W8wjGMqIGk9YL8JCwiSlQmSSAqGLR-gfVR1uWHS9CFpDexv3T1Jfj2PNHzR6IGtJgbZIO5pJ3si03xR7K5CjKDndEEMgKoenRdWUl5exJyllX6lroeDGz66e2HEgqAJuYH-mkYez2oJkQuBPbsELcdi9SfCOv0UJLYbKGjfHwZVk7ySmrOVDEnBoNW1TRR02wKoBG5J5gUKh3n-iJ8O3BWvJD6uHIImlQAFvIKiv-1Y5uld6N0dLF0GS5NHt4nXZsDPxk92tBSaAQTY5d2ffDeNbROQ-TFiAA0WJQiIux0DHu3CZBAMw8fQvmwjaoMV5E6FebFE1CKvfmp'
};
export function ApiEndPoint(){
    return 'https://api.synchtravel.com'
};
export function TransferApiToken(){
    return 'rz6tgS0tlmw7aSb4hquHHn7NGQ44y8vA88pJaeEH-MRtaeH0lvcyDnhWkFAbhq9NwXRuW5CXp9aPjel92eOZumKV8Tf8N6AyUMdiu-I1jWxYx8Q9nypyYsJXkyPZ1iE-ClroFTec5rzV5Yiw0WZUKCKsgDjnvGVtwKV4s5vzdLgev7W8wjGMqIGk9YL8JCwiSlQmSSAqGLR-gfVR1uWHS9CFpDexv3T1Jfj2PNHzR6IGtJgbZIO5pJ3si03xR7K5CjKDndEEMgKoenRdWUl5exJyllX6lroeDGz66e2HEgqAJuYH-mkYez2oJkQuBPbsELcdi9SfCOv0UJLYbKGjfHwZVk7ySmrOVDEnBoNW1TRR02wKoBG5J5gUKh3n-iJ8O3BWvJD6uHIImlQAFvIKiv-1Y5uld6N0dLF0GS5NHt4nXZsDPxk92tBSaAQTY5d2ffDeNbROQ-TFiAA0WJQiIux0DHu3CZBAMw8fQvmwjaoMV5E6FebFE1CKvfmp'
};
export function NewTransferSearchToken(){
    return 'rz6tgS0tlmw7aSb4hquHHn7NGQ44y8vA88pJaeEH-MRtaeH0lvcyDnhWkFAbhq9NwXRuW5CXp9aPjel92eOZumKV8Tf8N6AyUMdiu-I1jWxYx8Q9nypyYsJXkyPZ1iE-ClroFTec5rzV5Yiw0WZUKCKsgDjnvGVtwKV4s5vzdLgev7W8wjGMqIGk9YL8JCwiSlQmSSAqGLR-gfVR1uWHS9CFpDexv3T1Jfj2PNHzR6IGtJgbZIO5pJ3si03xR7K5CjKDndEEMgKoenRdWUl5exJyllX6lroeDGz66e2HEgqAJuYH-mkYez2oJkQuBPbsELcdi9SfCOv0UJLYbKGjfHwZVk7ySmrOVDEnBoNW1TRR02wKoBG5J5gUKh3n-iJ8O3BWvJD6uHIImlQAFvIKiv-1Y5uld6N0dLF0GS5NHt4nXZsDPxk92tBSaAQTY5d2ffDeNbROQ-TFiAA0WJQiIux0DHu3CZBAMw8fQvmwjaoMV5E6FebFE1CKvfmp'
};
export function TransferCheckoutToken(){
    return 'rz6tgS0tlmw7aSb4hquHHn7NGQ44y8vA88pJaeEH-MRtaeH0lvcyDnhWkFAbhq9NwXRuW5CXp9aPjel92eOZumKV8Tf8N6AyUMdiu-I1jWxYx8Q9nypyYsJXkyPZ1iE-ClroFTec5rzV5Yiw0WZUKCKsgDjnvGVtwKV4s5vzdLgev7W8wjGMqIGk9YL8JCwiSlQmSSAqGLR-gfVR1uWHS9CFpDexv3T1Jfj2PNHzR6IGtJgbZIO5pJ3si03xR7K5CjKDndEEMgKoenRdWUl5exJyllX6lroeDGz66e2HEgqAJuYH-mkYez2oJkQuBPbsELcdi9SfCOv0UJLYbKGjfHwZVk7ySmrOVDEnBoNW1TRR02wKoBG5J5gUKh3n-iJ8O3BWvJD6uHIImlQAFvIKiv-1Y5uld6N0dLF0GS5NHt4nXZsDPxk92tBSaAQTY5d2ffDeNbROQ-TFiAA0WJQiIux0DHu3CZBAMw8fQvmwjaoMV5E6FebFE1CKvfmp'
};
export function CurrencyConverter(){
    return 'b8d4b285d48d1e690388e999'
};
export function StripeClientSecret(){
    // return 'sk_test_51N9pi8GwYlRApJ0wzqUOVIJBpCNJ1Lz7UcUjJoSdMU73WAVzoTXSJ1gfakCYThPWhnzWlKnLLQXugdB0s8dBFDJ500Beg22CmF'
    return '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'
};
export function ActivityToken(){
    return 'rz6tgS0tlmw7aSb4hquHHn7NGQ44y8vA88pJaeEH-MRtaeH0lvcyDnhWkFAbhq9NwXRuW5CXp9aPjel92eOZumKV8Tf8N6AyUMdiu-I1jWxYx8Q9nypyYsJXkyPZ1iE-ClroFTec5rzV5Yiw0WZUKCKsgDjnvGVtwKV4s5vzdLgev7W8wjGMqIGk9YL8JCwiSlQmSSAqGLR-gfVR1uWHS9CFpDexv3T1Jfj2PNHzR6IGtJgbZIO5pJ3si03xR7K5CjKDndEEMgKoenRdWUl5exJyllX6lroeDGz66e2HEgqAJuYH-mkYez2oJkQuBPbsELcdi9SfCOv0UJLYbKGjfHwZVk7ySmrOVDEnBoNW1TRR02wKoBG5J5gUKh3n-iJ8O3BWvJD6uHIImlQAFvIKiv-1Y5uld6N0dLF0GS5NHt4nXZsDPxk92tBSaAQTY5d2ffDeNbROQ-TFiAA0WJQiIux0DHu3CZBAMw8fQvmwjaoMV5E6FebFE1CKvfmp'
};
export function HajjApiToken(){
    return 'rz6tgS0tlmw7aSb4hquHHn7NGQ44y8vA88pJaeEH-MRtaeH0lvcyDnhWkFAbhq9NwXRuW5CXp9aPjel92eOZumKV8Tf8N6AyUMdiu-I1jWxYx8Q9nypyYsJXkyPZ1iE-ClroFTec5rzV5Yiw0WZUKCKsgDjnvGVtwKV4s5vzdLgev7W8wjGMqIGk9YL8JCwiSlQmSSAqGLR-gfVR1uWHS9CFpDexv3T1Jfj2PNHzR6IGtJgbZIO5pJ3si03xR7K5CjKDndEEMgKoenRdWUl5exJyllX6lroeDGz66e2HEgqAJuYH-mkYez2oJkQuBPbsELcdi9SfCOv0UJLYbKGjfHwZVk7ySmrOVDEnBoNW1TRR02wKoBG5J5gUKh3n-iJ8O3BWvJD6uHIImlQAFvIKiv-1Y5uld6N0dLF0GS5NHt4nXZsDPxk92tBSaAQTY5d2ffDeNbROQ-TFiAA0WJQiIux0DHu3CZBAMw8fQvmwjaoMV5E6FebFE1CKvfmp'
};
export function AuthToken(){
    return 'rz6tgS0tlmw7aSb4hquHHn7NGQ44y8vA88pJaeEH-MRtaeH0lvcyDnhWkFAbhq9NwXRuW5CXp9aPjel92eOZumKV8Tf8N6AyUMdiu-I1jWxYx8Q9nypyYsJXkyPZ1iE-ClroFTec5rzV5Yiw0WZUKCKsgDjnvGVtwKV4s5vzdLgev7W8wjGMqIGk9YL8JCwiSlQmSSAqGLR-gfVR1uWHS9CFpDexv3T1Jfj2PNHzR6IGtJgbZIO5pJ3si03xR7K5CjKDndEEMgKoenRdWUl5exJyllX6lroeDGz66e2HEgqAJuYH-mkYez2oJkQuBPbsELcdi9SfCOv0UJLYbKGjfHwZVk7ySmrOVDEnBoNW1TRR02wKoBG5J5gUKh3n-iJ8O3BWvJD6uHIImlQAFvIKiv-1Y5uld6N0dLF0GS5NHt4nXZsDPxk92tBSaAQTY5d2ffDeNbROQ-TFiAA0WJQiIux0DHu3CZBAMw8fQvmwjaoMV5E6FebFE1CKvfmp'
};
// d452152ef318121614eccb23
// b8d4b285d48d1e690388e999 new