import React, { useEffect, useState } from 'react'
import Googlemap from '../../Components/Googlemap/Googlemap'
import img2 from '../../Images/New/element/contact.svg'
import Layout from '../../Components/Layout/Layout'
import wow from 'wowjs'
import { ToastContainer, toast } from 'react-toastify'
import emailjs from 'emailjs-com'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faHeadset, faInbox } from '@fortawesome/free-solid-svg-icons'
const ContactusNew = ({ onLogout }) => {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    subject: '',
    phnno: '',
    message: ''
  })
  const [isBooking, setIsBooking] = useState(false)
  useEffect(() => {
    new wow.WOW().init()
  }, [])

  const handleChange = event => {
    const { value, name } = event.target
    setFormData(prevdata => ({
      ...prevdata,
      [name]: value
    }))
  }

  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const submitForm = async () => {
    if (formData.name === '') {
      toast.info('Please Enter Your Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.email === '') {
      toast.info('Please Enter Your Email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.subject === '') {
      toast.info('Please Enter Subject.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.phnno === '') {
      toast.info('Please Enter Phone Number.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.message === '') {
      toast.info('Please Enter Message.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (!isValidEmail(formData.email)) {
      toast.info('Please Enter a valid email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    setIsBooking(true)
    await sendUserMessage()
    setIsBooking(false)
    toast.success(
      'Dear Customer,Thank you for contacting AlHijaz Rooms.One of our agent will contact you within 12 to 24 hours.'
    )
    // try {
    //   const templateParams = {
    //     email: formData.email,
    //     name: formData.name,
    //     subject: formData.subject,
    //     phone: formData.phnno,
    //     message: formData.message
    //   }
    //   await emailjs.send(
    //     'service_d6zrc2l',
    //     'template_la4ks0l',
    //     templateParams,
    //     'c163bgNie5rW0iU1f'
    //   )
    //   setIsBooking(false)
    //   toast.success('Email Send Successfully.', {
    //     position: toast.POSITION.TOP_RIGHT
    //   })
    // } catch (error) {
    //   setIsBooking(false)
    //   console.error('Error sending email', error)
    //   toast.error('Error sending email.', {
    //     position: toast.POSITION.TOP_RIGHT
    //   })
    // }
  }
  const sendUserMessage = async () => {
    const messages = [
      {
        recipient: '+447309803307',
        message: `Dear Alhijaz Rooms,
		  
	  A customer has contact via contact form.
		  
	  Name:${formData.name}
	  Email:${formData.email}
	  Number:${formData.phnno}
	  Subject:${formData.subject}
	  Message:${formData.message}
		  
	  Thank You`
      },
      {
        recipient: formData.phnno,
        message: `Dear Customer,\nThank you for contacting Alhijaz Rooms.\nOne of our agent will contact you within 12 to 24 hours.
		
	Thank You 
	Team Alhijaz Rooms`
      }
    ]
    const apiKey = 'RUU6Wkzm2JRt'
    for (let i = 0; i < messages.length; i++) {
      const { recipient, message } = messages[i]
      const encodedMessage = encodeURIComponent(message)
      const url = `http://api.textmebot.com/send.php?recipient=${recipient}&apikey=${apiKey}&text=${encodedMessage}`

      try {
        const response = await fetch(url)

        if (response.ok) {
          const contentType = response.headers.get('content-type')

          if (contentType && contentType.includes('application/json')) {
            const data = await response.json()
            console.log(`Message sent to ${recipient}:`, data)
          } else {
            const text = await response.text()
            console.log(`Message sent to ${recipient}:`, text)
          }

          // Wait 7 seconds before sending the next message
          if (i < messages.length - 1) {
            await new Promise(resolve => setTimeout(resolve, 7000))
          }
        } else {
          console.error(
            `Error sending message to ${recipient}:`,
            response.statusText
          )
        }
      } catch (error) {
        if (i < messages.length - 1) {
          await new Promise(resolve => setTimeout(resolve, 7000))
        }
        console.error(`Error sending message to ${recipient}:`, error)
      }
    }
  }
  return (
    <>
      <ToastContainer />
      <Layout logout={onLogout}>
        <section class='pt-4 pt-md-5'>
          <div class='container'>
            <div class='row mb-5'>
              <div class='col-xl-10'>
                <h3>
                  We’d love to hear from you! Whether you have questions,
                  feedback, or need support, feel free to reach out to us
                </h3>
                <p class='lead mb-0'>Here’s how you can get in touch:</p>
              </div>
            </div>

            <div class='row g-4'>
              <div class='col-md-6 col-xl-4'>
                <div class='card card-body shadow text-center align-items-center h-100'>
                  <div class='icon-lg bg-info bg-opacity-10 text-info rounded-circle mb-2'>
                    <i class='bi bi-headset fs-5'>
                      <FontAwesomeIcon icon={faHeadset} />
                    </i>
                  </div>
                  <h5>Call us</h5>

                  <div class='d-grid gap-3 d-sm-block'>
                    <button class='btn btn-sm btn-primary-soft'>
                      <i class='bi bi-phone me-2'></i>0121 777 2522
                    </button>
                    <button class='btn btn-sm btn-light'>
                      <i class='bi bi-telephone me-2'></i>+44 7309 803307
                    </button>
                  </div>
                </div>
              </div>

              <div class='col-md-6 col-xl-4'>
                <div class='card card-body shadow text-center align-items-center h-100'>
                  <div class='icon-lg bg-danger bg-opacity-10 text-danger rounded-circle mb-2'>
                    <i class='bi bi-inboxes-fill fs-5'>
                      <FontAwesomeIcon icon={faInbox} />
                    </i>
                  </div>
                  <h5>Email us</h5>

                  <a
                    href='#'
                    class='btn btn-link text-decoration-underline p-0 mb-0'
                  >
                    <i class='bi bi-envelope me-1'></i>info@alhijazrooms.com
                  </a>
                </div>
              </div>

              <div class='col-xl-4 position-relative'>
                <figure class='position-absolute top-0 end-0 z-index-1 mt-n4 ms-n7'>
                  <svg class='fill-warning' width='77px' height='77px'>
                    <path d='M76.997,41.258 L45.173,41.258 L67.676,63.760 L63.763,67.673 L41.261,45.171 L41.261,76.994 L35.728,76.994 L35.728,45.171 L13.226,67.673 L9.313,63.760 L31.816,41.258 L-0.007,41.258 L-0.007,35.725 L31.816,35.725 L9.313,13.223 L13.226,9.311 L35.728,31.813 L35.728,-0.010 L41.261,-0.010 L41.261,31.813 L63.763,9.311 L67.676,13.223 L45.174,35.725 L76.997,35.725 L76.997,41.258 Z'></path>
                  </svg>
                </figure>

                <div class='card card-body shadow text-center align-items-center h-100'>
                  <div class='icon-lg bg-orange bg-opacity-10 text-orange rounded-circle mb-2'>
                    <i class='bi bi-globe2 fs-5'>
                      <FontAwesomeIcon icon={faGlobe} />
                    </i>
                  </div>
                  <h5>Social media</h5>

                  <ul class='list-inline mb-0'>
                    <li class='list-inline-item'>
                      {' '}
                      <a target='blank' class='btn btn-sm bg-facebook px-2 mb-0' href='https://www.facebook.com/profile.php?id=61564229820968'>
                        <i class='fab fa-fw fa-facebook-f'></i>
                      </a>{' '}
                    </li>
                    <li class='list-inline-item'>
                      {' '}
                      <a target='blank' class='btn btn-sm bg-instagram px-2 mb-0' href='https://www.instagram.com/alhijazrooms/'>
                        <i class='fab fa-fw fa-instagram'></i>
                      </a>{' '}
                    </li>
                    
                    <li class='list-inline-item'>
                      {' '}
                      <a target='blank' class='btn btn-sm bg-linkedin px-2 mb-0' href='https://www.linkedin.com/in/alhijaz-rooms-190727322/'>
                        <i class='fab fa-fw fa-linkedin-in'></i>
                      </a>{' '}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class='pt-0 pt-lg-5'>
          <div class='container'>
            <div class='row g-4 g-lg-5 align-items-center'>
              <div class='col-lg-6 text-center'>
                <img src={img2} alt='' />
              </div>

              <div class='col-lg-6'>
                <div class='card bg-light p-4'>
                  <figure class='position-absolute end-0 bottom-0 mb-n4 me-n2'>
                    <svg class='fill-orange' width='104.2px' height='95.2px'>
                      <circle cx='2.6' cy='92.6' r='2.6' />
                      <circle cx='2.6' cy='77.6' r='2.6' />
                      <circle cx='2.6' cy='62.6' r='2.6' />
                      <circle cx='2.6' cy='47.6' r='2.6' />
                      <circle cx='2.6' cy='32.6' r='2.6' />
                      <circle cx='2.6' cy='17.6' r='2.6' />
                      <circle cx='2.6' cy='2.6' r='2.6' />
                      <circle cx='22.4' cy='92.6' r='2.6' />
                      <circle cx='22.4' cy='77.6' r='2.6' />
                      <circle cx='22.4' cy='62.6' r='2.6' />
                      <circle cx='22.4' cy='47.6' r='2.6' />
                      <circle cx='22.4' cy='32.6' r='2.6' />
                      <circle cx='22.4' cy='17.6' r='2.6' />
                      <circle cx='22.4' cy='2.6' r='2.6' />
                      <circle cx='42.2' cy='92.6' r='2.6' />
                      <circle cx='42.2' cy='77.6' r='2.6' />
                      <circle cx='42.2' cy='62.6' r='2.6' />
                      <circle cx='42.2' cy='47.6' r='2.6' />
                      <circle cx='42.2' cy='32.6' r='2.6' />
                      <circle cx='42.2' cy='17.6' r='2.6' />
                      <circle cx='42.2' cy='2.6' r='2.6' />
                      <circle cx='62' cy='92.6' r='2.6' />
                      <circle cx='62' cy='77.6' r='2.6' />
                      <circle cx='62' cy='62.6' r='2.6' />
                      <circle cx='62' cy='47.6' r='2.6' />
                      <circle cx='62' cy='32.6' r='2.6' />
                      <circle cx='62' cy='17.6' r='2.6' />
                      <circle cx='62' cy='2.6' r='2.6' />
                      <circle cx='81.8' cy='92.6' r='2.6' />
                      <circle cx='81.8' cy='77.6' r='2.6' />
                      <circle cx='81.8' cy='62.6' r='2.6' />
                      <circle cx='81.8' cy='47.6' r='2.6' />
                      <circle cx='81.8' cy='32.6' r='2.6' />
                      <circle cx='81.8' cy='17.6' r='2.6' />
                      <circle cx='81.8' cy='2.6' r='2.6' />
                      <circle cx='101.7' cy='92.6' r='2.6' />
                      <circle cx='101.7' cy='77.6' r='2.6' />
                      <circle cx='101.7' cy='62.6' r='2.6' />
                      <circle cx='101.7' cy='47.6' r='2.6' />
                      <circle cx='101.7' cy='32.6' r='2.6' />
                      <circle cx='101.7' cy='17.6' r='2.6' />
                      <circle cx='101.7' cy='2.6' r='2.6' />
                    </svg>
                  </figure>

                  <div class='card-header bg-light p-0 pb-3'>
                    <h3 class='mb-0'>Send us message</h3>
                  </div>

                  <div class='card-body p-0'>
                    <div class='row g-4'>
                      <div class='col-md-6'>
                        <label class='form-label'>Your name</label>
                        <input
                          type='text'
                          class='form-control'
                          onChange={handleChange}
                          placeholder='Enter Name...'
                          name='name'
                          required
                        />
                      </div>
                      <div class='col-md-6'>
                        <label class='form-label'>Email address</label>
                        <input
                          type='email'
                          class='form-control'
                          onChange={handleChange}
                          placeholder='Email'
                          name='email'
                          required
                        />
                      </div>
                      <div class='col-md-6'>
                        <label class='form-label'>Subject</label>
                        <input
                          type='text'
                          class='form-control'
                          onChange={handleChange}
                          placeholder='Enter Subject...'
                          name='subject'
                          required
                        />
                      </div>
                      <div class='col-md-6'>
                        <label class='form-label'>Mobile number</label>
                        <input
                          type='text'
                          class='form-control'
                          onChange={handleChange}
                          placeholder='+123456789'
                          name='phnno'
                          required
                        />
                      </div>
                      <div class='col-12'>
                        <label class='form-label'>Message</label>
                        <textarea
                          class='form-control'
                          placeholder='What’s on your mind'
                          name='message'
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                      <div class='col-12'>
                        <button
                          type='submit'
                          disabled={isBooking}
                          onClick={submitForm}
                          className='btn btn-dark mb-0'
                        >
                          {' '}
                          {isBooking ? <Spinner /> : 'Submit'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Googlemap />
      </Layout>
    </>
  )
}

const Spinner = () => {
  return (
    <div className='spinner-border' role='status'>
      <span className='visually-hidden'>Loading...</span>
    </div>
  )
}
export default ContactusNew
