import React, { useEffect,useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBan, faCheck, faFileInvoice, faHouse, faReceipt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { NavLink } from "react-router-dom";
import Chart from 'react-apexcharts';
function UserDashboard({ invoiceListData }){
const [bookingList,setBookingList]=useState(invoiceListData);
const [filterText, setFilterText] = useState('')
const [autoCancelledInNext24Hours, setAutoCancelledInNext24Hours] = useState(0);
const [autoCancelledInNext4Days, setAutoCancelledInNext4Days] = useState(0);
const [cancelledInPrevious24Hours, setCancelledInPrevious24Hours] = useState(0);
const [cancelledInPrevious4Days, setCancelledInPrevious4Days] = useState(0);

useEffect(()=>{
setBookingList(invoiceListData);
const checkCancellations = () => {
	const today = new Date();
	let next24HoursCount = 0;
	let next4DaysCount = 0;
	let previous24HoursCount = 0;
	let previous4DaysCount = 0;
  
	invoiceListData.forEach(item => {
	  const hotelDetail = JSON.parse(item.reservation_response).hotel_details;
  
	  // Extract the cancellation policies from the hotel details
	  const cancellationPolicies = hotelDetail.rooms.flatMap(room =>
		room.room_rates.flatMap(rate => rate.cancellation_policy.map(policy => ({
		  ...policy,
		  net: rate.net // Include net value for comparison
		})))
	  );
  
	  cancellationPolicies.forEach(policy => {
		const cancellationDate = new Date(policy.from_date);
  
		// Calculate the time differences in hours and days
		const timeDiffInHours = (cancellationDate - today) / (1000 * 60 * 60);
		const timeDiffInDays = timeDiffInHours / 24;
  
		// Count based on status
		if (item.status.toLowerCase() === 'confirmed') {
		  // Next 24 hours (auto-cancel) with free cancellation check
		  if (timeDiffInHours > 0 && timeDiffInHours <= 24 && parseFloat(policy.amount) === parseFloat(policy.net)) {
			next24HoursCount += 1;
		  }
  
		  // Next 4 days with free cancellation check
		  if (timeDiffInDays > 0 && timeDiffInDays <= 4 && parseFloat(policy.amount) === parseFloat(policy.net)) {
			next4DaysCount += 1;
		  }
		}
  
		if (item.status.toLowerCase() === 'cancelled') {
		  // Previous 24 hours
		  if (timeDiffInHours < 0 && timeDiffInHours >= -24) {
			previous24HoursCount += 1;
		  }
  
		  // Previous 4 days
		  if (timeDiffInDays < 0 && timeDiffInDays >= -4) {
			previous4DaysCount += 1;
		  }
		}
	  });
	});
  
	setAutoCancelledInNext24Hours(next24HoursCount);
	setAutoCancelledInNext4Days(next4DaysCount);
	setCancelledInPrevious24Hours(previous24HoursCount);
	setCancelledInPrevious4Days(previous4DaysCount);
  };
  
if(invoiceListData.length !==0){
	checkCancellations();
}
},[invoiceListData])
      const handleChange =(event)=>{
		setFilterText(event.target.value);
		var filterdata=invoiceListData.filter(item => JSON.parse(item.reservation_response).hotel_details.hotel_name.toLowerCase().includes(event.target.value.toLowerCase()))
		setBookingList(filterdata);
	  }
	  const aggregatedData = aggregateBookingsByMonth(invoiceListData);
	  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	  const totalBookingsData = months.map(month => aggregatedData.total[month] || 0);
	  const confirmedBookingsData = months.map(month => aggregatedData.confirmed[month] || 0);
	  const cancelledBookingsData = months.map(month => aggregatedData.cancelled[month] || 0);
	
	  const options = {
		chart: {
		  id: 'booking-chart',
		  type: 'line',
		  zoom: {
			enabled: false
		  }
		},
		dataLabels: {
		  enabled: false
		},
		stroke: {
		  curve: 'smooth'
		},
		xaxis: {
		  categories: months
		},
		yaxis: {
		  title: {
			text: 'Booking  Count'

		  }
		},
		tooltip: {
		  shared: true,
		  intersect: false
		},
		legend: {
		  position: 'top'
		}
	  };
	
	  const series = [
		{
		  name: 'Total Bookings',
		  data: totalBookingsData
		},
		{
		  name: 'Confirmed Bookings',
		  data: confirmedBookingsData
		},
		{
		  name: 'Cancelled Bookings',
		  data: cancelledBookingsData
		}
	  ];
	  
    return(
        <>
        {/* <h5>Dashboard Statistics</h5> */}
        <section class="pt-0">
	<div class="container vstack gap-4">
		<div class="row">
			<div class="col-12">
				<h4 class="fs-4 mb-0"><i class="bi bi-house-door fa-fw me-1"><FontAwesomeIcon icon={faHouse}/></i>Dashboard</h4>
			</div>
		</div>	
		<div class="row g-4">
			<div class="col-sm-6 col-xl-4">
				<div class="card p-1 card-body border">
					<div class="d-flex align-items-center">
						<div class="icon-xl bg-info rounded-3 text-white">
							<i class="bi bi-journals"><FontAwesomeIcon icon={faFileInvoice}/></i>
						</div>
						<div class="ms-3">
							<h4>{invoiceListData.length}</h4>
							<span>Total Bookings</span>
						</div>
					</div>
				</div>
			</div>

			<div class="col-sm-6 col-xl-4">
				<div class="card p-1 card-body border">
					<div class="d-flex align-items-center">
						<div class="icon-xl bg-success rounded-3 text-white">
							<i class="bi bi-graph-up-arrow"><FontAwesomeIcon icon={faCheck}/></i>
						</div>
						<div class="ms-3">
							<h4>{invoiceListData.length ===0 ? 0:invoiceListData.filter(booking => booking.status.toLowerCase() === 'confirmed').length}</h4>
							<span>Confirmed Bookings</span>
						</div>
					</div>
				</div>
			</div>

			<div class="col-sm-6 col-xl-4">
				<div class="card p-1 card-body border">
					<div class="d-flex align-items-center">
						<div class="icon-xl bg-danger rounded-3 text-white">
							<i class="bi bi-bar-chart-line-fill"><FontAwesomeIcon icon={faBan}/></i>
						</div>
						<div class="ms-3">
							<h4>{invoiceListData.length ===0 ? 0:invoiceListData.filter(booking => booking.status.toLowerCase() === 'cancelled').length}</h4>
							<span>Cancelled Bookings</span>
						</div>
					</div>
				</div>
			</div>

			<div class="col-sm-6 col-xl-4">
				<div class="card p-1 card-body border">
					<div class="d-flex align-items-center">
						<div class="icon-xl bg-primary rounded-3 text-white">
							<i class="bi bi-star"><FontAwesomeIcon icon={faReceipt}/></i>
						</div>
						<div class="ms-3">
							<h4>{invoiceListData.length ===0 ? 0:invoiceListData.filter(booking => booking.status.toLowerCase() === 'room on request').length}</h4>
							<span>On Request Bookings</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-6 col-xl-4">
				<div class="card p-1 card-body border">
					<div class="d-flex align-items-center">
						<div class="icon-xl bg-info rounded-3 text-white">
							<i class="bi bi-star"><FontAwesomeIcon icon={faReceipt}/></i>
						</div>
						<div class="ms-3">
							<h4>{invoiceListData.length ===0 ? 0:invoiceListData.filter(booking => booking.status.toLowerCase() === 'confirmed' && booking.payment_details !==null && JSON.parse(booking.payment_details) !=='').length}</h4>
							<span>Paid Bookings</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-6 col-xl-4">
				<div class="card p-1 card-body border">
					<div class="d-flex align-items-center">
						<div class="icon-xl bg-warning rounded-3 text-white">
							<i class="bi bi-star"><FontAwesomeIcon icon={faReceipt}/></i>
						</div>
						<div class="ms-3">
							<h4>{invoiceListData.length ===0 ? 0:invoiceListData.filter(booking => booking.status.toLowerCase() === 'confirmed' && booking.payment_details ===null || JSON.parse(booking.payment_details) ==='').length}</h4>
							<span>Unpaid Bookings</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
		<div class="row g-4">
			<div class="col-sm-12 col-xl-6">
				<div class="card card-body border rounded-1">
					<h6>Free Cancellation</h6>
                    <h6 className="text-secondary mt-3">{autoCancelledInNext24Hours} end in 24 hours</h6>
                    <h6 className=" text-secondary mt-3">{autoCancelledInNext4Days} end in 4 days</h6>
				</div>
			</div>

			<div class="col-sm-12 col-xl-6">
            <div class="card card-body border rounded-1">
					<h6>Cancelled Bookings</h6>
                    <h6 className="text-secondary mt-3">{cancelledInPrevious24Hours} previous 24 hours</h6>
                    <h6 className=" text-secondary mt-3">{cancelledInPrevious4Days} previous 4 days</h6>
				</div>
			</div>
		</div>

		{/* <div class="row g-4">
			<div class="col-lg-12 col-xl-12">
				<div class="card border rounded-1 h-100">
					<div class="card-header border-bottom">
						<h5 class="card-header-title">Payment Information</h5>
					</div>
					<div class="card-body row">
                        <div className="col-md-12"> 
                            <div class="d-flex p-2 my-1 rounded-1 justify-content-between align-items-center">
                                <h6 className="">Payment not required </h6>
                                <h6 className="">GBP 256</h6>
                            </div>
                            <div class="d-flex p-2 my-1 rounded-1 justify-content-between align-items-center">
                                <h6 className="">Payment required </h6>
                                <h6 className="">GBP 256</h6>
                            </div>
                            <div class="d-flex p-2 my-1 rounded-1 justify-content-between align-items-center">
                                <h6 className="">Overdue payment</h6>
                                <h6 className="">GBP 256</h6>
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>	 */}
		
		<div class="row">
			<div class="col-12">
				<div class="card border rounded-3">
					<div class="card-header border-bottom">
						<div class="d-sm-flex justify-content-between align-items-center">
							<h5 class="mb-2 mb-sm-0">Upcoming Bookings</h5>
							{/* <a href="#" class="btn btn-sm btn-primary mb-0">View All</a> */}
						</div>
					</div>

					<div class="card-body">
						<div class="row g-3 align-items-center justify-content-between mb-3">
							<div class="col-md-8">
								<form class="rounded position-relative">
									<input class="form-control pe-5" type="search" value={filterText} onChange={handleChange} placeholder="Search by hotel name" aria-label="Search"/>
									{/* <button class="btn border-0 px-3 py-0 position-absolute top-50 end-0 translate-middle-y" type="submit"><i class="fas fa-search fs-6"></i></button> */}
								</form>
							</div>
						</div>
						<div class="table-responsive border-0">
							<table class="table align-middle p-4 mb-0 table-hover table-shrink">
								<thead class="table-light">
									<tr>
										<th scope="col" class="border-0 rounded-start">#</th>
										<th scope="col" class="border-0">Hotel Name</th>
										<th scope="col" class="border-0">status</th>
										<th scope="col" class="border-0">Payment</th>
										<th scope="col" class="border-0">Action</th>
									</tr>
								</thead>

								<tbody class="border-top-0">
								{bookingList.length !== 0 && bookingList.filter((item) => {
												const reservation = JSON.parse(item.reservation_response);
												const checkinDate = new Date(reservation.hotel_details.checkIn); // Assuming the date is in a valid format
												const today = new Date();

												// Compare dates: only return items where the check-in date is in the future
												return checkinDate > today;
											})
											.map((item,index) => {
												let payment
												const reservation = JSON.parse(item.reservation_response);
													if(item.payment_details!==null){
														if(JSON.parse(item.payment_details)!==''){
															payment='Full Payment'
														}else {
															payment='No Payment'
														}
													}else{
														payment='No Payment'
													}
												return (
													<tr key={item.id}>
														<td> <h6 class="mb-0">{index+1}</h6> </td>
														<td> 
															<h6 class="mb-0">{reservation.hotel_details.hotel_name} </h6> 
															<p>{moment(reservation.hotel_details.checkIn).format('ll')} <FontAwesomeIcon icon={faArrowRight}/> {moment(reservation.hotel_details.checkOut).format('ll')}</p>
														</td>
														<td> <div class={`badge ${item.status.toLowerCase()==='confirmed'?'text-bg-success':item.status.toLowerCase()==='cancelled'?'text-bg-danger ':'text-bg-info'} `}>{item.status}</div> </td>
														<td> <div class={`badge ${payment=='No Payment'?'bg-danger text-danger':'bg-success text-success'} bg-opacity-10 `}>{payment}</div> </td>
														<td>
														<NavLink target='blank'to={`/hotel_voucher/${item.invoice_no}`}>
															<p class='btn btn-primary-soft mb-0'>View Voucher</p>
															</NavLink>
														</td>
													</tr>
												);
											})}
								</tbody>
							</table>
						</div>
					</div>

					{/* <div class="card-footer pt-0">
						<div class="d-sm-flex justify-content-sm-between align-items-sm-center">
							<p class="mb-sm-0 text-center text-sm-start">Showing 1 to 8 of 20 entries</p>
							<nav class="mb-sm-0 d-flex justify-content-center" aria-label="navigation">
								<ul class="pagination pagination-sm pagination-primary-soft mb-0">
									<li class="page-item disabled">
										<a class="page-link" href="#" tabindex="-1">Prev</a>
									</li>
									<li class="page-item"><a class="page-link" href="#">1</a></li>
									<li class="page-item active"><a class="page-link" href="#">2</a></li>
									<li class="page-item disabled"><a class="page-link" href="#">..</a></li>
									<li class="page-item"><a class="page-link" href="#">15</a></li>
									<li class="page-item">
										<a class="page-link" href="#">Next</a>
									</li>
								</ul>
							</nav>
						</div>
					</div> */}
				</div>
			</div>
		</div>	
	</div>
</section>
        </>
    )
}

const aggregateBookingsByMonth = (bookings) => {
	const monthMap = {
	  '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'Jun',
	  '07': 'Jul', '08': 'Aug', '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec'
	};
  
	const result = {
	  total: {},
	  confirmed: {},
	  cancelled: {}
	};
  
	bookings.forEach(({ created_at, status }) => {
	  const month = created_at.slice(5, 7); // Extract the month (e.g., '08' for August)
	  const monthName = monthMap[month]; // Get month name (e.g., 'Aug')
  
	  // Initialize counts for the month if not already done
	  if (!result.total[monthName]) {
		result.total[monthName] = 0;
		result.confirmed[monthName] = 0;
		result.cancelled[monthName] = 0;
	  }
  
	  // Increment total bookings
	  result.total[monthName]++;
  
	  // Increment according to status
	  if (status && status.toLowerCase() === 'confirmed') {
		result.confirmed[monthName]++;
	  } else if (status && status.toLowerCase() === 'cancelled') {
		result.cancelled[monthName]++;
	  }
	});
  
	return result;
  };
export default UserDashboard;