import React from "react";
import Layout from "../../Components/Layout/Layout";
function ComplaintPolicy({ onLogout }){
    return(
        <>
        <Layout logout={onLogout}>
        <section class='py-0 mt-2'>
          <div class='container'>
            <div class='card bg-light overflow-hidden px-sm-5'>
              <div class='row align-items-center g-4'>
                <div class='col-sm-9'>
                  <div class='card-body'>
                    <nav aria-label='breadcrumb'>
                      <ol class='breadcrumb breadcrumb-dots mb-0'>
                        <li class='breadcrumb-item'>
                          <p>
                            <i class='bi bi-house me-1'></i> Home
                          </p>
                        </li>
                        <li class='breadcrumb-item'>Complaints Policy</li>
                      </ol>
                    </nav>
                    <h3 class='m-0 h3 card-title'>Complaints Policy & Procedures</h3>
                  </div>
                </div>

                {/* <div class='col-sm-3 text-end d-none d-sm-block'>
                  <img src={img} class='mb-n4' alt='' />
                </div> */}
              </div>
            </div>
          </div>
        </section>
            <div className='container'>
          {/* <div className='row mt-3 text-center'>
            <h3>Complaints Policy & Procedures</h3>
          </div> */}
          <div className='row mt-4 mb-4'>
            <p className='FontSize15'>
            Alhijaz Rooms stands out as the pioneer tour operator, providing
              unparalleled travel experiences for explorers worldwide. Our
              meticulously crafted packages cater to every aspect of your
              journey, including hotel bookings, flights, transfers, and
              activity arrangements. From securing comfortable accommodations to
              facilitating seamless travel arrangements, our dedicated team
              meticulously attends to every detail, ensuring a stress-free and
              enriching experience. Trust in  Alhijaz Rooms to tailor packages that
              perfectly align with your individual needs and preferences,
              providing peace of mind and unforgettable memories throughout your
              expedition.
            </p>{' '}
          </div>
          <p className='mt-2'>
            How to Submit a Complaint
            <br />
            You can submit a complaint through the following channels:
            <br />
            Email: info@alhijazrooms.com
            <br />
            Phone:+44 7309 803307
            <br />
            Online Form: Contact us on our website
            <br />
            Please include as much detail as possible, such as your booking
            reference and the nature of your complaint.
          </p>
        </div>
        </Layout>
        </>
    )
}

export default ComplaintPolicy;