import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck} from '@fortawesome/free-solid-svg-icons'
function RoomFacilities({ item }) {
  const [showAllFacilities, setShowAllFacilities] = useState(false);

  const facilitiesToDisplay = showAllFacilities
    ? item.rooms_facilities
    : item.rooms_facilities.slice(0, 5);

  const toggleFacilities = () => {
    setShowAllFacilities(!showAllFacilities);
  };

  return (
    <div>
    <ul class="nav nav-divider mb-2">
      {facilitiesToDisplay.map((facility, index) => (
        <li class="nav-item" key={index}>
          {facility}
        </li>
      ))}
    </ul>
   
    {item.rooms_facilities.length > 5 && (
      <button onClick={toggleFacilities}>
        {showAllFacilities ? 'Show Less' : 'View More'}
      </button>
    )}
  </div>
  );
}

export default RoomFacilities;
