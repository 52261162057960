import React, { useEffect, useState, useCallback } from 'react'
import DataTable from 'react-data-table-component'
import styled from 'styled-components'
import moment from 'moment'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { Stripe } from 'stripe'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'
import { Button } from 'rsuite'
import Axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import {
  StripeClientSecret,
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'

function DashboardPage ({ invoiceListData }) {
  var Secretkey = StripeClientSecret()
  const UserDetail = useSelector(state => state.hotels.UserLoginRes)
  const baseurl = ApiEndPoint()
  const token = Hotelapitoken()
  const stripe = new Stripe(Secretkey)
  const elements = useElements()
  const stripeInstance = useStripe()
  const [error, setError] = useState(null)
  const [isPayment, setIsPayment] = useState(false)
  const [filterId, setFilterId] = useState(1)
  const [filterData, setFilterData] = useState([])
  const [invoicePayment, setInvoicePayment] = useState({})
  const navigation = useNavigate()
  const [cardInfo, setCardInfo] = useState({
    name: '',
    cardnumber: '',
    mm: '',
    yy: '',
    cvv: ''
  })
  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold'
      }
    },
    rows: {
      style: {
        backgroundColor: '#f7f7fa'
      }
    }
  }

  const columns = [
    {
      name: 'Sr',
      selector: (row, index) => index + 1,
      sortable: true,
      cell: (row, index) => index + 1,
      width: '80px'
    },
    { name: 'Booking Type', selector: row => row.domain_name, sortable: true },
    {
      name: 'Invoice No',
      selector: row =>
        row.domain_name === 'Inovice Booking'
          ? `INV${row.generate_id}`
          : row.invoice_no,
      sortable: true
    },
    {
      name: 'Passenger Name',
      width: '150px',
      selector: row =>
        row.domain_name === 'Inovice Booking'
          ? row.b2b_Agent_Company_Name
          : row.lead_passenger,
      sortable: true
    },
    {
      name: 'Hotel Name',
      selector: row => row.domain_name, // This is needed for sorting
      sortable: true,
      width: '200px',
      cell: row => {
        try {
          if (row.domain_name === 'Hotel Booking') {
            const reservation = JSON.parse(row.reservation_response)
            return (
              <div>
                {reservation.hotel_details.hotel_name || ''}
                <br />
                {moment(reservation.hotel_details.checkIn).format(
                  'DD-MM-YYYY'
                )}{' '}
                {moment(reservation.hotel_details.checkOut).format(
                  'DD-MM-YYYY'
                )}
              </div>
            )
          } else if (row.domain_name === 'Transfer Booking') {
            const transfer = JSON.parse(row.transfer_data)
            return (
              <div>
                {transfer.pickup_City || ''}
                <br />
                Pckup Date : {moment(transfer.pickup_date).format('DD-MM-YYYY')}
              </div>
            )
          } else {
            return <div>{row.country}</div>
          }
        } catch (e) {
          return ''
        }
      }
    },
    {
      name: 'Total Amount',
      selector: row => row.exchange_price,
      sortable: true,
      cell: row =>
        `${
          row.domain_name === 'Inovice Booking'
            ? row.currency_symbol
            : row.exchange_currency
        } ${Number(
          row.domain_name === 'Inovice Booking'
            ? row.total_sale_price_Company
            : row.domain_name === 'Transfer Booking'
            ? row.transfer_total_price
            : row.exchange_price
        ).toFixed(2)}`
    },
    {
      name: 'Status',
      selector: row =>
        row.domain_name === 'Inovice Booking'
          ? row.confirm === null
            ? 'Tentative'
            : 'Confirm'
          : row.domain_name === 'Transfer Booking'
          ? row.booking_status
          : row.status,
      sortable: true
    }
  ]
  const filteredItems = invoiceListData.filter(item => {
    if (item.domain_name === 'Inovice Booking') {
      return (
        item.generate_id &&
        item.generate_id
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())
      )
    } else {
      return (
        (item.invoice_no &&
          item.invoice_no.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.lead_passenger &&
          item.lead_passenger.toLowerCase().includes(filterText.toLowerCase()))
      )
    }
  })
useEffect(()=>{
  var newdata=invoiceListData.filter(item=>item.status.toLowerCase()==='confirmed')
  setFilterData(newdata);
},[invoiceListData])
  
  function convertArrayOfObjectsToCSV (array) {
    if (!array || !array.length) {
      return null
    }

    const columnDelimiter = ','
    const lineDelimiter = '\n'
    const keys = Object.keys(array[0])

    let result = ''
    result += keys.join(columnDelimiter)
    result += lineDelimiter

    array.forEach(item => {
      let ctr = 0
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter

        result += item[key]

        ctr++
      })
      result += lineDelimiter
    })

    return result
  }
  const downloadCSV = useCallback(() => {
    if (invoiceListData.length === 0) {
      return
    }

    const csvData = invoiceListData.map((item, index) => {
      let content = ''
      let id = `item-${index}` // Create a dynamic id based on the index
      try {
        if (item.domain_name === 'Hotel Booking') {
          const reservation = JSON.parse(item.reservation_response)
          const hotelName = reservation.hotel_details.hotel_name || ''
          const checkIn = moment(reservation.hotel_details.checkIn).format(
            'DD-MM-YYYY'
          )
          const checkOut = moment(reservation.hotel_details.checkOut).format(
            'DD-MM-YYYY'
          )
          content = `${hotelName}<br />${checkIn} - ${checkOut}`
        } else if (item.domain_name === 'Transfer Booking') {
          const transfer = JSON.parse(item.transfer_data)
          const pickupCity = transfer.pickup_City || ''
          const pickupDate = moment(transfer.pickup_date).format('DD-MM-YYYY')
          content = `${pickupCity}<br />Pickup Date: ${pickupDate}`
        } else {
          content = item.country || ''
        }
      } catch (e) {
        content = ''
      }
      return {
        Sr: index + 1,
        'B2B Agent': item.domain_name,
        'Invoice No':
          item.domain_name === 'Inovice Booking'
            ? `INV${item.generate_id}`
            : item.invoice_no,
        'Passenger Name':
          item.domain_name === 'Inovice Booking'
            ? item.b2b_Agent_Company_Name
            : item.lead_passenger,
        'Hotel Name': content,
        'Total Amount': `${
          item.domain_name === 'Inovice Booking'
            ? item.currency_symbol
            : item.exchange_currency
        } ${Number(
          item.domain_name === 'Inovice Booking'
            ? item.total_sale_price_Company
            : item.domain_name === 'Transfer Booking'
            ? item.transfer_total_price
            : item.exchange_price
        ).toFixed(2)}`,
        Status:
          item.domain_name === 'Inovice Booking'
            ? item.confirm === null
              ? 'Tentative'
              : 'Confirm'
            : item.domain_name === 'Transfer Booking'
            ? item.booking_status
            : item.status
      }
    })

    const csv = convertArrayOfObjectsToCSV(csvData)
    if (csv == null) return

    const filename = 'Booking_List.csv'
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [invoiceListData])

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }
    const handlePrint = () => {
      const tableContent = `
       <img className='text-center'>Alhijaz Rooms</img>
        <table border="1" style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Booking Type</th>
              <th>Invoice No</th>
              <th>Passenger Name</th>
              <th>Hotel Name</th>
              <th>Total Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            ${filteredItems
              .map((item, index) => {
                let content = ''
                let id = `item-${index}` // Create a dynamic id based on the index
                try {
                  if (item.domain_name === 'Hotel Booking') {
                    const reservation = JSON.parse(item.reservation_response)
                    const hotelName = reservation.hotel_details.hotel_name || ''
                    const checkIn = moment(
                      reservation.hotel_details.checkIn
                    ).format('DD-MM-YYYY')
                    const checkOut = moment(
                      reservation.hotel_details.checkOut
                    ).format('DD-MM-YYYY')
                    content = `${hotelName}<br />${checkIn} - ${checkOut}`
                  } else if (item.domain_name === 'Transfer Booking') {
                    const transfer = JSON.parse(item.transfer_data)
                    const pickupCity = transfer.pickup_City || ''
                    const pickupDate = moment(transfer.pickup_date).format(
                      'DD-MM-YYYY'
                    )
                    content = `${pickupCity}<br />Pickup Date: ${pickupDate}`
                  } else {
                    content = item.country || ''
                  }
                } catch (e) {
                  content = ''
                }
                return `
                <tr>
                  <td>${index + 1}</td>
                  <td>${item.domain_name}</td>
                  <td>${
                    item.domain_name === 'Inovice Booking'
                      ? `INV${item.generate_id}`
                      : item.invoice_no
                  }</td>
                  <td>${
                    item.domain_name === 'Inovice Booking'
                      ? item.b2b_Agent_Company_Name
                      : item.lead_passenger
                  }</td>
                  <td id={id}>${content}</td>
                  <td>${
                    item.domain_name === 'Inovice Booking'
                      ? item.currency_symbol
                      : item.exchange_currency
                  } ${Number(
                  item.domain_name === 'Inovice Booking'
                    ? item.total_sale_price_Company
                    : item.domain_name === 'Transfer Booking'
                    ? item.transfer_total_price
                    : item.exchange_price
                ).toFixed(2)}</td>
                  <td>${
                    item.domain_name === 'Inovice Booking'
                      ? item.confirm === null
                        ? 'Tentative'
                        : 'Confirm'
                      : item.domain_name === 'Transfer Booking'
                      ? item.booking_status
                      : item.status
                  }</td>
                </tr>
              `
              })
              .join('')}
          </tbody>
        </table>
      `

      const newWindow = window.open()
      newWindow.document.write('<html><head><title>Print Table</title>')
      newWindow.document.write('</head><body>')
      newWindow.document.write(tableContent)
      newWindow.document.write('</body></html>')
      newWindow.document.close()
      newWindow.print()
    }
    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        handlePrint={handlePrint}
        downloadCSV={downloadCSV}
        downloadPDF={handlePrint}
      />
    )
  }, [filterText, resetPaginationToggle, downloadCSV])

  const handlePayment = async () => {
    if (cardInfo.name === '') {
      setError('Enter Card Holder Name.')
      return { success: false }
    }
    const response = await stripe.paymentIntents.create({
      amount: (Number(invoicePayment.exchange_price) * 100).toFixed(0), // Amount in cents
      currency:
        invoicePayment.exchange_currency == null
          ? invoicePayment.selected_currency
          : invoicePayment.exchange_currency,
      description: 'Example payment',
      payment_method_types: ['card']
    })
    var Client_Secret = response.client_secret

    try {
      const { paymentIntent, error } = await stripeInstance.confirmCardPayment(
        Client_Secret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: cardInfo.name
            }
          }
        }
      )

      if (error) {
        setError(error.message)
        console.error(error)
        return { success: false, data: '' }
      } else {
        return { success: true, data: paymentIntent }
      }
    } catch (error) {
      console.error('Error:', error)
      return { success: false, data: '' }
    }
  }
  const handlecarddetail = e => {
    const { value, name } = e.target
    setCardInfo(prevcardinfo => ({ ...prevcardinfo, [name]: value }))
  }
  const OpenPaymentModal = id => {
    var filterinvoice = filteredItems.filter(item => item.invoice_no === id)
    if (filterinvoice.length !== 0) {
      setInvoicePayment(filterinvoice[0])
    }
  }
  const PayNow = async () => {
    setIsPayment(true)
    var status = await handlePayment()
    if (status.success === false) {
      setIsPayment(false)
      return
    }
    var data = {
      token: UserDetail.token,
      b2b_agent_id: UserDetail.id,
      invoice_no: invoicePayment.invoice_no,
      payment_details: JSON.stringify(status.data)
    }
    try {
      const response = await Axios.post(
        baseurl + '/api/hotels/payment_Update',
        data
      )
      setIsPayment(false)
      if (response.data.status === 'success') {
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      setIsPayment(false)
      console.error(error)
    }
  }
  const handlefilterchange=(id)=>{
    if(id===1){
      var newdata=invoiceListData.filter(item=>item.status.toLowerCase()==='confirmed')
      setFilterData(newdata);
      setFilterId(id)
    }else if(id===2){
      var newdata=invoiceListData.filter(item=>item.status.toLowerCase()==='cancelled')
      setFilterData(newdata);
      setFilterId(id)
    }else if(id===3){
      var newdata=invoiceListData.filter(item=>item.status.toLowerCase()!=='cancelled'&&item.status.toLowerCase()!=='confirmed')
      setFilterData(newdata);
      setFilterId(id)
    }

  }
 
  return (
    <>
      <ToastContainer />
      {/* <div className='agent-head'>
        <h5>Total Bookings: {invoiceListData.length}</h5>
      </div>
      <div id='dataTable'>
        <DataTable
          title='My Bookings'
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          customStyles={customStyles}
        />
      </div> */}
      <div class='card border bg-transparent'>
        <div class='card-header bg-transparent border-bottom'>
          <h4 class='card-header-title'>My Bookings</h4>
        </div>
        <div class='card-body p-0'>
          <ul class="nav nav-tabs nav-bottom-line nav-responsive nav-justified">
							<li class="nav-item"> 
								<p onClick={()=>handlefilterchange(1)} style={{cursor:'pointer'}} class="nav-link mb-0 active" data-bs-toggle="tab" ><i class="bi bi-briefcase-fill fa-fw me-1"></i>Confirmed Bookings</p> 
							</li>
							<li class="nav-item">
								<p onClick={()=>handlefilterchange(2)} style={{cursor:'pointer'}} class="nav-link mb-0" data-bs-toggle="tab" ><i class="bi bi-x-octagon fa-fw me-1"></i>Cancelled Bookings</p> 
								</li>
							<li class="nav-item"> 
								<p onClick={()=>handlefilterchange(3)} style={{cursor:'pointer'}} class="nav-link mb-0" data-bs-toggle="tab" ><i class="bi bi-patch-check fa-fw me-1"></i>On Request Bookings</p> 
							</li>
						</ul>
          <div class='tab-content p-2 p-sm-4' id='nav-tabContent'>
            <div class='tab-pane fade show active' id='tab-1'>
              <h6>Total Bookings ({filterData.length})</h6>
              {filterData.map((item, index) => {
                let content = ''
                let checkIn = ''
                let checkOut = ''
                let rooms
                const todayDate = new Date()
                let id = `item-${index}` // Create a dynamic id based on the index
                try {
                  if (item.domain_name === 'Hotel Booking') {
                    const reservation = JSON.parse(item.reservation_response)
                    rooms = reservation.hotel_details.rooms
                    const hotelName = reservation.hotel_details.hotel_name || ''
                    checkIn = moment(reservation.hotel_details.checkIn).format(
                      'll'
                    )
                    checkOut = moment(
                      reservation.hotel_details.checkOut
                    ).format('ll')
                    content = `${hotelName}`
                  }
                } catch (e) {
                  content = ''
                }
                return (
                  <div key={index} class=' mt-2 card rounded-1 border'>
                    <div class='card-header border-bottom d-md-flex justify-content-md-between align-items-center'>
                      <div class='d-flex align-items-center'>
                        <div class='icon-lg bg-light rounded-circle flex-shrink-0'>
                          <i class='fa-solid fa-building'></i>
                        </div>
                        <div class='ms-2'>
                          <h6 class='card-title mb-0'>{content}</h6>
                          <ul class='nav nav-divider small'>
                            <li class='nav-item'>
                              Booking ID:{' '}
                              {
                                JSON.parse(item.reservation_response)
                                  .reference_no
                              }
                              <br /> Invoice no:{' '}
                              {item.domain_name === 'Inovice Booking'
                                ? `INV${item.generate_id}`
                                : item.invoice_no}
                            </li>
                            <li class='nav-item fw-bold'>
                              {item.exchange_currency === null
                                ? item.selected_currency
                                : item.exchange_currency}{' '}
                              {item.exchange_price}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class='mt-2 mt-md-0'>
                        <p
                          class={` ${
                            item.status === 'Cancelled'
                              ? 'bg-danger'
                              : 'bg-success'
                          } text-white p-1 rounded-1 mb-0`}
                        >
                          {item.domain_name === 'Inovice Booking'
                            ? item.confirm === null
                              ? 'Tentative'
                              : 'Confirm'
                            : item.domain_name === 'Transfer Booking'
                            ? item.booking_status
                            : item.status}
                        </p>
                      </div>
                      <div class='mt-2 mt-md-0'>
                        <NavLink
                          target='blank'
                          to={`/hotel_voucher/${item.invoice_no}`}
                        >
                          {' '}
                          <p class='btn btn-primary-soft mb-0'>View Voucher</p>
                        </NavLink>
                      </div>
                    </div>
                    <div class='card-body '>
                      <div class='row g-3'>
                        <div class='col-sm-6 col-md-3'>
                          <span>Check In</span>
                          <h6 class='mb-0'>{checkIn}</h6>
                        </div>

                        <div class='col-sm-6 col-md-3'>
                          <span>Check Out</span>
                          <h6 class='mb-0'>{checkOut}</h6>
                        </div>
                        <div class='col-md-3'>
                          <span>Booked by</span>
                          <h6 class='mb-0'>{item.lead_passenger}</h6>
                        </div>
                        <div class='col-md-3'>
                          <span>Booking Date</span>
                          <h6 class='mb-0'>
                            {moment(item.created_at).format('ll')}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      {rooms.map((item, index) => (
                        <div className='p-1'>
                          <h6>
                            {item.room_rates[0]?.cancellation_policy &&
                            item.room_rates[0]?.cancellation_policy.length >
                              0 ? (
                              new Date(
                                item.room_rates[0]?.cancellation_policy[0].from_date
                              ) > todayDate ? (
                                <span class='text-success  mb-2 mt-2'>
                                  Free Cancellation till{' '}
                                  {moment(
                                    item?.room_rates[0]?.cancellation_policy[0]
                                      .from_date
                                  )
                                    .subtract(1, 'days')
                                    .utc()
                                    .format('ll')}
                                </span>
                              ) : (
                                <span class='text-danger text-center mb-2 mt-2'>
                                  Non-Refundable
                                </span>
                              )
                            ) : (
                              <span class='text-danger text-center mb-2 mt-2'>
                                Non-Refundable
                              </span>
                            )}
                          </h6>
                        </div>
                      ))}
                      {item.status !=='Cancelled' && item.provider !== 'Custome_hotel' &&
                        item.provider !== 'Custome_hotel_provider' &&
                        JSON.parse(item.payment_details) === '' && (
                          <div className='d-flex align-items-center '>
                            <h5>
                              Total Due:{' '}
                              {item.exchange_currency === null
                                ? item.selected_currency
                                : item.exchange_currency}{' '}
                              {item.exchange_price}
                            </h5>
                            <button
                              className='btn btn-warning py-1 rounded-1 mx-1'
                              data-bs-toggle='modal'
                              data-bs-target='#staticBackdrop'
                              onClick={() => OpenPaymentModal(item.invoice_no)}
                            >
                              <FontAwesomeIcon icon={faCreditCard} /> Make
                              Payment
                            </button>
                          </div>
                        )}
                        
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        class='modal fade'
        id='staticBackdrop'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabindex='-1'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h1 class='modal-title fs-5' id='staticBackdropLabel'>
                Make Payment
              </h1>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div class='modal-body'>
              <h6 className='mt-2'>
                Payable Amount :  {invoicePayment.exchange_currency === null
                                ? invoicePayment.selected_currency
                                : invoicePayment.exchange_currency}{' '}
                {invoicePayment.exchange_price}
              </h6>
              <div class='row g-3 mt-3'>
                <div class='col-12'>
                  <label class='form-label'>
                    <span class='h6 fw-normal'>Name on Card *</span>
                  </label>
                  <input
                    type='text'
                    class='form-control'
                    name='name'
                    value={cardInfo.name}
                    onChange={handlecarddetail}
                    placeholder='Name'
                  />
                </div>
                <div class='col-12'>
                  <label class='form-label'>
                    <span class='h6 fw-normal'>Card Number *</span>
                  </label>
                  <div>
                    <CardNumberElement
                      className='form-control'
                      id='cardNumber'
                      placeholder='0000 0000 0000 0000'
                    />
                  </div>
                </div>
                <div class='col-md-6'>
                  <label class='form-label'>
                    <span class='h6 fw-normal'>Expiration date *</span>
                  </label>
                  <CardExpiryElement className='form-control' id='expiryDate' />
                </div>
                <div class='col-md-6'>
                  <label class='form-label'>
                    <span class='h6 fw-normal'>CVV / CVC *</span>
                  </label>
                  <CardCvcElement
                    className='form-control'
                    id='cvc'
                    style={{ width: '40%' }}
                  />
                </div>

                {error && (
                  <div
                    style={{ color: 'red' }}
                    className='error mt-2'
                    role='alert'
                  >
                    {error}
                  </div>
                )}
              </div>
            </div>
            <div class='modal-footer'>
              <Button
                data-bs-dismiss='modal'
                color='red'
                aria-label='Close'
                appearance='primary'
              >
                Close
              </Button>
              <Button onClick={PayNow} appearance='primary' loading={isPayment}>
                Pay Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  handlePrint,
  downloadCSV,
  downloadPDF
}) => (
  <>
    <div>
      <button className='agent-filter-btn' onClick={handlePrint}>
        Print
      </button>
      <button className='agent-filter-btn' onClick={downloadCSV}>
        Download CSV
      </button>
      <button className='agent-filter-btn me-1' onClick={downloadPDF}>
        Download PDF
      </button>
    </div>
    <input
      id='search'
      type='text'
      placeholder='Search...'
      value={filterText}
      onChange={onFilter}
    />
    <button type='button' className='agent-filter-btn' onClick={onClear}>
      Clear
    </button>
  </>
)

export default DashboardPage
