import React from 'react';
import gif from '../../Images/Loader.gif'
const Loading = ({status}) => {
  return (

    <div id="loading-wrapper" style={{zIndex:"9999"}}>
      <div id="loading-text">
              <img src={gif}/>
           </div>
          
    </div>

  );
};

export default Loading;
