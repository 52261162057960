import react, { useState, useEffect } from 'react'
// import '../../Style/NewStyle.css'
// import '../../Style/swiper-bundle.min.css'
// import banner1 from '../../Images/home4/banner-img5.jpeg'

import card1 from '../../Images/View Detail/1.jpeg'
import { useDispatch } from 'react-redux'
import { fetchHotelDetail } from '../../Redux/Actions/actions'
import Axios from 'axios'
import moment from 'moment'
import { fetchHotelsSearh, fetchHotels } from '../../Redux/Actions/actions'
import {
  ApiEndPoint,
  Hotelapitoken,
  CurrencyConverter
} from '../../Components/GlobalData/GlobalData'
import HotelSearchNew from '../../Components/SearchBar/HotelSearchNew'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStopwatch,
  faShield,
  faBoltLightning,
  faQuoteLeft,
  faHeadset,
  faHandHoldingDollar,
  faBed
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import Layout from '../../Components/Layout/Layout'
import Loader from '../../Components/Loading/Loader'
import Explore from '../../Components/ExploreNearBy/Explore'
// New Imports
import OwlCarousel from 'react-owl-carousel'
import room1 from '../../Images/img3.jpeg'
import room2 from '../../Images/img1.jpeg'
import topbg from '../../Images/img4.jpeg'
import img2 from '../../Images/img2.jpeg'
import svg1 from '../../Images/New/01.svg'
import svg2 from '../../Images/New/02.svg'
import svg3 from '../../Images/New/03.svg'
import svg4 from '../../Images/New/04.svg'
import svg5 from '../../Images/New/05.svg'
import svg6 from '../../Images/New/06.svg'
import team1 from '../../Images/New/team1 (1).jpg'
import team2 from '../../Images/New/team1 (2).jpg'
import sale1 from '../../Images/New/offer/01.jpg'
import sale2 from '../../Images/New/offer/02.jpg'
import sale3 from '../../Images/New/offer/03.jpg'
import sale4 from '../../Images/New/offer/04.jpg'
import { ToastContainer,toast } from 'react-toastify'
function HomeNew ({ onLogout }) {
  const options = {
    items: 2,
    autoplay: true,
    autoplayTimeout: 6000, // 6 seconds
    autoplaySpeed: 3000, // 2 second
    loop: true,
    margin: 10,
    nav: true,
    dots: true
  }
  const optionsSale = {
    items: 3,
    autoplay: true,
    autoplayTimeout: 3000, // 3 seconds
    autoplaySpeed: 2000, // 1 second
    loop: true,
    margin: 10,
    nav: true,
    dots: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  }

  var token = Hotelapitoken()
  var apiendpoint = ApiEndPoint()
  const dispatch = useDispatch()
  const [index, setIndex] = useState(0)
  const [userCountryNew, setUserCountry] = useState('')
  const [numberOfNights, setNumberOfNights] = useState('')
  const [isloading, setLoading] = useState(false)
  const [makkahPromotion, setMakkahPromotion] = useState(true)
  const [madinahPromotion, setMadinahPromotion] = useState(true)
  const [isloadingMakkah, setLoadingMakkah] = useState(true)
  const [isloadingMadinah, setLoadingMadinah] = useState(true)
  const [makkahHotels, setMakkahHotels] = useState([])
  const [MadinahHotels, setMadinahHotels] = useState([])
  const [makkahHotelsRes, setMakkahHotelsRes] = useState([])
  const [MadinahHotelsRes, setMadinahHotelsRes] = useState([])
  const [makkahDetail, setMakkahDetail] = useState({})
  const [madinahDetail, setMadinahDetail] = useState({})
  const [GetCurrency, setGetCurrency] = useState(true)
  const [baseCName, setBaseCName] = useState('GBP')
  const [showPrice, setShowPrice] = useState(true)
  const [baseCurrency, setBaseCurrency] = useState([])
  const Dispatch = useDispatch()
  const navigation = useNavigate()
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)

  useEffect(() => {
    getUserLocation()
  }, [])
  function getUserLocation () {
    // Fetch user's location data
    fetch(
      'https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041'
    )
      .then(response => response.json())
      .then(data => {
        SearchMakkahhotels(data.country.name)
        SearchMadinahhotels(data.country.name)
        localStorage.setItem('usercountry', data.country.name)
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error)
      })
  }
  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/hotels/mata_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setMakkahDetail(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }

  const fetchHotelDetails1 = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/hotels/mata_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setMadinahDetail(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const SearchMakkahhotels = async userCountry => {
    const currentDate = new Date()
    const twoDaysLater = new Date(currentDate)
    twoDaysLater.setDate(currentDate.getDate() + 2)
    const threeDaysLater = new Date(currentDate)
    threeDaysLater.setDate(currentDate.getDate() + 3)
    let Checkin = moment(twoDaysLater)
    let checkout = moment(threeDaysLater)
    let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
    setNumberOfNights(daysBetween)

    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      token: token,
      currency_slc: 'AFN',
      currency_slc_iso: 'AF',
      destination_name: 'Makkah',
      country: 'Saudi Arabia',
      lat: 21.4240968,
      long: 39.81733639999999,
      pin: 'SA',
      cityd: 'Makkah',
      country_code: 'SA',
      check_in: moment(twoDaysLater).format('YYYY-MM-DD'),
      check_out: moment(threeDaysLater).format('YYYY-MM-DD'),
      slc_nationality: userCountry,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: []
    }
    try {
      Dispatch(fetchHotelsSearh(FormData))
      const response = await Axios.post(
        apiendpoint + '/api/search/hotels/new_Live',
        FormData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.removeItem('FlightCheckOut')
      var promotion_Hotel_List = response.data.hotels_list.filter(item =>
        item.rooms_options.some(room => room.room_Promotions_Exist === '1')
      )
      setMakkahHotelsRes(response.data)
      console.log(promotion_Hotel_List)
      if (promotion_Hotel_List.length !== 0) {
        promotion_Hotel_List.forEach(hotel => {
          if (!makkahDetail[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
          }
        })
      } else {
        response.data.hotels_list.slice(0, 6).forEach(hotel => {
          if (!makkahDetail[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
          }
        })
      }
      if (response.data.hotels_list.length !== 0) {
        setGetCurrency(false)
        const currencies = []
        response.data.hotels_list.forEach(hotel => {
          if (!currencies.includes(hotel.hotel_curreny)) {
            currencies.push(hotel.hotel_curreny)
          }
        })
        const currencyResponse = await AllCurrency(currencies)
        localStorage.setItem(
          'HotelListCurrency',
          JSON.stringify(currencyResponse)
        )
        setBaseCurrency(currencyResponse)
      }
      setLoadingMakkah(false)
      if (promotion_Hotel_List.length !== 0) {
        setMakkahHotels(promotion_Hotel_List)
      } else {
        setMakkahPromotion(false)
        setMakkahHotels(response.data)
      }
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const SearchMadinahhotels = async userCountry => {
    const currentDate = new Date()
    const twoDaysLater = new Date(currentDate)
    twoDaysLater.setDate(currentDate.getDate() + 2)
    const threeDaysLater = new Date(currentDate)
    threeDaysLater.setDate(currentDate.getDate() + 3)
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      token: token,
      currency_slc: 'AFN',
      currency_slc_iso: 'AF',
      destination_name: 'Madinah',
      country: 'Saudi Arabia',
      lat: 24.4672132,
      long: 39.6024496,
      pin: 'SA',
      cityd: 'Madinah',
      country_code: 'SA',
      check_in: moment(twoDaysLater).format('YYYY-MM-DD'),
      check_out: moment(threeDaysLater).format('YYYY-MM-DD'),
      slc_nationality: userCountry,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: []
    }
    try {
      Dispatch(fetchHotelsSearh(FormData))
      const response = await Axios.post(
        apiendpoint + '/api/search/hotels/new_Live',
        FormData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.removeItem('FlightCheckOut')
      var promotion_Hotel_List = response.data.hotels_list.filter(item =>
        item.rooms_options.some(room => room.room_Promotions_Exist === '1')
      )
      setLoadingMadinah(false)
      setMadinahHotelsRes(response.data)
      if (promotion_Hotel_List.length !== 0) {
        promotion_Hotel_List.forEach(hotel => {
          if (!madinahDetail[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
          }
        })
        setMadinahHotels(promotion_Hotel_List)
      } else {
        setMadinahPromotion(false)
        response.data.hotels_list.slice(0, 6).forEach(hotel => {
          if (!madinahDetail[hotel.hotel_id]) {
            fetchHotelDetails1(hotel.hotel_id, hotel.hotel_provider)
          }
        })
        setMadinahHotels(response.data)
      }
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const RoomDetailPage = async (id, index, city) => {
    var hotelRoomdetail = []
    if (city === 'makkah') {
      if (makkahPromotion) {
        hotelRoomdetail = makkahHotels.filter(item => item.hotel_id == id)
      } else {
        hotelRoomdetail = makkahHotels.hotels_list.filter(
          item => item.hotel_id == id
        )
      }

      dispatch(fetchHotels(makkahHotelsRes))
    } else if (city === 'madinah') {
      if (madinahPromotion) {
        hotelRoomdetail = MadinahHotels.filter(item => item.hotel_id == id)
      } else {
        hotelRoomdetail = MadinahHotels.hotels_list.filter(
          item => item.hotel_id == id
        )
      }
      dispatch(fetchHotels(MadinahHotelsRes))
    }

    setLoading(true)
    try {
      const data = {
        token: token,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id)
      }
      const response = await Axios.post(
        apiendpoint + '/api/hotels/details_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      // Handle the API response here
      if(response.data!==''){
        dispatch(fetchHotelDetail(response.data.hotel_details))
        navigation(`/hotel_detail/${id}`, { state: { index } })
        setLoading(false)
      }else{
        setLoading(false)
        toast.info('Something went wrong. Please select another hotel or try after some time thanks.')
      }
    } catch (error) {
      // Handle errors here
      setLoading(false)
      console.error('Error:', error)
    }
  }
  const AllCurrency = async currencyArray => {
    const token = CurrencyConverter()
    const requests =
      currencyArray.lengt !== 0 &&
      currencyArray.map(currencynew => {
        const config = {
          method: 'get',
          url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currencynew}`, // Replace with your API URL
          maxBodyLength: Infinity,
          headers: {}
        }

        return Axios.request(config)
          .then(response => response.data)
          .catch(error => {
            console.error(error)
            setShowPrice(false)
            return [] // Return null for failed requests
          })
      })
    const results = await Promise.all(requests)
    var newdatcurr = results.filter(response => response !== null)
    return newdatcurr
  }
  const renderPrice = (price, currency) => {
    var currencyData = baseCurrency
    var selectedcurr = localStorage.getItem('DefaultCurrency')
    if (
      selectedcurr === currency ||
      currencyData === null ||
      currencyData.length === 0 ||
      currencyData === null
    ) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2)
      }
      return Number(price)
    } else {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === currency
      )
      if (CurrencyRates === undefined) {
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName] // Use square brackets to access the property
        var baseprice = Number(gbpprice) * Number(price)
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName]
        var baseprice1 = Number(gbpprice) * Number(price)
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = Number(gbpprice2) * Number(baseprice1)
      }
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2)
      }
      return baseprice
    }
  }
  const calculateMarkup = (
    price,
    adminmarkup,
    admintype,
    clientmarkup,
    clienttype
  ) => {
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = Number(price)
    if (Number(adminmarkup) !== 0) {
      if (admintype === 'Percentage') {
        markupprice = (price * Number(adminmarkup)) / 100
      } else {
        markupprice = Number(adminmarkup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      // savemarkup.admin=adminmarkupprice;
      // savemarkup.final=finalpricemarkup;
    }
    if (Number(clientmarkup) !== 0) {
      if (clienttype === 'Percentage') {
        markupprice = (finalpricemarkup * Number(clientmarkup)) / 100
      } else {
        markupprice = Number(clientmarkup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
    }
    if (Number(finalpricemarkup) % 1 !== 0) {
      return Number(finalpricemarkup).toFixed(2)
    }
    return finalpricemarkup
  }
  const promotionFromDate = rooms => {
    const roomWithPromotion = rooms.find(
      room => room.room_Promotions_Exist === '1'
    )
    if (roomWithPromotion) {
      const formattedDate = moment(
        roomWithPromotion.room_Promotions.availible_from
      ).format('DD-MM-YYYY')
      return formattedDate // or assign it to a variable/property as needed
    }
  }
  const promotionToDate = rooms => {
    const roomWithPromotion = rooms.find(
      room => room.room_Promotions_Exist === '1'
    )
    if (roomWithPromotion) {
      const formattedDate = moment(
        roomWithPromotion.room_Promotions.availible_to
      ).format('DD-MM-YYYY')
      return formattedDate // or assign it to a variable/property as needed
    }
  }
  return (
    <>
      {isloading && <Loader />}
      <Layout logout={onLogout}>
        <ToastContainer />
        <section class='pt-3 pt-lg-5'>
          <div class='container'>
            <div class='row g-4 g-lg-5'>
              <div class='col-lg-6 position-relative mb-4 mb-md-0'>
                <h1 class='mb-4 mt-md-5 display-5'>
                 
                  <span class='position-relative z-index-9'>
                  Popular Destinations
                    <span class='position-absolute top-50 start-50 translate-middle z-index-n1 d-none d-md-block mt-4'>
                      <svg
                        width='390.5px'
                        height='21.5px'
                        viewBox='0 0 445.5 21.5'
                      >
                        <path
                          class='fill-primary opacity-7'
                          d='M409.9,2.6c-9.7-0.6-19.5-1-29.2-1.5c-3.2-0.2-6.4-0.2-9.7-0.3c-7-0.2-14-0.4-20.9-0.5 c-3.9-0.1-7.8-0.2-11.7-0.3c-1.1,0-2.3,0-3.4,0c-2.5,0-5.1,0-7.6,0c-11.5,0-23,0-34.5,0c-2.7,0-5.5,0.1-8.2,0.1 c-6.8,0.1-13.6,0.2-20.3,0.3c-7.7,0.1-15.3,0.1-23,0.3c-12.4,0.3-24.8,0.6-37.1,0.9c-7.2,0.2-14.3,0.3-21.5,0.6 c-12.3,0.5-24.7,1-37,1.5c-6.7,0.3-13.5,0.5-20.2,0.9C112.7,5.3,99.9,6,87.1,6.7C80.3,7.1,73.5,7.4,66.7,8 C54,9.1,41.3,10.1,28.5,11.2c-2.7,0.2-5.5,0.5-8.2,0.7c-5.5,0.5-11,1.2-16.4,1.8c-0.3,0-0.7,0.1-1,0.1c-0.7,0.2-1.2,0.5-1.7,1 C0.4,15.6,0,16.6,0,17.6c0,1,0.4,2,1.1,2.7c0.7,0.7,1.8,1.2,2.7,1.1c6.6-0.7,13.2-1.5,19.8-2.1c6.1-0.5,12.3-1,18.4-1.6 c6.7-0.6,13.4-1.1,20.1-1.7c2.7-0.2,5.4-0.5,8.1-0.7c10.4-0.6,20.9-1.1,31.3-1.7c6.5-0.4,13-0.7,19.5-1.1c2.7-0.1,5.4-0.3,8.1-0.4 c10.3-0.4,20.7-0.8,31-1.2c6.3-0.2,12.5-0.5,18.8-0.7c2.1-0.1,4.2-0.2,6.3-0.2c11.2-0.3,22.3-0.5,33.5-0.8 c6.2-0.1,12.5-0.3,18.7-0.4c2.2-0.1,4.4-0.1,6.7-0.1c11.5-0.1,23-0.2,34.6-0.4c7.2-0.1,14.4-0.1,21.6-0.1c12.2,0,24.5,0.1,36.7,0.1 c2.4,0,4.8,0.1,7.2,0.2c6.8,0.2,13.5,0.4,20.3,0.6c5.1,0.2,10.1,0.3,15.2,0.4c3.6,0.1,7.2,0.4,10.8,0.6c10.6,0.6,21.1,1.2,31.7,1.8 c2.7,0.2,5.4,0.4,8,0.6c2.9,0.2,5.8,0.4,8.6,0.7c0.4,0.1,0.9,0.2,1.3,0.3c1.1,0.2,2.2,0.2,3.2-0.4c0.9-0.5,1.6-1.5,1.9-2.5 c0.6-2.2-0.7-4.5-2.9-5.2c-1.9-0.5-3.9-0.7-5.9-0.9c-1.4-0.1-2.7-0.3-4.1-0.4c-2.6-0.3-5.2-0.4-7.9-0.6 C419.7,3.1,414.8,2.9,409.9,2.6z'
                        />
                      </svg>
                    </span>
                  </span>
                </h1>
                <p class='mb-4'>
                Al Hijaz Rooms make your stay perfect at the most popular destinations of Makkah and Madina through cheap hotel room bookings.
                </p>
              </div>

              <div class='col-lg-6 position-relative'>
                <img src={topbg} class='rounded' alt='' />
                <div class='position-absolute top-0 end-0 z-index-1 mt-n4'>
                  <div class='bg-blur border border-light rounded-3 text-center shadow-lg p-3'>
                    <i class='bi bi-headset text-danger fs-3'>
                      <FontAwesomeIcon icon={faHeadset} />
                    </i>
                    <h5 class='text-dark mb-1'>24 / 7</h5>
                    <h6 class='text-dark fw-light small mb-0'>
                      Guide Supports
                    </h6>
                  </div>
                </div>

                <div class='vstack gap-5 align-items-center position-absolute top-0 start-0 d-none d-md-flex mt-4 ms-n3'>
                  <img
                    class='icon-lg shadow-lg border border-3 border-white rounded-circle'
                    src={room1}
                    alt='avatar'
                  />
                  <img
                    class='icon-xl shadow-lg border border-3 border-white rounded-circle'
                    src={room2}
                    alt='avatar'
                  />
                </div>
              </div>
            </div>
            <HotelSearchNew />
          </div>
        </section>

        {/* <section class='pb-2 pb-lg-5'>
          <div class='container'>
            <div class='tiny-slider arrow-round arrow-blur arrow-hover'>
              <div
                class='tiny-slider-inner'
                data-autoplay='true'
                data-arrow='true'
                data-edge='2'
                data-dots='false'
                data-items-xl='3'
                data-items-lg='2'
                data-items-md='1'
              >
                <OwlCarousel
                  className='owl-theme owl-controls'
                  loop='true'
                  {...optionsSale}
                >
                  <div class='item'>
                    <div class='card border rounded-3 overflow-hidden'>
                      <div class='row g-0 align-items-center'>
                        <div class='col-sm-6'>
                          <img src={sale1} class='card-img rounded-0' alt='' />
                        </div>

                        <div class='col-sm-6'>
                          <div class='card-body px-3'>
                            <h6 class='card-title'>
                              <p class='stretched-link'>
                                Daily 50 Lucky Winners get a Free Stay
                              </p>
                            </h6>
                            <p class='mb-0'>Valid till: 15 Nov</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class='item'>
                    <div class='card border rounded-3 overflow-hidden'>
                      <div class='row g-0 align-items-center'>
                        <div class='col-sm-6'>
                          <img src={sale2} class='card-img rounded-0' alt='' />
                        </div>

                        <div class='col-sm-6'>
                          <div class='card-body px-3'>
                            <h6 class='card-title'>
                              <p class='stretched-link'>Up to 60% OFF</p>
                            </h6>
                            <p class='mb-0'>On Hotel Bookings Online</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class='item'>
                    <div class='card border rounded-3 overflow-hidden'>
                      <div class='row g-0 align-items-center'>
                        <div class='col-sm-6'>
                          <img src={sale3} class='card-img rounded-0' alt='' />
                        </div>

                        <div class='col-sm-6'>
                          <div class='card-body px-3'>
                            <h6 class='card-title'>
                              <p class='stretched-link'>Book & Enjoy</p>
                            </h6>
                            <p class='mb-0'>
                              20% Off on the best available room rate
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class='item'>
                    <div class='card border rounded-3 overflow-hidden'>
                      <div class='row g-0 align-items-center'>
                        <div class='col-sm-6'>
                          <img src={sale4} class='card-img rounded-0' alt='' />
                        </div>

                        <div class='col-sm-6'>
                          <div class='card-body px-3'>
                            <h6 class='card-title'>
                              <p class='stretched-link'>Hot Summer Nights</p>
                            </h6>
                            <p class='mb-0'>Up to 3 nights free!</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section> */}
        <section class='pb-0 pb-xl-5'>
          <div class='container'>
            <div class='row g-4 justify-content-between align-items-center'>
              <div class='col-lg-5 position-relative'>
                <figure class='position-absolute top-0 start-0 translate-middle z-index-1 ms-4'>
                  <svg class='fill-warning' width='77px' height='77px'>
                    <path d='M76.997,41.258 L45.173,41.258 L67.676,63.760 L63.763,67.673 L41.261,45.171 L41.261,76.994 L35.728,76.994 L35.728,45.171 L13.226,67.673 L9.313,63.760 L31.816,41.258 L-0.007,41.258 L-0.007,35.725 L31.816,35.725 L9.313,13.223 L13.226,9.311 L35.728,31.813 L35.728,-0.010 L41.261,-0.010 L41.261,31.813 L63.763,9.311 L67.676,13.223 L45.174,35.725 L76.997,35.725 L76.997,41.258 Z' />
                  </svg>
                </figure>

                <figure class='position-absolute bottom-0 end-0 d-none d-md-block mb-n5 me-n4'>
                  <svg
                    height='400'
                    class='fill-primary opacity-2'
                    viewBox='0 0 340 340'
                  >
                    <circle cx='194.2' cy='2.2' r='2.2'></circle>
                    <circle cx='2.2' cy='2.2' r='2.2'></circle>
                    <circle cx='218.2' cy='2.2' r='2.2'></circle>
                    <circle cx='26.2' cy='2.2' r='2.2'></circle>
                    <circle cx='242.2' cy='2.2' r='2.2'></circle>
                    <circle cx='50.2' cy='2.2' r='2.2'></circle>
                    <circle cx='266.2' cy='2.2' r='2.2'></circle>
                    <circle cx='74.2' cy='2.2' r='2.2'></circle>
                    <circle cx='290.2' cy='2.2' r='2.2'></circle>
                    <circle cx='98.2' cy='2.2' r='2.2'></circle>
                    <circle cx='314.2' cy='2.2' r='2.2'></circle>
                    <circle cx='122.2' cy='2.2' r='2.2'></circle>
                    <circle cx='338.2' cy='2.2' r='2.2'></circle>
                    <circle cx='146.2' cy='2.2' r='2.2'></circle>
                    <circle cx='170.2' cy='2.2' r='2.2'></circle>
                    <circle cx='194.2' cy='26.2' r='2.2'></circle>
                    <circle cx='2.2' cy='26.2' r='2.2'></circle>
                    <circle cx='218.2' cy='26.2' r='2.2'></circle>
                    <circle cx='26.2' cy='26.2' r='2.2'></circle>
                    <circle cx='242.2' cy='26.2' r='2.2'></circle>
                    <circle cx='50.2' cy='26.2' r='2.2'></circle>
                    <circle cx='266.2' cy='26.2' r='2.2'></circle>
                    <circle cx='74.2' cy='26.2' r='2.2'></circle>
                    <circle cx='290.2' cy='26.2' r='2.2'></circle>
                    <circle cx='98.2' cy='26.2' r='2.2'></circle>
                    <circle cx='314.2' cy='26.2' r='2.2'></circle>
                    <circle cx='122.2' cy='26.2' r='2.2'></circle>
                    <circle cx='338.2' cy='26.2' r='2.2'></circle>
                    <circle cx='146.2' cy='26.2' r='2.2'></circle>
                    <circle cx='170.2' cy='26.2' r='2.2'></circle>
                    <circle cx='194.2' cy='50.2' r='2.2'></circle>
                    <circle cx='2.2' cy='50.2' r='2.2'></circle>
                    <circle cx='218.2' cy='50.2' r='2.2'></circle>
                    <circle cx='26.2' cy='50.2' r='2.2'></circle>
                    <circle cx='242.2' cy='50.2' r='2.2'></circle>
                    <circle cx='50.2' cy='50.2' r='2.2'></circle>
                    <circle cx='266.2' cy='50.2' r='2.2'></circle>
                    <circle cx='74.2' cy='50.2' r='2.2'></circle>
                    <circle cx='290.2' cy='50.2' r='2.2'></circle>
                    <circle cx='98.2' cy='50.2' r='2.2'></circle>
                    <circle cx='314.2' cy='50.2' r='2.2'></circle>
                    <circle cx='122.2' cy='50.2' r='2.2'></circle>
                    <circle cx='338.2' cy='50.2' r='2.2'></circle>
                    <circle cx='146.2' cy='50.2' r='2.2'></circle>
                    <circle cx='170.2' cy='50.2' r='2.2'></circle>
                    <circle cx='194.2' cy='74.2' r='2.2'></circle>
                    <circle cx='2.2' cy='74.2' r='2.2'></circle>
                    <circle cx='218.2' cy='74.2' r='2.2'></circle>
                    <circle cx='26.2' cy='74.2' r='2.2'></circle>
                    <circle cx='242.2' cy='74.2' r='2.2'></circle>
                    <circle cx='50.2' cy='74.2' r='2.2'></circle>
                    <circle cx='266.2' cy='74.2' r='2.2'></circle>
                    <circle cx='74.2' cy='74.2' r='2.2'></circle>
                    <circle cx='290.2' cy='74.2' r='2.2'></circle>
                    <circle cx='98.2' cy='74.2' r='2.2'></circle>
                    <circle cx='314.2' cy='74.2' r='2.2'></circle>
                    <circle cx='122.2' cy='74.2' r='2.2'></circle>
                    <circle cx='338.2' cy='74.2' r='2.2'></circle>
                    <circle cx='146.2' cy='74.2' r='2.2'></circle>
                    <circle cx='170.2' cy='74.2' r='2.2'></circle>
                    <circle cx='194.2' cy='98.2' r='2.2'></circle>
                    <circle cx='2.2' cy='98.2' r='2.2'></circle>
                    <circle cx='218.2' cy='98.2' r='2.2'></circle>
                    <circle cx='26.2' cy='98.2' r='2.2'></circle>
                    <circle cx='242.2' cy='98.2' r='2.2'></circle>
                    <circle cx='50.2' cy='98.2' r='2.2'></circle>
                    <circle cx='266.2' cy='98.2' r='2.2'></circle>
                    <circle cx='74.2' cy='98.2' r='2.2'></circle>
                    <circle cx='290.2' cy='98.2' r='2.2'></circle>
                    <circle cx='98.2' cy='98.2' r='2.2'></circle>
                    <circle cx='314.2' cy='98.2' r='2.2'></circle>
                    <circle cx='122.2' cy='98.2' r='2.2'></circle>
                    <circle cx='338.2' cy='98.2' r='2.2'></circle>
                    <circle cx='146.2' cy='98.2' r='2.2'></circle>
                    <circle cx='170.2' cy='98.2' r='2.2'></circle>
                    <circle cx='194.2' cy='122.2' r='2.2'></circle>
                    <circle cx='2.2' cy='122.2' r='2.2'></circle>
                    <circle cx='218.2' cy='122.2' r='2.2'></circle>
                    <circle cx='26.2' cy='122.2' r='2.2'></circle>
                    <circle cx='242.2' cy='122.2' r='2.2'></circle>
                    <circle cx='50.2' cy='122.2' r='2.2'></circle>
                    <circle cx='266.2' cy='122.2' r='2.2'></circle>
                    <circle cx='74.2' cy='122.2' r='2.2'></circle>
                    <circle cx='290.2' cy='122.2' r='2.2'></circle>
                    <circle cx='98.2' cy='122.2' r='2.2'></circle>
                    <circle cx='314.2' cy='122.2' r='2.2'></circle>
                    <circle cx='122.2' cy='122.2' r='2.2'></circle>
                    <circle cx='338.2' cy='122.2' r='2.2'></circle>
                    <circle cx='146.2' cy='122.2' r='2.2'></circle>
                    <circle cx='170.2' cy='122.2' r='2.2'></circle>
                    <circle cx='194.2' cy='146.2' r='2.2'></circle>
                    <circle cx='2.2' cy='146.2' r='2.2'></circle>
                    <circle cx='218.2' cy='146.2' r='2.2'></circle>
                    <circle cx='26.2' cy='146.2' r='2.2'></circle>
                    <circle cx='242.2' cy='146.2' r='2.2'></circle>
                    <circle cx='50.2' cy='146.2' r='2.2'></circle>
                    <circle cx='266.2' cy='146.2' r='2.2'></circle>
                    <circle cx='74.2' cy='146.2' r='2.2'></circle>
                    <circle cx='290.2' cy='146.2' r='2.2'></circle>
                    <circle cx='98.2' cy='146.2' r='2.2'></circle>
                    <circle cx='314.2' cy='146.2' r='2.2'></circle>
                    <circle cx='122.2' cy='146.2' r='2.2'></circle>
                    <circle cx='338.2' cy='146.2' r='2.2'></circle>
                    <circle cx='146.2' cy='146.2' r='2.2'></circle>
                    <circle cx='170.2' cy='146.2' r='2.2'></circle>
                    <circle cx='194.2' cy='170.2' r='2.2'></circle>
                    <circle cx='2.2' cy='170.2' r='2.2'></circle>
                    <circle cx='218.2' cy='170.2' r='2.2'></circle>
                    <circle cx='26.2' cy='170.2' r='2.2'></circle>
                    <circle cx='242.2' cy='170.2' r='2.2'></circle>
                    <circle cx='50.2' cy='170.2' r='2.2'></circle>
                    <circle cx='266.2' cy='170.2' r='2.2'></circle>
                    <circle cx='74.2' cy='170.2' r='2.2'></circle>
                    <circle cx='290.2' cy='170.2' r='2.2'></circle>
                    <circle cx='98.2' cy='170.2' r='2.2'></circle>
                    <circle cx='314.2' cy='170.2' r='2.2'></circle>
                    <circle cx='122.2' cy='170.2' r='2.2'></circle>
                    <circle cx='338.2' cy='170.2' r='2.2'></circle>
                    <circle cx='146.2' cy='170.2' r='2.2'></circle>
                    <circle cx='170.2' cy='170.2' r='2.2'></circle>
                    <circle cx='194.2' cy='194.2' r='2.2'></circle>
                    <circle cx='2.2' cy='194.2' r='2.2'></circle>
                    <circle cx='218.2' cy='194.2' r='2.2'></circle>
                    <circle cx='26.2' cy='194.2' r='2.2'></circle>
                    <circle cx='242.2' cy='194.2' r='2.2'></circle>
                    <circle cx='50.2' cy='194.2' r='2.2'></circle>
                    <circle cx='266.2' cy='194.2' r='2.2'></circle>
                    <circle cx='74.2' cy='194.2' r='2.2'></circle>
                    <circle cx='290.2' cy='194.2' r='2.2'></circle>
                    <circle cx='98.2' cy='194.2' r='2.2'></circle>
                    <circle cx='314.2' cy='194.2' r='2.2'></circle>
                    <circle cx='122.2' cy='194.2' r='2.2'></circle>
                    <circle cx='338.2' cy='194.2' r='2.2'></circle>
                    <circle cx='146.2' cy='194.2' r='2.2'></circle>
                    <circle cx='170.2' cy='194.2' r='2.2'></circle>
                    <circle cx='194.2' cy='218.2' r='2.2'></circle>
                    <circle cx='2.2' cy='218.2' r='2.2'></circle>
                    <circle cx='218.2' cy='218.2' r='2.2'></circle>
                    <circle cx='26.2' cy='218.2' r='2.2'></circle>
                    <circle cx='242.2' cy='218.2' r='2.2'></circle>
                    <circle cx='50.2' cy='218.2' r='2.2'></circle>
                    <circle cx='266.2' cy='218.2' r='2.2'></circle>
                    <circle cx='74.2' cy='218.2' r='2.2'></circle>
                    <circle cx='290.2' cy='218.2' r='2.2'></circle>
                    <circle cx='98.2' cy='218.2' r='2.2'></circle>
                    <circle cx='314.2' cy='218.2' r='2.2'></circle>
                    <circle cx='122.2' cy='218.2' r='2.2'></circle>
                    <circle cx='338.2' cy='218.2' r='2.2'></circle>
                    <circle cx='146.2' cy='218.2' r='2.2'></circle>
                    <circle cx='170.2' cy='218.2' r='2.2'></circle>
                    <circle cx='194.2' cy='242.2' r='2.2'></circle>
                    <circle cx='2.2' cy='242.2' r='2.2'></circle>
                    <circle cx='218.2' cy='242.2' r='2.2'></circle>
                    <circle cx='26.2' cy='242.2' r='2.2'></circle>
                    <circle cx='242.2' cy='242.2' r='2.2'></circle>
                    <circle cx='50.2' cy='242.2' r='2.2'></circle>
                    <circle cx='266.2' cy='242.2' r='2.2'></circle>
                    <circle cx='74.2' cy='242.2' r='2.2'></circle>
                    <circle cx='290.2' cy='242.2' r='2.2'></circle>
                    <circle cx='98.2' cy='242.2' r='2.2'></circle>
                    <circle cx='314.2' cy='242.2' r='2.2'></circle>
                    <circle cx='122.2' cy='242.2' r='2.2'></circle>
                    <circle cx='338.2' cy='242.2' r='2.2'></circle>
                    <circle cx='146.2' cy='242.2' r='2.2'></circle>
                    <circle cx='170.2' cy='242.2' r='2.2'></circle>
                    <circle cx='194.2' cy='266.2' r='2.2'></circle>
                    <circle cx='2.2' cy='266.2' r='2.2'></circle>
                    <circle cx='218.2' cy='266.2' r='2.2'></circle>
                    <circle cx='26.2' cy='266.2' r='2.2'></circle>
                    <circle cx='242.2' cy='266.2' r='2.2'></circle>
                    <circle cx='50.2' cy='266.2' r='2.2'></circle>
                    <circle cx='266.2' cy='266.2' r='2.2'></circle>
                    <circle cx='74.2' cy='266.2' r='2.2'></circle>
                    <circle cx='290.2' cy='266.2' r='2.2'></circle>
                    <circle cx='98.2' cy='266.2' r='2.2'></circle>
                    <circle cx='314.2' cy='266.2' r='2.2'></circle>
                    <circle cx='122.2' cy='266.2' r='2.2'></circle>
                    <circle cx='338.2' cy='266.2' r='2.2'></circle>
                    <circle cx='146.2' cy='266.2' r='2.2'></circle>
                    <circle cx='170.2' cy='266.2' r='2.2'></circle>
                    <circle cx='194.2' cy='290.2' r='2.2'></circle>
                    <circle cx='2.2' cy='290.2' r='2.2'></circle>
                    <circle cx='218.2' cy='290.2' r='2.2'></circle>
                    <circle cx='26.2' cy='290.2' r='2.2'></circle>
                    <circle cx='242.2' cy='290.2' r='2.2'></circle>
                    <circle cx='50.2' cy='290.2' r='2.2'></circle>
                    <circle cx='266.2' cy='290.2' r='2.2'></circle>
                    <circle cx='74.2' cy='290.2' r='2.2'></circle>
                    <circle cx='290.2' cy='290.2' r='2.2'></circle>
                    <circle cx='98.2' cy='290.2' r='2.2'></circle>
                    <circle cx='314.2' cy='290.2' r='2.2'></circle>
                    <circle cx='122.2' cy='290.2' r='2.2'></circle>
                    <circle cx='338.2' cy='290.2' r='2.2'></circle>
                    <circle cx='146.2' cy='290.2' r='2.2'></circle>
                    <circle cx='170.2' cy='290.2' r='2.2'></circle>
                    <circle cx='194.2' cy='314.2' r='2.2'></circle>
                    <circle cx='2.2' cy='314.2' r='2.2'></circle>
                    <circle cx='218.2' cy='314.2' r='2.2'></circle>
                    <circle cx='26.2' cy='314.2' r='2.2'></circle>
                    <circle cx='242.2' cy='314.2' r='2.2'></circle>
                    <circle cx='50.2' cy='314.2' r='2.2'></circle>
                    <circle cx='266.2' cy='314.2' r='2.2'></circle>
                    <circle cx='74.2' cy='314.2' r='2.2'></circle>
                    <circle cx='290.2' cy='314.2' r='2.2'></circle>
                    <circle cx='98.2' cy='314.2' r='2.2'></circle>
                    <circle cx='314.2' cy='314.2' r='2.2'></circle>
                    <circle cx='122.2' cy='314.2' r='2.2'></circle>
                    <circle cx='338.2' cy='314.2' r='2.2'></circle>
                    <circle cx='146.2' cy='314.2' r='2.2'></circle>
                    <circle cx='170.2' cy='314.2' r='2.2'></circle>
                    <circle cx='194.2' cy='338.2' r='2.2'></circle>
                    <circle cx='2.2' cy='338.2' r='2.2'></circle>
                    <circle cx='218.2' cy='338.2' r='2.2'></circle>
                    <circle cx='26.2' cy='338.2' r='2.2'></circle>
                    <circle cx='242.2' cy='338.2' r='2.2'></circle>
                    <circle cx='50.2' cy='338.2' r='2.2'></circle>
                    <circle cx='266.2' cy='338.2' r='2.2'></circle>
                    <circle cx='74.2' cy='338.2' r='2.2'></circle>
                    <circle cx='290.2' cy='338.2' r='2.2'></circle>
                    <circle cx='98.2' cy='338.2' r='2.2'></circle>
                    <circle cx='314.2' cy='338.2' r='2.2'></circle>
                    <circle cx='122.2' cy='338.2' r='2.2'></circle>
                    <circle cx='338.2' cy='338.2' r='2.2'></circle>
                    <circle cx='146.2' cy='338.2' r='2.2'></circle>
                    <circle cx='170.2' cy='338.2' r='2.2'></circle>
                  </svg>
                </figure>

                <img src={img2} class='rounded-3 position-relative' alt='' />
              </div>

              <div class='col-lg-6'>
                <h2 class='mb-3 mb-lg-5'>Welcome to Al Hijjaz Rooms</h2>
                

                <div class='row g-4'>
                  <div class='col-sm-6'>
                    <div class='icon-lg bg-success bg-opacity-10 text-success rounded-circle'>
                    <FontAwesomeIcon icon={faHandHoldingDollar} />
                    </div>
                    <h5 class='mt-2'>Best Price Guarantee</h5>
                    <p class='mb-0'>
                    Al Hijaz Rooms has partnered with more than 500 hotels in Makkah and Madina and connected with more than 10 3rd party APIs for Worldwide hotels. We guarantee the best prices for all the deals available to the agents. Our partnered hotels on the other hand have an opportunity to get viewed by thousand of agents all around the world.

                    </p>
                  </div>
                  <div class='col-sm-6'>
                    <div class='icon-lg bg-danger bg-opacity-10 text-danger rounded-circle'>
                      <i class='bi bi-stopwatch-fill'>
                        <FontAwesomeIcon icon={faBed} />
                      </i>
                    </div>
                    <h5 class='mt-2'>How to book Hotel Rooms?</h5>
                    <p class='mb-0'>
                    Al Hijaz Rooms offer you hotel rooms search with price comparisons. Prices given at Al Hijaz Rooms website comes from partner hotels and 3rd party APIs. You can search desired room for clients from list of hotel rooms available by just specifying requirements in 1 click.{' '}
                    </p>
                  </div>
                  <div class='col-sm-6'>
                    <div class='icon-lg bg-orange bg-opacity-10 text-orange rounded-circle'>
                      <i class='bi bi-shield-fill-check'>
                        <FontAwesomeIcon icon={faShield} />
                      </i>
                    </div>
                    <h5 class='mt-2'>Why Choose Us?</h5>
                    <p class='mb-0'>
                    Al Hijaz Rooms provides a complete list of options, and you can decide the best room according to client needs. Booking process gets completed by taking all requirements and offering cheap hotel rates through deal hotel room deals in Makkah, Madina and all over the world.{' '}
                    </p>
                  </div>
                  <div class='col-sm-6'>
                    <div class='icon-lg bg-info bg-opacity-10 text-info rounded-circle'>
                      <i class='bi bi-lightning-fill'>
                        <FontAwesomeIcon icon={faBoltLightning} />
                      </i>
                    </div>
                    <h5 class='mt-2'>24/7 Customer Support</h5>
                    <p class='mb-0'>
                    If you need any help during your hotel rooms search, our team is available to help and provide support 24/7. You just need to contact us to make a confirm booking for your client and gain the status of a reliable agent. Try Al Hijaz Rooms to book cheap hotel rooms now.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {makkahPromotion ? (
          <section>
            <div class='container'>
              <div class='row mb-4'>
                <div class='col-12 text-center'>
                  <h2 class='mb-0'>Makkah Promotion Hotels</h2>
                </div>
              </div>
              {isloadingMakkah ? (
                <div class='row g-4'>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div class='row g-4'>
                  {makkahHotels.map((item, index) => (
                    <div key={index} class='col-sm-6 col-xl-3'>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          RoomDetailPage(item.hotel_id, index, 'makkah')
                        }
                        class='card card-img-scale overflow-hidden bg-transparent'
                      >
                        <div
                          style={{ height: '25em' }}
                          class='card-img-scale-wrapper rounded-3'
                        >
                          {makkahDetail[item.hotel_id] &&
                          makkahDetail[item.hotel_id].details_data &&
                          makkahDetail[item.hotel_id].details_data.image ? (
                            <img
                              style={{
                                height: '25em',
                                width: '100%',
                                objectFit: 'cover'
                              }}
                              src={
                                makkahDetail[item.hotel_id].details_data.image
                              }
                              class='card-img'
                              alt='hotel image'
                            />
                          ) : (
                            <img
                              style={{
                                height: '25em',
                                width: '100%',
                                objectFit: 'cover'
                              }}
                              src={card1}
                              class='card-img'
                              alt='hotel image'
                            />
                          )}

                          <div class='position-absolute bottom-0 start-0 p-3'>
                            <div class='badge text-bg-dark fs-6 rounded-pill stretched-link'>
                              Special Discount
                            </div>
                          </div>
                        </div>

                        <div class='card-body px-2'>
                          <div className='d-flex fw-bold align-items-center justify-content-between promotion-card-devider p-2'>
                            <p>{promotionFromDate(item.rooms_options)}</p>
                            <p className='mt-0'>to</p>
                            <p className='mt-0'>
                              {promotionToDate(item.rooms_options)}
                            </p>
                          </div>
                          <h4 class='card-title text-ellipes'>
                            {item.hotel_name}
                          </h4>
                          <div class='d-flex justify-content-between align-items-center'>
                            {/* <h6 class="text-success mb-0">$455 <small class="fw-light">/starting at</small> </h6> */}
                            {showPrice ? (
                              <h6 class='text-success mb-0'>
                                {CurrencyRates === undefined
                                  ? baseCName
                                  : CurrencyRates.selectedcurrency}{' '}
                                {renderPrice(
                                  calculateMarkup(
                                    item.min_price,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  ),
                                  item?.hotel_curreny
                                )}
                                <small class='fw-light'>
                                  /{' '}
                                  <del>
                                    {renderPrice(
                                      calculateMarkup(
                                        item.min_price_Actual,
                                        item.admin_markup,
                                        item.admin_markup_type,
                                        item.customer_markup,
                                        item.customer_markup_type
                                      ),
                                      item?.hotel_curreny
                                    )}
                                  </del>
                                </small>
                              </h6>
                            ) : (
                              <h6 class='text-success mb-0'>
                                {item?.hotel_curreny}{' '}
                                {calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                )}
                                <small class='fw-light'>
                                  /{' '}
                                  <del>
                                    {calculateMarkup(
                                      item.min_price_Actual,
                                      item.admin_markup,
                                      item.admin_markup_type,
                                      item.customer_markup,
                                      item.customer_markup_type
                                    )}
                                  </del>
                                </small>
                              </h6>
                            )}
                            <h6 class='mb-0'>
                              {item.stars_rating === '' ? (
                                <span className='fw-bold'>No Rating</span>
                              ) : (
                                Array(item.stars_rating)
                                  .fill(0)
                                  .map((_, index) => (
                                    <i
                                      key={index}
                                      class='fa-solid fa-star text-warning ms-1'
                                    ></i>
                                  ))
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>
        ) : (
          <section>
            <div class='container'>
              <div class='row mb-4'>
                <div class='col-12 text-center'>
                  <h2 class='mb-0'>Makkah Top Hotels</h2>
                </div>
              </div>
              {isloadingMakkah ? (
                <div class='row g-4'>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div class='row g-4'>
                  {makkahHotels.hotels_list.slice(0, 6).map((item, index) => (
                    <div key={index} class='col-sm-6 col-xl-3'>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          RoomDetailPage(item.hotel_id, index, 'makkah')
                        }
                        class='card card-img-scale overflow-hidden bg-transparent'
                      >
                        <div
                          style={{ height: '25em' }}
                          class='card-img-scale-wrapper rounded-3'
                        >
                          {makkahDetail[item.hotel_id] &&
                          makkahDetail[item.hotel_id].details_data &&
                          makkahDetail[item.hotel_id].details_data.image ? (
                            <img
                              style={{
                                height: '25em',
                                width: '100%',
                                objectFit: 'cover'
                              }}
                              src={
                                makkahDetail[item.hotel_id].details_data.image
                              }
                              class='card-img'
                              alt='hotel image'
                            />
                          ) : (
                            <img
                              style={{
                                height: '25em',
                                width: '100%',
                                objectFit: 'cover'
                              }}
                              src={card1}
                              class='card-img'
                              alt='hotel image'
                            />
                          )}

                          {/* <div class="position-absolute bottom-0 start-0 p-3">
                        <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                      </div> */}
                        </div>

                        <div class='card-body px-2'>
                          <h4 class='card-title text-ellipes'>
                            {item.hotel_name}
                          </h4>
                          <div class='d-flex justify-content-between align-items-center'>
                            {/* <h6 class="text-success mb-0">$455 <small class="fw-light">/starting at</small> </h6> */}
                            {showPrice ? (
                              <h6 class='text-success mb-0'>
                                {CurrencyRates === undefined
                                  ? baseCName
                                  : CurrencyRates.selectedcurrency}{' '}
                                {renderPrice(
                                  calculateMarkup(
                                    item.min_price,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  ),
                                  item?.hotel_curreny
                                )}
                                <small class='fw-light'>/1 Night</small>
                              </h6>
                            ) : (
                              <h6 class='text-success mb-0'>
                                {item?.hotel_curreny}{' '}
                                {calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                )}
                                <small class='fw-light'>/1 Night</small>
                              </h6>
                            )}
                            <h6 class='mb-0'>
                              {item.stars_rating === '' ? (
                                <span className='fw-bold'>No Rating</span>
                              ) : (
                                Array(item.stars_rating)
                                  .fill(0)
                                  .map((_, index) => (
                                    <i
                                      key={index}
                                      class='fa-solid fa-star text-warning ms-1'
                                    ></i>
                                  ))
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>
        )}
        {madinahPromotion ? (
          <section>
            <div class='container'>
              <div class='row mb-4'>
                <div class='col-12 text-center'>
                  <h2 class='mb-0'>Madinah Promotion Hotels</h2>
                </div>
              </div>
              {isloadingMadinah ? (
                <div class='row g-4'>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div class='row g-4'>
                  {MadinahHotels.map((item, index) => (
                    <div key={index} class='col-sm-6 col-xl-3'>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          RoomDetailPage(item.hotel_id, index, 'makkah')
                        }
                        class='card card-img-scale overflow-hidden bg-transparent'
                      >
                        <div
                          style={{ height: '25em' }}
                          class='card-img-scale-wrapper rounded-3'
                        >
                          {madinahDetail[item.hotel_id] &&
                          madinahDetail[item.hotel_id].details_data &&
                          madinahDetail[item.hotel_id].details_data.image ? (
                            <img
                              style={{
                                height: '25em',
                                width: '100%',
                                objectFit: 'cover'
                              }}
                              src={
                                madinahDetail[item.hotel_id].details_data.image
                              }
                              class='card-img'
                              alt='hotel image'
                            />
                          ) : (
                            <img
                              style={{
                                height: '25em',
                                width: '100%',
                                objectFit: 'cover'
                              }}
                              src={card1}
                              class='card-img'
                              alt='hotel image'
                            />
                          )}

                          <div class='position-absolute bottom-0 start-0 p-3'>
                            <div class='badge text-bg-dark fs-6 rounded-pill stretched-link'>
                              Special Discount
                            </div>
                          </div>
                        </div>

                        <div class='card-body px-2'>
                          <div className='d-flex fw-bold align-items-center justify-content-between promotion-card-devider p-2'>
                            <p>{promotionFromDate(item.rooms_options)}</p>
                            <p className='mt-0'>to</p>
                            <p className='mt-0'>
                              {promotionToDate(item.rooms_options)}
                            </p>
                          </div>
                          <h4 class='card-title text-ellipes'>
                            {item.hotel_name}
                          </h4>
                          <div class='d-flex justify-content-between align-items-center'>
                            {/* <h6 class="text-success mb-0">$455 <small class="fw-light">/starting at</small> </h6> */}
                            {showPrice ? (
                              <h6 class='text-success mb-0'>
                                {CurrencyRates === undefined
                                  ? baseCName
                                  : CurrencyRates.selectedcurrency}{' '}
                                {renderPrice(
                                  calculateMarkup(
                                    item.min_price,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  ),
                                  item?.hotel_curreny
                                )}
                                <small class='fw-light'>
                                  /{' '}
                                  <del>
                                    {renderPrice(
                                      calculateMarkup(
                                        item.min_price_Actual,
                                        item.admin_markup,
                                        item.admin_markup_type,
                                        item.customer_markup,
                                        item.customer_markup_type
                                      ),
                                      item?.hotel_curreny
                                    )}
                                  </del>
                                </small>
                              </h6>
                            ) : (
                              <h6 class='text-success mb-0'>
                                {item?.hotel_curreny}{' '}
                                {calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                )}
                                <small class='fw-light'>
                                  /{' '}
                                  <del>
                                    {calculateMarkup(
                                      item.min_price_Actual,
                                      item.admin_markup,
                                      item.admin_markup_type,
                                      item.customer_markup,
                                      item.customer_markup_type
                                    )}
                                  </del>
                                </small>
                              </h6>
                            )}
                            <h6 class='mb-0'>
                              {item.stars_rating === '' ? (
                                <span className='fw-bold'>No Rating</span>
                              ) : (
                                Array(item.stars_rating)
                                  .fill(0)
                                  .map((_, index) => (
                                    <i
                                      key={index}
                                      class='fa-solid fa-star text-warning ms-1'
                                    ></i>
                                  ))
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>
        ) : (
          <section>
            <div class='container'>
              <div class='row mb-4'>
                <div class='col-12 text-center'>
                  <h2 class='mb-0'>Madinah Top Hotels</h2>
                </div>
              </div>
              {isloadingMadinah ? (
                <div class='row g-4'>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-sm-6 col-xl-3'>
                    <div class='card card-img-scale overflow-hidden bg-transparent'>
                      <div class='card-img-scale-wrapper rounded-3'>
                        <div
                          style={{ background: '#c88698', height: '25em' }}
                        ></div>
                        {/* <div class="position-absolute bottom-0 start-0 p-3">
                      <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                    </div> */}
                      </div>

                      <div class='card-body px-2'>
                        <h5 class='card-title placeholder-glow'>
                          <span className='placeholder col-10'></span>
                        </h5>
                        <div class='d-flex justify-content-between align-items-center'>
                          <h6 class='text-success mb-0 placeholder-glow'>
                            <span className='placeholder col-12'></span>
                            <span className='placeholder col-9'></span>
                            <span className='placeholder col-5'></span>
                          </h6>
                          {/* <h6 class="mb-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div class='row g-4'>
                  {MadinahHotels.hotels_list.slice(0, 6).map((item, index) => (
                    <div key={index} class='col-sm-6 col-xl-3'>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          RoomDetailPage(item.hotel_id, index, 'makkah')
                        }
                        class='card card-img-scale overflow-hidden bg-transparent'
                      >
                        <div
                          style={{ height: '25em' }}
                          class='card-img-scale-wrapper rounded-3'
                        >
                          {madinahDetail[item.hotel_id] &&
                          madinahDetail[item.hotel_id].details_data &&
                          madinahDetail[item.hotel_id].details_data.image ? (
                            <img
                              style={{
                                height: '25em',
                                width: '100%',
                                objectFit: 'cover'
                              }}
                              src={
                                madinahDetail[item.hotel_id].details_data.image
                              }
                              class='card-img'
                              alt='hotel image'
                            />
                          ) : (
                            <img
                              style={{
                                height: '25em',
                                width: '100%',
                                objectFit: 'cover'
                              }}
                              src={card1}
                              class='card-img'
                              alt='hotel image'
                            />
                          )}

                          {/* <div class="position-absolute bottom-0 start-0 p-3">
                        <div class="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>New York</div>
                      </div> */}
                        </div>

                        <div class='card-body px-2'>
                          <h4 class='card-title text-ellipes'>
                            {item.hotel_name}
                          </h4>
                          <div class='d-flex justify-content-between align-items-center'>
                            {/* <h6 class="text-success mb-0">$455 <small class="fw-light">/starting at</small> </h6> */}
                            {showPrice ? (
                              <h6 class='text-success mb-0'>
                                {CurrencyRates === undefined
                                  ? baseCName
                                  : CurrencyRates.selectedcurrency}{' '}
                                {renderPrice(
                                  calculateMarkup(
                                    item.min_price,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  ),
                                  item?.hotel_curreny
                                )}
                                <small class='fw-light'>/1 Night</small>
                              </h6>
                            ) : (
                              <h6 class='text-success mb-0'>
                                {item?.hotel_curreny}{' '}
                                {calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                )}
                                <small class='fw-light'>/1 Night</small>
                              </h6>
                            )}
                            <h6 class='mb-0'>
                              {item.stars_rating === '' ? (
                                <span className='fw-bold'>No Rating</span>
                              ) : (
                                Array(item.stars_rating)
                                  .fill(0)
                                  .map((_, index) => (
                                    <i
                                      key={index}
                                      class='fa-solid fa-star text-warning ms-1'
                                    ></i>
                                  ))
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>
        )}

        <section class='py-0 py-md-5 d-none'>
          <div class='container'>
            <div class='row g-4 g-lg-7 justify-content-center align-items-center'>
              <div class='col-5 col-sm-3 col-xl-2'>
                <img src={svg1} class='grayscale' alt='' />
              </div>
              <div class='col-5 col-sm-3 col-xl-2'>
                <img src={svg2} class='grayscale' alt='' />
              </div>
              <div class='col-5 col-sm-3 col-xl-2'>
                <img src={svg3} class='grayscale' alt='' />
              </div>
              <div class='col-5 col-sm-3 col-xl-2'>
                <img src={svg4} class='grayscale' alt='' />
              </div>
              <div class='col-5 col-sm-3 col-xl-2'>
                <img src={svg5} class='grayscale' alt='' />
              </div>
              <div class='col-5 col-sm-3 col-xl-2'>
                <img src={svg6} class='grayscale' alt='' />
              </div>
            </div>
          </div>
        </section>
        <section class='pb-0 py-md-5'>
          <div class='container'>
            <div class='row'>
              <div class='col-lg-11 mx-auto'>
                <div class='tiny-slider arrow-round arrow-border arrow-hover'>
                  <div class='tiny-slider-inner' data-edge='2' data-items='1'>
                    <OwlCarousel
                      className='owl-theme owl-controls'
                      loop='true'
                      {...options}
                    >
                      <div class='item '>
                        <div class='row justify-content-between align-items-center'>
                         

                          <div class='col-md-12'>
                            <span class='display-3 mb-0 text-primary'>
                              <i class='bi bi-quote'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                              </i>
                            </span>
                            <h3>Fantastic Experience with Al Hijaz Rooms!</h3>
                            <h5 class='fw-light'>
                            I recently used Al Hijaz Rooms to book a hotel for my family vacation, and I couldn’t be more pleased with the experience. The website is user-friendly, and the booking process was seamless. We found a great deal on a five-star hotel that met all our expectations. The customer service team was also very responsive and helpful with our queries. I highly recommend Al Hijaz Rooms for anyone looking for hassle-free hotel bookings!
                            </h5>
                            <ul class='list-inline small mb-2'>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item'>
                                <i class='fa-solid fa-star-half-alt text-warning'></i>
                              </li>
                            </ul>
                            <h6 class='mb-0'>Ahmed Sabor</h6>
                          
                          </div>
                          
                        </div>
                      </div>
                      <div class='item '>
                        <div class='row justify-content-between align-items-center'>
                       

                          <div class='col-md-12'>
                            <span class='display-3 mb-0 text-primary'>
                              <i class='bi bi-quote'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                              </i>
                            </span>
                            <h3>Easy to Use and Great Deals</h3>
                            <h5 class='fw-light'>
                            Al Hijaz Rooms made booking our holiday accommodation a breeze. The site offers a wide range of hotels at competitive prices. We appreciated the detailed descriptions and genuine user reviews which helped us make an informed choice. Our hotel stay was exactly as advertised, and the check-in process was smooth. We will definitely use Al Hijaz Rooms for our future trips.
                            </h5>
                            <ul class='list-inline small mb-2'>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item'>
                                <i class='fa-solid fa-star-half-alt text-warning'></i>
                              </li>
                            </ul>
                            <h6 class='mb-0'>Sheikh Usman</h6>
                            
                          </div>
                        </div>
                      </div>
                      <div class='item '>
                        <div class='row justify-content-between align-items-center'>
                       

                          <div class='col-md-12'>
                            <span class='display-3 mb-0 text-primary'>
                              <i class='bi bi-quote'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                              </i>
                            </span>
                            <h3>Top-notch Service and Excellent Choices</h3>
                            <h5 class='fw-light'>
                            Al Hijaz Rooms is my go-to site for hotel bookings. The variety of options available is impressive, and the prices are unbeatable. I recently booked a weekend getaway through them, and everything went perfectly. The hotel was fantastic, and the whole process from booking to check-out was effortless. Plus, their customer support is outstanding – they really go above and beyond to assist. Highly 
                            </h5>
                            <ul class='list-inline small mb-2'>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item'>
                                <i class='fa-solid fa-star-half-alt text-warning'></i>
                              </li>
                            </ul>
                            <h6 class='mb-0'>James T</h6>
                          
                          </div>
                        </div>
                      </div>
                      <div class='item '>
                        <div class='row justify-content-between align-items-center'>
                       

                          <div class='col-md-12'>
                            <span class='display-3 mb-0 text-primary'>
                              <i class='bi bi-quote'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                              </i>
                            </span>
                            <h3>Great Website for Booking Hotels</h3>
                            <h5 class='fw-light'>
                            I had a positive experience using Al Hijaz Rooms to book a hotel for a business trip. The website is well-designed and easy to navigate, making the booking process quick and straightforward. I found a comfortable and affordable hotel in a prime location. The reviews and ratings on the site were accurate and helpful. I will definitely use Al Hijaz Rooms again for future travel needs.
                            </h5>
                            <ul class='list-inline small mb-2'>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item'>
                                <i class='fa-solid fa-star-half-alt text-warning'></i>
                              </li>
                            </ul>
                            <h6 class='mb-0'>Raza Jamal</h6>
                          
                          </div>
                        </div>
                      </div>
                      <div class='item '>
                        <div class='row justify-content-between align-items-center'>
                       

                          <div class='col-md-12'>
                            <span class='display-3 mb-0 text-primary'>
                              <i class='bi bi-quote'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                              </i>
                            </span>
                            <h3>Exceptional Booking Experience</h3>
                            <h5 class='fw-light'>
                            Booking through Al Hijaz Rooms was a pleasure from start to finish. The website is intuitive, and the range of hotels is extensive. We booked a luxury hotel for our anniversary trip, and everything was perfect. The hotel matched the description and photos on the website, and the customer service team was very supportive when we had questions. We had an amazing stay, thanks to Al Hijaz Rooms!
                            </h5>
                            <ul class='list-inline small mb-2'>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item me-0'>
                                <i class='fa-solid fa-star text-warning'></i>
                              </li>
                              <li class='list-inline-item'>
                                <i class='fa-solid fa-star-half-alt text-warning'></i>
                              </li>
                            </ul>
                            <h6 class='mb-0'>Aisha H</h6>
                          
                          </div>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Explore />
        <section class='bg-light'>
          <div class='container'>
            <div class='row g-4'>
              <div class='col-md-6 col-xxl-4'>
                <div class='bg-body d-flex rounded-3 h-100 p-4'>
                  <h3>
                    <i class='fa-solid fa-hand-holding-heart'></i>
                  </h3>
                  <div class='ms-3'>
                    <h5>24x7 Help</h5>
                    <p class='mb-0'>
                      If we fall short of your expectation in any way, let us
                      know
                    </p>
                  </div>
                </div>
              </div>

              <div class='col-md-6 col-xxl-4'>
                <div class='bg-body d-flex rounded-3 h-100 p-4'>
                  <h3>
                    <i class='fa-solid fa-hand-holding-usd'></i>
                  </h3>
                  <div class='ms-3'>
                    <h5>Payment Trust</h5>
                    <p class='mb-0'>
                      All refunds come with no questions asked guarantee
                    </p>
                  </div>
                </div>
              </div>

              {/* <!-- Download app -->
			<div class="col-lg-6 col-xl-5 col-xxl-3 ms-xxl-auto">
				<h5 class="mb-4">Download app</h5>
				<div class="row g-3">
					<!-- Google play store button -->
					<div class="col-6 col-sm-4 col-md-3 col-lg-6">
						<a href="#"> <img src="assets/images/element/google-play.svg" alt=""> </a>
					</div>
					<!-- App store button -->
					<div class="col-6 col-sm-4 col-md-3 col-lg-6">
						<a href="#"> <img src="assets/images/element/app-store.svg" alt=""> </a>
					</div>
				</div>
			</div> */}
            </div>
          </div>
        </section>
        {/* <div class='home4-destination-card-slider-section mb-120'>
          <div class='container'>
            <div class='row mb-60'>
              <div class='col-lg-12 d-flex align-items-center justify-content-between flex-wrap gap-3'>
                <div class='section-title3'>
                  <h2>Find Top Destinations</h2>
                  <p>
                    Immerse yourself in the history and culture of the world's
                    most fascinating cities. From guided tours through ancient
                    ruins to culinary walks in bustling markets, experience
                    cities like never before.
                  </p>
                </div>
              </div>
            </div>
            <div class='mb-50'>
              <div class='row'>
                <div class='col-lg-4 col-md-4 mt-2 home4-destination-card-slider'>
                  <div class='swiper-wrapper'>
                    <div class='swiper-slide'>
                      <div
                        class='destination-card2 style-2'
                        style={{ cursor: 'pointer' }}
                        onClick={() => Searchhotels('Makkah')}
                      >
                        <p class='destination-card-img'>
                          <img
                            style={{ height: '30em', objectFit: 'cover' }}
                            src={destination1}
                            alt
                          />
                        </p>
                        <div class='destination-card2-content-wrap'>
                          <div class='eg-batch'>
                            <div class='location'>
                              <ul class='location-list'>
                                <li>20 Tour</li>
                                <li>05 Adventure</li>
                              </ul>
                            </div>
                          </div>
                          <div class='destination-card2-content'>
                            <span>Travel To</span>
                            <h4>
                              <a>Makkah</a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=' col-lg-4 col-md-4 mt-2 home4-destination-card-slider'>
                  <div class='swiper-wrapper'>
                    <div class='swiper-slide'>
                      <div
                        class='destination-card2 style-2'
                        style={{ cursor: 'pointer' }}
                        onClick={() => Searchhotels('Madinah')}
                      >
                        <p class='destination-card-img'>
                          <img src={destination2} alt />
                        </p>
                        <div class='destination-card2-content-wrap'>
                          <div class='eg-batch'>
                            <div class='location'>
                              <ul class='location-list'>
                                <li>20 Tour</li>
                                <li>05 Adventure</li>
                              </ul>
                            </div>
                          </div>
                          <div class='destination-card2-content'>
                            <span>Travel To</span>
                            <h4>
                              <a>Madinah</a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=' col-lg-4 col-md-4 mt-2 home4-destination-card-slider'>
                  <div class='swiper-wrapper'>
                    <div class='swiper-slide'>
                      <div
                        class='destination-card2 style-2'
                        style={{ cursor: 'pointer' }}
                        onClick={() => Searchhotels('Jeddah')}
                      >
                        <p class='destination-card-img'>
                          <img src={destination3} alt />
                        </p>
                        <div class='destination-card2-content-wrap'>
                          <div class='eg-batch'>
                            <div class='location'>
                              <ul class='location-list'>
                                <li>20 Tour</li>
                                <li>05 Adventure</li>
                              </ul>
                            </div>
                          </div>
                          <div class='destination-card2-content'>
                            <span>Travel To</span>
                            <h4>
                              <a>Jeddah</a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div> */}
      </Layout>
    </>
  )
}

export default HomeNew
