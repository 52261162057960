import React from 'react'
function Googlemap () {
  return (
    <>
      <div className='container'>
        <div className='col-12'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2431.0074118442067!2d-1.8621419070118492!3d52.44589152602594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bbef3588bc13%3A0x8a441b0b4a83c6d0!2sAlhijaz%20Tours!5e1!3m2!1sen!2suk!4v1683195987117!5m2!1sen!2suk'
              width='100%'
              height='450'
              frameborder='0'
              allowfullscreen
            ></iframe>
{/* <div style={{width: '100%'}}><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=AKM,%20Shan%20Plaza,%20Shaan%20Plaza,%20AKM%20Fazl-ul-Haq%20Rd,%20Block%20D%20G%206/2%20Blue%20Area,%20Islamabad,%20Islamabad%20Capital%20Territory%2044000+(My%20Business%20Name)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps systems</a></iframe></div>        */}
 </div>
      </div>
    </>
  )
}

export default Googlemap
