import React,{useEffect,useState} from "react";
import team1 from '../../Images/New/team1 (1).jpg'
import team2 from '../../Images/New/team1 (2).jpg'
import dammam from '../../Images/Locations/Dammam.jpg'
import jeddah from '../../Images/Locations/Jeddah.jpg'
import madinah from '../../Images/Locations/Madinah-1.jpg'
import makkah from '../../Images/Locations/makkah.jpg'
import riyadh from '../../Images/Locations/riyadh.jpeg'
import taif from '../../Images/Locations/taif.jpeg'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
    ApiEndPoint,
    Hotelapitoken,
  } from '../../Components/GlobalData/GlobalData'
import moment from "moment";
import Loading from "../Loading/Loader";
import { fetchHotelsSearh, fetchHotels,fetchHotelDetail } from '../../Redux/Actions/actions'
function Explore(){
    const [userCountryNew,setUserCountryNew]=useState('');
    const [isloading, setLoading] = useState(false);
    var token = Hotelapitoken();
    var apiendpoint = ApiEndPoint();
    const Dispatch = useDispatch();
    const navigation = useNavigate();
    useEffect(() => {
        getUserLocation()
      }, [])
      function getUserLocation () {
        // Fetch user's location data
        fetch(
          'https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041'
        )
          .then(response => response.json())
          .then(data => {
            setUserCountryNew(data.country.name)
            // Handle location data as needed
          })
          .catch(error => {
            console.error('Error fetching user location:', error)
          })
      }
      const Searchhotels = async name => {
        setLoading(true)
        const currentDate = new Date()
        const twoDaysLater = new Date(currentDate)
        twoDaysLater.setDate(currentDate.getDate() + 2)
        const threeDaysLater = new Date(currentDate)
        threeDaysLater.setDate(currentDate.getDate() + 3)
        var destination_name = ''
        var country = ''
        var lat = ''
        var lon = ''
        var pin = ''
        if (name === 'taif') {
          destination_name = 'Taif'
          country = 'Saudi Arabia'
          lat = 21.2840782
          lon = 40.42481919999999
          pin = 'SA'
        } else if (name === 'makkah') {
          destination_name = 'Makkah'
          country = 'Saudi Arabia'
          lat = 21.4240968
          lon = 39.81733639999999
          pin = 'SA'
        } else if (name === 'madinah') {
          destination_name = 'Madinah'
          country = 'Saudi Arabia'
          lat = 24.4672132
          lon = 39.6024496
          pin = 'SA'
        } else if (name === 'riyadh') {
            destination_name = 'Riyadh'
            country = 'Saudi Arabia'
            lat = 24.7135517
            lon = 46.6752957
            pin = 'SA'
          } else if (name === 'jeddah') {
            destination_name = 'Jeddah'
            country = 'Saudi Arabia'
            lat = 21.5291545
            lon = 39.1610863
            pin = 'SA'
          }else if (name === 'dammam') {
            destination_name = 'Dammam'
            country = 'Southampton'
            lat = 26.4206828
            lon = 50.0887943
            pin = 'SA'
          }
        // Define your API URL, authToken, and dataToSend as shown in the previous example
        const FormData = {
          token: token,
          currency_slc: 'AFN',
          currency_slc_iso: 'AF',
          destination_name: destination_name,
          country: country,
          lat: lat,
          long: lon,
          pin: pin,
          cityd: destination_name,
          country_code: pin,
          check_in: moment(twoDaysLater).format('YYYY-MM-DD'),
          check_out: moment(threeDaysLater).format('YYYY-MM-DD'),
          slc_nationality: userCountryNew,
          adult: 2,
          child: 0,
          room: 1,
          Adults: [2],
          children: [0],
          child_ages1: [],
          rooms_counter: [1],
          child_ages2: []
        }
        try {
          Dispatch(fetchHotelsSearh(FormData))
          const response = await Axios.post(
            apiendpoint + '/api/search/hotels/new_Live',
            FormData,
            {
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }
          )
          sessionStorage.removeItem('FlightCheckOut')
          navigation('/hotels')
          Dispatch(fetchHotels(response.data))
          // Handle the API response here
          setLoading(false)
        } catch (error) {
          // Handle errors here
          setLoading(false)
          console.error('Error:', error)
        }
      }
    return(
        <>
        {isloading && (<Loading/>)}
        <section>
	<div class="container">
		<div class="row mb-4">
			<div class="col-12 text-center">
				<h2 class="mb-0">Explore Nearby</h2>
			</div>
		</div>

		<div class="row g-4 g-md-5">
			<div class="col-6 col-sm-4 col-lg-3 col-xl-2">
				<div class="card bg-transparent text-center p-1 h-100">
					<img src={taif} class="rounded-circle1" alt=""/>

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><p onClick={()=>Searchhotels('taif')} class="stretched-link2">Taif</p></h5>	
					</div>
				</div>
			</div>

			<div class="col-6 col-sm-4 col-lg-3 col-xl-2">
				<div class="card bg-transparent text-center p-1 h-100">
					<img src={makkah} class="rounded-circle1" alt=""/>

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><p onClick={()=>Searchhotels('makkah')} class="stretched-link2">Makkah</p></h5>
						
					</div>
				</div>
			</div>

			<div class="col-6 col-sm-4 col-lg-3 col-xl-2">
				<div class="card bg-transparent text-center p-1 h-100">
					<img src={madinah} class="rounded-circle1" alt=""/>

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><p onClick={()=>Searchhotels('madinah')} class="stretched-link2">Madinah</p></h5>
					</div>
				</div>
			</div>

      <div class="col-6 col-sm-4 col-lg-3 col-xl-2">
				<div class="card bg-transparent text-center p-1 h-100">
					<img src={jeddah} class="rounded-circle1" alt=""/>

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><p onClick={()=>Searchhotels('jeddah')} class="stretched-link2">Jeddah</p></h5>
					</div>
				</div>
			</div>

      <div class="col-6 col-sm-4 col-lg-3 col-xl-2">
				<div class="card bg-transparent text-center p-1 h-100">
					<img src={riyadh} class="rounded-circle1" alt=""/>

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><p onClick={()=>Searchhotels('riyadh')} class="stretched-link2">Riyadh</p></h5>
					</div>
				</div>
			</div>

      <div class="col-6 col-sm-4 col-lg-3 col-xl-2">
				<div class="card bg-transparent text-center p-1 h-100">
					<img src={dammam} class="rounded-circle1" alt=""/>

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><p onClick={()=>Searchhotels('dammam')} class="stretched-link2">Dammam</p></h5>
					</div>
				</div>
			</div>
		</div> 
	</div>
</section>
        </>
    )
}

export default Explore;