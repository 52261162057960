import React, { useEffect, useState } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { Modal,ModalBody,ModalHeader,ModalFooter } from 'reactstrap'
import Axios from 'axios'
import moment from 'moment'
import header from'../../Images/header.png'
import footer from'../../Images/footer.png'

import Loading from '../../Components/Loading/Loader'
import { ToastContainer, toast } from 'react-toastify';
import { ApiEndPoint,Hotelapitoken,StripeClientSecret } from '../../Components/GlobalData/GlobalData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Stripe } from 'stripe'

function Confirmation () {
  const { id } = useParams()
  var endpoint=ApiEndPoint();
  const todayDate = new Date();
  const navigation=useNavigate();
  var Secretkey = StripeClientSecret()
  const stripe = new Stripe(Secretkey)
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [hotelDetail, setHotelDetail] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [hotel, setHotel] = useState([]);
  const [hoteldetail2, setHoteldetail2] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [HotelMeta, setHotelMeta] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [leadPassenger, setLeadPassenger] = useState([]);
  const [paymentObject, setPaymentObjec] = useState([]);
  const [roomPriceSums, setRoomPriceSums] = useState([]);
  const finalRoomPrices = [];
  const [refundAmount, setRefundAmount] = useState(0);
  useEffect(() => {
    getinvoicedetail();
  }, []);
  useEffect(() => {
    total();
  }, [rooms]);
  let Checkin = moment(hoteldetail2.checkIn)
  let checkout = moment(hoteldetail2.checkOut)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const getinvoicedetail = async () => {
    setIsLoading(true);
    var invoiceno = {
      invoice_no: id
    }
    // _Live
    try {
      const response = await Axios.post(endpoint+
        '/api/hotels/view/reservation_Live',
        invoiceno,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      if (response.length !== '') {
        var data = response.data.booking_data;
        setInvoiceNo(data.invoice_no);
        var lead=JSON.parse(data.lead_passenger_data);
        setLeadPassenger(lead);
        var hoteldata = JSON.parse(data.reservation_response);
        setHotelDetail(
          JSON.parse(response.data.booking_data.reservation_response)
        );
        if(data.payment_details !==null){
          if(JSON.parse(data.payment_details)!==''){
            setPaymentObjec(JSON.parse(data.payment_details));
          }
        }
        setHoteldetail2(hoteldata.hotel_details);
        calculateRefund(hoteldata.hotel_details)
        setRooms(hoteldata.hotel_details.rooms);
        setHotel(hoteldata);
        setInvoiceDetail(response.data.booking_data)
        await fetchHotelDetails(hoteldata.hotel_details.hotel_code,data.provider)
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error('Error:', error)
    }
  };
  const calculateRefund = (bookingData) => {
    const today = new Date();
    let totalAmount = 0;
    bookingData.rooms.forEach(room => {
      room.room_rates.forEach(rate => {
        rate.cancellation_policy.forEach(policy => {
          const fromDate = new Date(policy.from_date);
          if (today < fromDate) {
            totalAmount += parseFloat(policy.amount);
          }
        });
      });
    });

    setRefundAmount(totalAmount);
  };
  const handleRefundPayment = async () => {
    try {
      const res = await stripe.refunds.create({
        payment_intent:paymentObject.id,
        amount: Number(refundAmount*100).toFixed(0),
      });
      console.log(res)
      if (res.status!=='succeeded') {
        console.error(res);
        toast.error('Refund failed. Please contact support.')
        // setRefundResponse({ message: error.message });
      } else {
        toast.success('Payment refunded. It may take a few days for the money to reach the customer bank account.')
      }
    } catch (error) {
      console.log(error)
      console.error(error);
      toast.error(error.message)
      // setRefundResponse({ message: 'Error processing refund' });
    } finally {
      // setIsRefunding(false);
    }
  }
  const total=()=>{
   let roomsprice=0;
   let markupprice=0;
   let adminmarkupprice=0;
   let clientmarkupprice=0;
   let finalpricemarkup=0;
   var length=rooms.length;
   for(var x=0;x<length;x++){
    roomsprice=Number(rooms[x].room_rates[0].net);
   finalpricemarkup=roomsprice;
   if(Number(hotel.admin_markup) !== 0)
   {
    if(hotel.admin_markup_type === "Percentage")
    {
      markupprice=( roomsprice * Number(hotel.admin_markup))/100;
    }else{
      markupprice= Number(hotel.admin_markup);
     }
      // adminmarkupprice=markupprice;
      finalpricemarkup +=markupprice
      
   }
   if(Number(hotel.customer_markup) !== 0)
   {
    if(hotel.customer_markup_type === "Percentage")
    {
      markupprice= (finalpricemarkup * Number(hotel.customer_markup))/100;
    }else{
      markupprice= Number(hotel.customer_markup);
     }
    //  clientmarkupprice=markupprice;
      finalpricemarkup +=markupprice;
      
   }
   finalRoomPrices.push(finalpricemarkup);
   setRoomPriceSums(finalRoomPrices);
   }
   
  };
const calculatemarkup=(price)=>{
  let roomsprice=Number(price);
  let markupprice=0;
  let finalpricemarkup=Number(price);

  if(Number(hotel.admin_markup) !== 0)
   {
    if(hotel.admin_markup_type === "Percentage")
    {
      markupprice=( roomsprice * Number(hotel.admin_markup))/100;
    }else{
      markupprice= Number(hotel.admin_markup);
     }
      // adminmarkupprice=markupprice;
      finalpricemarkup +=markupprice
      
   }
   if(Number(hotel.customer_markup) !== 0)
   {
    if(hotel.customer_markup_type === "Percentage")
    {
      markupprice= (finalpricemarkup * Number(hotel.customer_markup))/100;
    }else{
      markupprice= Number(hotel.customer_markup);
     }
    //  clientmarkupprice=markupprice;
      finalpricemarkup +=markupprice;
      
   }
   return finalpricemarkup.toFixed(2);
};
  const toggleModal=()=>{
    setModalOpen(!modalOpen);
  };
  const BookingCancillation =async()=>{
    var apitoken=Hotelapitoken();
    setModalOpen(!modalOpen);
    var data={
      'token':apitoken,
      'invoice_no':invoiceNo
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(endpoint+
        '/api/hotel/reservation/cancell/new_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      var data=JSON.parse(response.data.view_reservation_details);
      if (data.error) {
           setIsLoading(false);
            toast.info(data.error.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            
          }else{
            if(paymentObject.length !==0 && refundAmount >0){
              await handleRefundPayment();
            }
            toast.info('Your reservation has been cancelled', {
              position: toast.POSITION.TOP_RIGHT
            });
            getinvoicedetail();
          }
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error('Error:', error)
    }

  };
  const ViewInvoice=()=>{
    navigation(`/hotel_booking_invoice/${invoiceNo}`);
  }
   const fetchHotelDetails = async (hotelId, provider) => {
    var apitoken=Hotelapitoken();
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }

    try {
      const response = await Axios.post(endpoint + '/api/hotels/mata_Live', data, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      setHotelMeta(response.data)
      // Handle the API response here
    } catch (error) {
      console.error('Error:', error)
    }
  };
  return (
    <>
    {isLoading && (
      <Loading/>
    )}
    <ToastContainer/>
      <section class="pt-4">
	<div class="container">
		<div class="row">
			<div class="col-md-10 col-xl-8 mx-auto">
				<div class="card shadow">
        <div class="container">
            <img style={{width:'100%'}} src={header} alt="letterhead" />
              <img src={HotelMeta?.details_data?.image} width='100%'/>
        </div>
					<div class="card-body text-center p-4">
						<h1 class="card-title fs-3">🎊 Congratulations! 🎊</h1>
						<div class="col-12 text-start">
        {invoiceDetail.status==='Room On Request' &&(
          	<div class="alert alert-success alert-dismissible fade show my-3" role="alert">
              <div class="align-items-center">
							<h5 class="alert-heading mb-0">Your request for room has been received.</h5>
              <h5 class="alert-heading mb-0">One of our agent will contact you shortly with the availability of your required room.</h5>
						</div>
              </div>
        )}
        {invoiceDetail.status==='Cancelled' &&(
          	<div class="alert alert-danger alert-dismissible fade show my-3" role="alert">
              <div class="d-sm-flex align-items-center">
							<h5 class="alert-heading mb-0">Your booking has been cancelled! Thank you for choosing our service.</h5>
						</div>
              </div>
        )}
         {invoiceDetail.status==='Confirmed' &&(
          	<div class="alert alert-success alert-dismissible fade show my-3" role="alert">
              <div class="d-sm-flex align-items-center">
							<h5 class="alert-heading mb-0">Your booking has been confirmed! Thank you for choosing our service.</h5>
						</div>
              </div>
        )}		
				</div>
						<h5 class="text-primary mb-4"> {hoteldetail2.hotel_name}</h5>

						<div class="row justify-content-between text-start mb-4">
							<div class="col-lg-5"> 
								<ul class="list-group list-group-borderless">
                <li class="list-group-item d-sm-flex justify-content-between align-items-center">
										<span class="mb-0"><i class="bi bi-vr fa-fw me-2"></i>Booking Status:</span>
                    {invoiceDetail.status==='Cancelled'?(
                      	<span class="text-danger fw-normal mb-0">{invoiceDetail.status}</span>
                    ):(
                      <span class="text-success fw-normal mb-0">{invoiceDetail.status==='In_Progess'?'In Progress':invoiceDetail.status}</span>

                    )}
									</li>
                  <li class="list-group-item d-sm-flex justify-content-between align-items-center">
										<span class="mb-0"><i class="bi bi-vr fa-fw me-2"></i>Booking ID:</span>
										<span class=" fw-normal mb-0">{hotelDetail.reference_no}</span>
									</li>
                  <li class="list-group-item d-sm-flex justify-content-between align-items-center">
										<span class="mb-0"><i class="bi bi-wallet2 fa-fw me-2"></i>Invoice No:</span>
										<span class=" fw-normal mb-0"> {invoiceNo}</span>
									</li>
									<li class="list-group-item d-sm-flex justify-content-between align-items-center">
										<span class="mb-0"><i class="bi bi-person fa-fw me-2"></i>Booked by:</span>
										<span class=" fw-normal mb-0">{leadPassenger.lead_title+" "+leadPassenger.lead_first_name+" "+leadPassenger.lead_last_name}</span>
									</li>
									
									<li class="list-group-item d-sm-flex justify-content-between align-items-center">
										<span class="mb-0"><i class="bi bi-currency-dollar fa-fw me-2"></i>Due Amount:</span>
										<span class=" fw-normal mb-0">{invoiceDetail.selected_currency} {invoiceDetail.status =='Cancelled' ? '0.0' :  invoiceDetail.payment_details !=null ? JSON.parse(invoiceDetail.payment_details)===''? invoiceDetail.exchange_price:0:invoiceDetail.exchange_price}</span>
									</li>
								</ul>
							</div>

							<div class="col-lg-5">
								<ul class="list-group list-group-borderless">
									<li class="list-group-item d-sm-flex justify-content-between align-items-center">
										<span class="mb-0"><i class="bi bi-calendar fa-fw me-2"></i>Date:</span>
										<span class=" fw-normal mb-0">{moment(invoiceDetail.creationDate).format('ll')}</span>
									</li>
									<li class="list-group-item d-sm-flex justify-content-between align-items-center">
										<span class="mb-0"><i class="bi bi-calendar fa-fw me-2"></i>Checkin Date:</span>
										<span class=" fw-normal mb-0">{moment(hoteldetail2.checkIn).format('ll')}</span>
									</li>
                  <li class="list-group-item d-sm-flex justify-content-between align-items-center">
										<span class="mb-0"><i class="bi bi-calendar fa-fw me-2"></i>Checkout Date:</span>
										<span class=" fw-normal mb-0">{moment(hoteldetail2.checkOut).format('ll')}</span>
									</li>
									<li class="list-group-item d-sm-flex justify-content-between align-items-center">
										<span class="mb-0"><i class="bi bi-people fa-fw me-2"></i>Duration:</span>
										<span class=" fw-normal mb-0">{daysBetween} Nights</span>
									</li>
                  <li class="list-group-item d-sm-flex justify-content-between align-items-center">
										<span class="mb-0"><i class="bi bi-currency-dollar fa-fw me-2"></i>Total Price:</span>
										<span class=" fw-normal mb-0">{invoiceDetail.selected_currency} {invoiceDetail.status =='Cancelled' ? '0.0' : invoiceDetail.exchange_price}</span>
									</li>
								</ul>
							</div>
						</div>
            <div class="text-start mb-2">
              {rooms.map((item,index)=>(
               <div>
                <h6>Room {index+1} : {item.room_name} <FontAwesomeIcon icon={faArrowRight}/>   {item.room_rates[0]?.cancellation_policy &&
                                    item.room_rates[0]?.cancellation_policy.length > 0 ? (
                                      new Date(
                                        item.room_rates[0]?.cancellation_policy[0].from_date
                                      ) > todayDate ? (
                                          <span class='text-success  mb-2 mt-2'>Free Cancellation till{' '}{moment(item?.room_rates[0]?.cancellation_policy[0].from_date).subtract(1, 'days').utc().format('ll')}</span>
                                      
                                      ) : (
                                        <span class='text-danger text-center mb-2 mt-2'>
                                          Non-Refundable
                                        </span>
                                      )
                                    ) : (
                                      <span class='text-danger text-center mb-2 mt-2'>
                                        Non-Refundable
                                      </span>
                                    )}</h6>
               </div>
              ))}
              </div>
						<div class="d-flex justify-content-end ">
              <div className='me-2'>
             <button  onClick={toggleModal} disabled={invoiceDetail.status==='Cancelled'} class="btn cursor-pointer btn-danger mb-0"><i class="bi bi-file-pdf me-2"></i>Cancel Booking</button>
             </div>
             <div>
              <p  onClick={ViewInvoice} class="btn cursor-pointer btn-primary mb-0"><i class="bi bi-file-pdf me-2"></i>View Invoice</p>
              </div>
            </div>
					</div>
          <div class="container">
            <img style={{width:'100%'}} src={footer} alt="letterhead" />
        </div>
				</div>
        
			</div>
		</div>
	</div>
</section>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Confirmation 
        </ModalHeader>
        <ModalBody>
        <div className='form-group'>
            <h6>Are you sure you want to cancell this booking ?</h6>
          </div>
        </ModalBody>
        <ModalFooter>
        <button onClick={toggleModal} className='btn btn-secondary' >
            Close
          </button>
          <button
          onClick={BookingCancillation}
            className=' btn btn-danger '
          >
            Confirm
          </button>
        </ModalFooter>
        </Modal>
      
    </>
  )
}

export default Confirmation
