import React, { useEffect, useState } from 'react'

import Axios from 'axios'
import moment from 'moment'
import emailjs from 'emailjs-com'
import payment from '../../Images/Packages/Logo/payment-img.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faArrowRight,
  faAngleRight,
  faCheck,
  faClockFour,
  faPlane,
  faWindowRestore,
  faLock,
  faClock,
  faMoon,
  faCreditCard,
  faPerson,
  faChild,
  faMale,
  faBed,
  faBroom,
  faRestroom
} from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { Stripe } from 'stripe'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { RadioTile, RadioTileGroup } from 'rsuite'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AirLineNames } from '../../Components/Data/AirLineNames'
import {
  ApiEndPoint,
  FlightSearchToken,
  StripeClientSecret,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import { ToastContainer, toast } from 'react-toastify'
import Layout from '../../Components/Layout/Layout'
import topimg from '../../Images/New/17.svg'
import WithoutLayout from '../../Components/Layout/WithoutLayout'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
import BookingLoader from './BookingLoader'
import { countryListLocal } from '../../Components/Data/CountryList'
var Totalsum = 0
var Currency = ''
var ConverterTotalSum = 0
var StripePayment = 0
var StripeCurrency = ''
var flighttotal = 0
var hotaltotal = 0
var Gbpexhangeprice = 0
var admingbpprice = 0
var exchangerateprice = 0
var ServiceCharges = 0
function BookRoom ({ onLogout }) {
  const UserDetail = useSelector(state => state.hotels.UserLoginRes)
  const todayDate = new Date()
  var MarkupSum = 0
  var ConverterCurrency = ''
  var Secretkey = StripeClientSecret()
  const stripe = new Stripe(Secretkey)
  const elements = useElements()
  const stripeInstance = useStripe()
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  var endpoint = ApiEndPoint()
  let AdultAmount = 0
  let ChildAmount = 0
  let InfantAmount = 0
  var Airlinenamesdata = AirLineNames
  const Search_response = useSelector(state => state.hotels.OneWayFlight)
  const homesearch = useSelector(state => state.hotels.hsearch)
  const IndexPageSearchData = useSelector(
    state => state.hotels.OneWayFlightSearchData
  )
  const adultCount = homesearch.adult
  const childCount = homesearch.child
  var infantCount
  if (IndexPageSearchData !== undefined) {
    infantCount = IndexPageSearchData.infant
  }
  const [gbpPrices, setGbpPrices] = useState({
    adult: '',
    child: '',
    infant: '',
    adultqty: '',
    childqty: '',
    infantqty: ''
  })
  const checkoutdetail = useSelector(state => state.hotels.checkoutdetail)
  const [isChecked, setIsChecked] = useState(false)
  const [selectedValue, setSelectedValue] = useState(
    checkoutdetail.hotel_provider !== 'Custome_hotel' &&
      checkoutdetail.hotel_provider !== 'custom_hotel_provider' &&
      new Date(
        checkoutdetail?.rooms_list[0].cancliation_policy_arr[0].from_date
      ) > todayDate
      ? 'nopayment'
      : 'cardpayment'
  )
  const [otherGuestData, setOtherGuestData] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [countryListsimple, setCountryListsimple] = useState([])
  // const [adminPrices, setAdminPrices] = useState({gbpprice:0,adminPrice:0});
  const [otherDiv, setOtherDiv] = useState({ adult: '', child: '', infant: '' })
  const [baseCName, setBaseCName] = useState('GBP')
  const [ConversionRates, setConversionRates] = useState({
    gbpRate: '',
    exchangeRate: ''
  })
  const [loaderLoading, setLoaderLoading] = useState(false)
  const [message, setMessage] = useState(true)
  const [showPrice, setShowPrice] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedCountryother, setSelectedCountryother] = useState(null)
  const [exchangemarkup, setExchangemarkup] = useState({ admin: 0, client: 0 })
  const [totalperson, setTotalperson] = useState({
    adult: '',
    child: '',
    infant: '',
    adultprice: '',
    childprice: '',
    infantprice: ''
  })
  const [savemarkup, setSavemarkup] = useState({
    totalroomprice: 0,
    admin: 0,
    client: 0,
    final: 0
  })
  const [selectedNationality, setSelectedNationality] = useState('')
  const [gestinfo, setGestinfo] = useState({
    title: '',
    firstname: '',
    lastname: '',
    dateofbirth: '',
    numbercode: '',
    email: '',
    phonenumber: '',
    gender: '',
    pno: '',
    pexpiry: '',
    country: '',
    nationality: '',
    postalcode: ''
  })
  const [cardInfo, setCardInfo] = useState({
    name: '',
    cardnumber: '',
    mm: '',
    yy: '',
    cvv: ''
  })
  const [isBooking, setIsBooking] = useState(false)
  const [guests, setGuests] = useState(Array(adultCount).fill({}))
  const [childs, setChilds] = useState(Array(childCount).fill({}))
  const [infants, setinfants] = useState(Array(infantCount).fill({}))

  const hotelimg = useSelector(
    state => state.hotels.hoteldetail.hotel_gallery[0]
  )
  console.log(checkoutdetail)
  let Checkin = moment(checkoutdetail?.checkIn)
  let checkout = moment(checkoutdetail?.checkOut)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const childsArray = Array.from({ length: childCount })
  const adultsArray = Array.from({ length: adultCount - 1 })
  const infantsArray = Array.from({ length: infantCount })
  const FlightCurrency = useSelector(state => state.hotels.FlightCurrency)
  const CheckoutFlightData = useSelector(
    state => state.hotels.OneWayFlightcheckout
  )
  const storedData = JSON.parse(sessionStorage.getItem('FlightCheckOut'))
  var FlightMarkup = JSON.parse(localStorage.getItem('FlightMarkup'))
  useEffect(() => {
    const countryOptions = countryListLocal.item.map(country => ({
      value: country.name.common,
      label: country.name.common,
      flag: country.flags.png,
      phoneCode: country.idd.root + country.idd.suffixes[0]
    }))
    setCountryList(countryOptions)
    fetchData2()
    if (storedData !== null) {
      Totalsum = calculateSum()
    }
    totalcount()
  }, [])

  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  async function fetchData2 () {
    Axios.get(endpoint + '/api/countries/fetch')
      .then(response => {
        setCountryListsimple(response.data.countries)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }
  const totalcount = () => {
    let allroomsprice = 0
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = 0
    allroomsprice = checkoutdetail.rooms_list.reduce(
      (sum, item) =>
        sum +
        Number(
          item.room_Promotions_Exist === '1'
            ? item.rooms_total_price_Promotion
            : item.rooms_total_price
        ),
      0
    )
    savemarkup.totalroomprice = allroomsprice
    finalpricemarkup = allroomsprice
    if (Number(checkoutdetail.admin_markup) !== 0) {
      if (checkoutdetail.admin_markup_type === 'Percentage') {
        markupprice =
          (allroomsprice * Number(checkoutdetail.admin_markup)) / 100
      } else {
        markupprice = Number(checkoutdetail.admin_markup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      savemarkup.admin = adminmarkupprice
      savemarkup.final = finalpricemarkup
    }
    if (Number(checkoutdetail.customer_markup) !== 0) {
      if (checkoutdetail.customer_markup_type === 'Percentage') {
        markupprice =
          (finalpricemarkup * Number(checkoutdetail.customer_markup)) / 100
      } else {
        markupprice = Number(checkoutdetail.customer_markup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
      savemarkup.client = clientmarkupprice
      savemarkup.final = finalpricemarkup
    } else {
      savemarkup.final = allroomsprice
    }
  }
  const handleCountryChange = selectedOption => {
    setSelectedCountry(selectedOption)
    setSelectedNationality(selectedOption.value)
    setGestinfo(prevdata => ({
      ...prevdata,
      phonenumber: selectedOption.phoneCode,
      numbercode: selectedOption.phoneCode,
      nationality: selectedOption.value
    }))
  }
  const handleCountryChangeother = selectedOption => {
    setSelectedCountryother(selectedOption)
    setGestinfo(prevdata => ({
      ...prevdata,
      country: selectedOption.value
    }))
  }
  const handlePayment = async () => {
    if (cardInfo.name === '') {
      setError('Enter Card Holder Name.')
      return { success: false }
    }
    const response = await stripe.paymentIntents.create({
      amount: (Number(StripePayment) * 100).toFixed(0), // Amount in cents
      currency: StripeCurrency,
      description: 'Example payment',
      payment_method_types: ['card']
    })
    var Client_Secret = response.client_secret

    try {
      // Confirm the payment intent with the payment method
      const { paymentIntent, error } = await stripeInstance.confirmCardPayment(
        Client_Secret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: cardInfo.name
            }
          }
        }
      )

      if (error) {
        setError(error.message)
        console.error(error)
        setLoaderLoading(false)
        setIsBooking(false)
        return { success: false, data: '' }
      } else {
        return { success: true, data: paymentIntent }
      }
    } catch (error) {
      console.error('Error:', error)
      setIsBooking(false)
      setLoaderLoading(false)

      return { success: false, data: '' }
    }
  }
  const calculateSum = () => {
    const pricedItinerary = storedData.PricedItineraries[0]
    if (pricedItinerary && pricedItinerary.AirItineraryPricingInfo) {
      const fareBreakdowns =
        pricedItinerary.AirItineraryPricingInfo.PTC_FareBreakdowns
      Currency = fareBreakdowns[0].PassengerFare.TotalFare.CurrencyCode
      const sum = fareBreakdowns.reduce((accumulator, item) => {
        var Code = item.PassengerTypeQuantity.Code
        var x = item.PassengerTypeQuantity.Quantity
        switch (Code) {
          case 'ADT':
            setTotalperson(prevdata => ({
              ...prevdata,
              adult: x,
              adultprice: item.PassengerFare.TotalFare.Amount
            }))
            setOtherDiv(prevdata => ({
              ...prevdata,
              adult: x - 1
            }))
            break
          case 'CHD':
            setTotalperson(prevdata => ({
              ...prevdata,
              child: x,
              childprice: item.PassengerFare.TotalFare.Amount
            }))
            setOtherDiv(prevdata => ({
              ...prevdata,
              child: x
            }))
            break
          case 'INF':
            setTotalperson(prevdata => ({
              ...prevdata,
              infant: x,
              infantprice: item.PassengerFare.TotalFare.Amount
            }))
            setOtherDiv(prevdata => ({
              ...prevdata,
              infant: x
            }))
            break
          default:
            break
        }

        return (
          accumulator +
          Number(item.PassengerFare.TotalFare.Amount) *
            item.PassengerTypeQuantity.Quantity
        )
      }, 0)
      return sum.toFixed(2)
    } else {
      return 0 // Handle the case where the data is missing or empty
    }
  }

  const calculateMarkup = price => {
    if (Object.keys(checkoutdetail).length !== 0) {
      let markupprice = 0
      let adminmarkupprice = 0
      let clientmarkupprice = 0
      let finalpricemarkup = Number(price)
      if (Number(checkoutdetail.admin_markup) !== 0) {
        if (checkoutdetail.admin_markup_type === 'Percentage') {
          markupprice = (price * Number(checkoutdetail.admin_markup)) / 100
        } else {
          markupprice = Number(checkoutdetail.admin_markup)
        }
        adminmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(checkoutdetail.customer_markup) !== 0) {
        if (checkoutdetail.customer_markup_type === 'Percentage') {
          markupprice =
            (finalpricemarkup * Number(checkoutdetail.customer_markup)) / 100
        } else {
          markupprice = Number(checkoutdetail.customer_markup)
        }
        clientmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(finalpricemarkup) % 1 !== 0) {
        return Number(finalpricemarkup).toFixed(2)
      }
      return finalpricemarkup
    }
  }

  const handlegestchange = e => {
    const { value, name } = e.target
    setGestinfo(prevgestinfo => ({ ...prevgestinfo, [name]: value }))
  }

  const confirmbooking = async () => {
    if (gestinfo.title === '') {
      toast.error('Please Select Lead Guest Title.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (gestinfo.firstname === '') {
      toast.error('Please Enter Lead Guest First Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (gestinfo.lastname === '') {
      toast.error('Please Enter Lead Guest Last Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (gestinfo.email === '') {
      toast.error('Please Enter Lead Guest Email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (selectedNationality === '') {
      toast.error('Please Select Lead Guest Nationality.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (gestinfo.phonenumber.length < 5) {
      toast.error('Please Enter Phone Number.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    if (isChecked) {
      setIsBooking(true)
      if (storedData !== null) {
        BothServicesBooking()
        return
      }
      setIsBooking(true)
      setLoaderLoading(true)
      var paymentmethod = 'slc_stripe'
      if (
        checkoutdetail.hotel_provider === 'Custome_hotel' ||
        new Date(
          checkoutdetail?.rooms_list[0].cancliation_policy_arr[0].from_date
        ) > todayDate
      ) {
        if (checkoutdetail?.rooms_list[0].request_type !== '1') {
          if (selectedValue === 'cardpayment') {
            var status = await handlePayment()
            if (status.success === false) {
              setLoaderLoading(false)
              setIsBooking(false)
              return
            }
            setMessage(false)
          } else if (selectedValue === 'bankpayment') {
            var status = { success: false, data: '' }
            paymentmethod = 'Bank_Payment'
          } else {
            var status = { success: false, data: '' }
            paymentmethod = 'nopayment'
          }
        } else {
          var status = { success: false, data: '' }
        }
      } else {
        var status = await handlePayment()
        if (status.success === false) {
          setLoaderLoading(false)
          setIsBooking(false)
          return
        }
        setMessage(false)
      }
      var title = []
      var firstname = []
      var lastname = []
      var nationality = []
      if (guests.length !== 0) {
        guests.forEach(person => {
          if (person.other_title && person.other_first_name) {
            title.push(person.other_title)
            firstname.push(person.other_first_name)
            lastname.push(person.other_last_name)
            nationality.push(person.other_passport_country)
          }
        })
      }
      var Childtitle = []
      var Childfirstname = []
      var Childlastname = []
      var Childnationality = []
      if (childs.length !== 0) {
        childs.forEach(children => {
          if (children.title && children.firstName) {
            Childtitle.push(children.title)
            Childfirstname.push(children.firstName)
            Childlastname.push(children.lastName)
            Childnationality.push(children.nationality)
          }
        })
      }
      const jsonString = {
        lead_title: gestinfo.title,
        lead_first_name: gestinfo.firstname,
        lead_last_name: gestinfo.lastname,
        lead_email: gestinfo.email,
        lead_date_of_birth: gestinfo.dateofbirth,
        lead_country: selectedNationality,
        lead_phone: gestinfo.phonenumber,
        other_title: title,
        other_first_name: firstname,
        other_last_name: lastname,
        other_nationality: nationality,
        child_title: Childtitle,
        child_first_name: Childfirstname,
        child_last_name: Childlastname,
        child_nationality: Childnationality,
        slc_pyment_method: 'slc_stripe',
        name: 'on',
        base_exchange_rate: '1',
        base_currency: 'GBP',
        selected_exchange_rate: '1',
        exchange_price: StripePayment,
        admin_markup: checkoutdetail.admin_markup,
        client_markup: checkoutdetail.customer_markup,
        exchange_currency: StripeCurrency
      }
      const apitoken = Hotelapitoken()
      const customersearch = {
        token: apitoken,
        b2b_agent_id: UserDetail.id,
        city_name: checkoutdetail.destinationName,
        destination: checkoutdetail.destinationName,
        room_searching: homesearch.room,
        child_searching: homesearch.child,
        adult_searching: homesearch.adult,
        adult_per_room: homesearch.adult,
        child_per_room: homesearch.children,
        country_nationality: homesearch.slc_nationality,
        check_in: homesearch.check_in,
        check_out: homesearch.check_out,
        request_all_data: JSON.stringify(homesearch)
      }
      const phpArray = {
        token: apitoken,
        service_charges: ServiceCharges,
        b2b_agent_id: UserDetail.id,
        lead_title: gestinfo.title,
        admin_exchange_currency: 'GBP',
        admin_exchange_rate: Gbpexhangeprice.toFixed(4),
        admin_exchange_total_markup_price: admingbpprice,
        lead_first_name: gestinfo.firstname,
        lead_last_name: gestinfo.lastname,
        lead_email: gestinfo.email,
        lead_date_of_birth: gestinfo.dateofbirth,
        lead_country: selectedNationality,
        lead_phone: gestinfo.phonenumber,
        other_title: title,
        other_first_name: firstname,
        other_last_name: lastname,
        other_nationality: nationality,
        slc_pyment_method: paymentmethod,
        payment_details: JSON.stringify(status.data),
        base_exchange_rate: Gbpexhangeprice,
        base_currency: 'GBP',
        selected_exchange_rate: exchangerateprice,
        selected_currency: StripeCurrency,
        exchange_price: StripePayment,
        admin_markup: checkoutdetail.admin_markup,
        client_markup: checkoutdetail.customer_markup,
        exchange_currency: StripeCurrency,
        request_data: JSON.stringify(jsonString),
        creditAmount: StripePayment,
        hotel_checkout_select: JSON.stringify(checkoutdetail),
        customer_search_data: JSON.stringify(customersearch)
      }
      // _Live
      try {
        const response = await Axios.post(
          endpoint + '/api/hotels/reservation_Live',
          phpArray,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              // Required for CORS support to work
              'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
              'Access-Control-Allow-Headers':
                'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
              // "Access-Control-Allow-Methods": "POST, OPTIONS"
            }
          }
        )

        // Handle the API response here

        if (response.data.message === 'Internal server error') {
          setLoaderLoading(false)
          setIsBooking(false)
          toast.error('Please Search Hotel Again.', {
            position: toast.POSITION.TOP_RIGHT
          })
        } else if (response.data.message === 'Insufficient allotment') {
          setIsBooking(false)
          setLoaderLoading(false)
          toast.error('Insufficient allotment.', {
            position: toast.POSITION.TOP_RIGHT
          })
        } else if (response.data.status === 'error') {
          setIsBooking(false)
          setLoaderLoading(false)
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        } else if (response.data.status === 'success') {
          sendEmail(response.data.Invoice_data)
          sendOwnerMessage(response.data.Invoice_data)
          setIsBooking(false)
          setLoaderLoading(false)
          navigate(`/hotel_voucher/${response.data.Invoice_id}`)
        }
        // navigate(`/hotel_detail/${id}`,{state:{index}})
      } catch (error) {
        // Handle errors here
        setIsBooking(false)
        setLoaderLoading(false)
        console.error('Error:', error)
      }
    } else {
      toast.error('Please Agree with Terms and Conditions.', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }
  const handlecarddetail = e => {
    const { value, name } = e.target
    setCardInfo(prevcardinfo => ({ ...prevcardinfo, [name]: value }))
  }
  const handleCheckboxContinue = () => {
    setIsChecked(!isChecked)
  }
  const otherGuestInfo = (e, guestIndex, digit) => {
    const selectedValue = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          title: selectedValue
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_title: selectedValue
        }
        return updatedGuests
      })
    } else if (digit === 3) {
      setinfants(prevInfants => {
        const updatedGuests = [...prevInfants]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          title: selectedValue
        }
        return updatedGuests
      })
    }
  }
  const otherGuestFirstName = (e, guestIndex, digit) => {
    // Handle the first name input and update the state
    const firstName = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          firstName: firstName
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_first_name: firstName
        }
        return updatedGuests
      })
    } else if (digit === 3) {
      setinfants(prevInfant => {
        const updatedGuests = [...prevInfant]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          firstName: firstName
        }
        return updatedGuests
      })
    }
  }
  const otherGuestLastName = (e, guestIndex, digit) => {
    // Handle the last name input and update the state
    const lastName = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          lastName: lastName
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_last_name: lastName
        }
        return updatedGuests
      })
    } else if (digit === 3) {
      setinfants(prevInfant => {
        const updatedGuests = [...prevInfant]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          lastName: lastName
        }
        return updatedGuests
      })
    }
  }
  const otherGuestdob = (e, guestIndex, digit) => {
    // Handle the last name input and update the state
    const other_dob = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          dob: other_dob
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_date_of_birth: other_dob
        }
        return updatedGuests
      })
    } else if (digit === 3) {
      setinfants(prevInfant => {
        const updatedGuests = [...prevInfant]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          dob: other_dob
        }
        return updatedGuests
      })
    }
  }
  const otherGuestGender = (e, guestIndex, digit) => {
    // Handle the last name input and update the state
    const other_g = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          gender: other_g
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_gender: other_g
        }
        return updatedGuests
      })
    } else if (digit === 3) {
      setinfants(prevInfant => {
        const updatedGuests = [...prevInfant]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          gender: other_g
        }
        return updatedGuests
      })
    }
  }
  const otherGuestPNumber = (e, guestIndex, digit) => {
    // Handle the last name input and update the state
    const value = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          pnumber: value
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_passport_no: value
        }
        return updatedGuests
      })
    } else if (digit === 3) {
      setinfants(prevInfant => {
        const updatedGuests = [...prevInfant]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          pnumber: value
        }
        return updatedGuests
      })
    }
  }

  const otherGuestPExpiry = (e, guestIndex, digit) => {
    // Handle the last name input and update the state
    const value = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          pexpiry: value
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_passport_expiry_date: value
        }
        return updatedGuests
      })
    } else if (digit === 3) {
      setinfants(prevInfants => {
        const updatedGuests = [...prevInfants]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          pexpiry: value
        }
        return updatedGuests
      })
    }
  }
  const otherGuestNationality = (e, guestIndex, digit) => {
    // Handle the last name input and update the state
    const newvalue = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          nationality: newvalue
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_passport_country: newvalue
        }
        return updatedGuests
      })
    } else if (digit === 3) {
      setinfants(prevInfants => {
        const updatedGuests = [...prevInfants]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          nationality: newvalue
        }
        return updatedGuests
      })
    }
  }
  const renderPrice = (price, currency) => {
    if (price === 0) {
      return
    }
    var currencyData = JSON.parse(localStorage.getItem('AllHotelCurr'))
    var selectedcurr = localStorage.getItem('DefaultCurrency')
    if (selectedcurr === currency || currencyData === null) {
      setShowPrice(false)
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2)
      }
      return Number(price)
    } else {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === currency
      )
      if (CurrencyRates === undefined) {
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName] // Use square brackets to access the property
        var baseprice = Number(gbpprice) * Number(price)
        StripeCurrency = baseCName
        Gbpexhangeprice = gbpprice
        admingbpprice = baseprice
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName]
        var baseprice1 = Number(gbpprice) * Number(price)
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = Number(gbpprice2) * Number(baseprice1)
        Gbpexhangeprice = gbpprice
        admingbpprice = baseprice1
        exchangerateprice = gbpprice2
      }
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2)
      }
      return baseprice
    }
  }

  const renderPrice2 = (price, qty, check) => {
    if (FlightCurrency !== undefined) {
      if (CurrencyRates === undefined) {
        const gbpprice = FlightCurrency[baseCName] // Use square brackets to access the property
        var baseprice = Number(gbpprice) * Number(price)

        if (check === 2) {
          gbpPrices.adult = baseprice
          gbpPrices.adultqty = qty
        } else if (check === 3) {
          gbpPrices.child = baseprice
          gbpPrices.childqty = qty
        } else if (check === 4) {
          gbpPrices.infant = baseprice
          gbpPrices.infantqty = qty
        }

        StripeCurrency = baseCName
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = FlightCurrency[baseCName]
        var baseprice1 = Number(gbpprice) * Number(price)
        if (check === 2) {
          gbpPrices.adult = baseprice1
          gbpPrices.adultqty = qty
        } else if (check === 3) {
          gbpPrices.child = baseprice1
          gbpPrices.childqty = qty
        } else if (check === 4) {
          gbpPrices.infant = baseprice1
          gbpPrices.infantqty = qty
        }
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = Number(gbpprice2) * Number(baseprice1)
      }
    } else {
      setShowPrice(false)
    }
    return baseprice
  }

  const ShowOtherGuestForm = () => {
    setOtherGuestData(!otherGuestData)
  }
  const BothServicesBooking = async () => {
    //   debugger
    //   var check=0;
    //   if(adultCount-1 !==0){
    //     for (let i = 0; i < adultsArray.length; i++) {
    //      if (!validateAdultData(adultsArray[i], i)) {
    //        // Validation failed for at least one adult, handle accordingly
    //        check=1;
    //        return;
    //      }
    //    }
    //  }
    //    if(check==1){
    //      return;
    //     }
    //    check=0;
    //    if(childCount !==''){
    //    for (let i = 0; i < childsArray.length; i++) {
    //      if (!validateChildData(childsArray[i], i)) {
    //        // Validation failed for at least one adult, handle accordingly
    //        check=1;
    //        return;
    //      }
    //    }
    //  }
    //    if(check==1){
    //      return;
    //     }
    var token = FlightSearchToken()
    var limit = {
      token: token
    }
    var limitcheck
    try {
      const response = await Axios.post(
        endpoint + '/api/flight_credit_limit_Live',
        limit,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      limitcheck = response.data
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      setIsBooking(false)
      console.error('Error:', error)
    }

    if (Number(flighttotal) > Number(limitcheck.credit_data.remaining_amount)) {
      toast.info(
        'There is a problem in the payment, Please contact Support or try again later on.',
        {
          position: toast.POSITION.TOP_RIGHT
        }
      )
      setIsBooking(false)
      return
    } else {
      var status = await handlePayment()
      if (status.success === false) {
        setIsBooking(false)
        return
      }
      var adminmarkup = ''
      var clientmarkup = ''

      for (var i = 0; i < FlightMarkup.markups.length; i++) {
        if (FlightMarkup.markups[i].services_type === 'flight') {
          if (FlightMarkup.markups[i].added_markup == 'synchtravel') {
            adminmarkup = FlightMarkup.markups[i]
          } else if (FlightMarkup.markups[i].added_markup == 'alhijaz') {
            clientmarkup = FlightMarkup.markups[i]
          }
        }
      }
      var leadpassengerdetail = {
        title: gestinfo.title,
        first_name: gestinfo.firstname,
        last_name: gestinfo.lastname,
        email: gestinfo.email,
        date_of_birth: gestinfo.dateofbirth,
        gender: gestinfo.gender,
        passport_no: gestinfo.pno,
        passport_expiry_date: gestinfo.pexpiry,
        passport_country: gestinfo.country,
        passenger_nationality_id: gestinfo.nationality,
        passenger_nationality_code: gestinfo.numbercode,
        passenger_phone_no: gestinfo.phonenumber,
        postal_code: gestinfo.postalcode
      }

      const retrievedNumber = sessionStorage.getItem('15digitnumber')
      var token = FlightSearchToken()
      var farerequest = {
        token_authorization: token,
        ConversationId: retrievedNumber,
        FareSourceCode:
          CheckoutFlightData.PricedItineraries[0].AirItineraryPricingInfo
            .FareSourceCode
      }
      const apitokenew = Hotelapitoken()
      const customersearch = {
        token: apitokenew,
        city_name: checkoutdetail.destinationName,
        destination: checkoutdetail.destinationName,
        room_searching: homesearch.room,
        child_searching: homesearch.child,
        adult_searching: homesearch.adult,
        adult_per_room: homesearch.adults,
        child_per_room: homesearch.children,
        country_nationality: homesearch.slc_nationality,
        check_in: homesearch.check_in,
        check_out: homesearch.check_out,
        request_all_data: JSON.stringify(homesearch)
      }
      var adulttotal = Number(gbpPrices.adult) * Number(gbpPrices.adultqty)
      var childtotal = Number(gbpPrices.child) * Number(gbpPrices.childqty)
      var infanttotal = Number(gbpPrices.infant) * Number(gbpPrices.infantqty)
      var total = adulttotal + childtotal + infanttotal
      var adminmarkupprice = 0
      var clientmarkupprice = 0

      FlightMarkup.markups.forEach(markup => {
        if (markup.services_type === 'flight') {
          if (markup.added_markup === 'alhijaz') {
            if (markup.markup_type === 'Percentage') {
              const markupValue = Number(markup.markup_value)
              const markupAmount = (Number(total) * markupValue) / 100
              clientmarkupprice = markupAmount
            } else {
              clientmarkupprice = Number(markup.markup_value)
            }
          } else if (markup.added_markup === 'synchtravel') {
            if (markup.markup_type === 'Percentage') {
              const markupValue = parseFloat(Number(markup.markup_value))
              const markupAmount = (Number(total) * markupValue) / 100
              adminmarkupprice = markupAmount
            } else {
              adminmarkupprice = Number(markup.markup_value)
            }
          }
        }
      })
      var data = {
        token_authorization: token,
        ConversationId: retrievedNumber,
        lead_passenger_details: JSON.stringify(leadpassengerdetail),
        other_passenger_details: JSON.stringify(guests),
        child_details: JSON.stringify(childs),
        infant_details: JSON.stringify(infants),
        extra_services_details: '',
        other_extra_services_details: '',
        child_extra_services_details: '',
        revalidation_res: JSON.stringify({ Data: CheckoutFlightData }),
        childs: IndexPageSearchData.child,
        adults: IndexPageSearchData.adult,
        infant: IndexPageSearchData.infant,
        departure_date: IndexPageSearchData.DepartureDate,
        search_rq: JSON.stringify(IndexPageSearchData),
        search_rs: JSON.stringify({ Data: Search_response }),
        farerules_rq: '',
        farerules_rs: '',
        revalidation_rq: JSON.stringify(farerequest),
        revalidation_rs: JSON.stringify({ Data: CheckoutFlightData }),
        payment_details: JSON.stringify(status.data),
        adult_price: Number(totalperson.adult) * AdultAmount,
        child_price: Number(totalperson.child) * ChildAmount,
        infant_price: Number(totalperson.infant) * InfantAmount,
        total_price: ConverterTotalSum,
        adult_price_markup: CalculateFLightMarkup(
          Number(totalperson.adult) * AdultAmount
        ),
        child_price_markup: CalculateFLightMarkup(
          Number(totalperson.child) * ChildAmount
        ),
        infant_price_markup: CalculateFLightMarkup(
          Number(totalperson.infant) * InfantAmount
        ),
        total_price_markup: MarkupSum,
        client_commission_amount: exchangemarkup.client,
        admin_commission_amount: exchangemarkup.admin,
        currency: ConverterCurrency,
        client_payable_price: Number(MarkupSum) - Number(exchangemarkup.client),
        client_markup:
          clientmarkup.markup_value === undefined
            ? ''
            : clientmarkup.markup_value,
        client_markup_type:
          clientmarkup.markup_type === undefined
            ? ''
            : clientmarkup.markup_type,
        client_commision_amount_exchange: clientmarkupprice,
        client_without_markup_price: total,
        client_markup_price: clientmarkupprice,
        client_payable_price_exchange:
          Number(total) + Number(clientmarkupprice),
        client_currency: 'GBP',
        admin_markup:
          adminmarkup.markup_value === undefined
            ? ''
            : adminmarkup.markup_value,
        admin_markup_type:
          adminmarkup.markup_type === undefined ? '' : adminmarkup.markup_type,
        admin_commision_amount_exchange: adminmarkupprice,
        admin_without_markup_price: total,
        admin_markup_price: adminmarkupprice,
        admin_payable_price_exchange: Number(total) + Number(adminmarkupprice),
        admin_currency: 'GBP',
        creditAmount: MarkupSum,
        hotel_checkout_select: JSON.stringify(checkoutdetail),
        hotel_customer_search_data: JSON.stringify(customersearch)
      }

      try {
        const response = await Axios.post(
          'https://api.synchtravel.com/api/combine_booking_apis_new',
          data,
          {
            headers: {
              // "Access-Control-Allow-Origin": "*",
              // Required for CORS support to work
              //"Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
              // "Access-Control-Allow-Headers":
              // "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
              // "Access-Control-Allow-Methods": "POST"
            }
          }
        )
        setIsBooking(false)
        if (response.data.message === 'success') {
          // navigation(`/Flight_invoice/${retrievedNumber}`, {
          //       state: {retrievedNumber}
          //     });
        } else {
          var data = JSON.parse(response.data.error)
          toast.info(data.Message, {
            position: toast.POSITION.TOP_RIGHT
          })
          return
        }
        // navigate(`/hotel_detail/${id}`,{state:{index}})
      } catch (error) {
        // Handle errors here
        setIsBooking(false)

        console.log(error)
        console.error('Error:', error)
      }
    }
  }
  const sendEmail = async data => {
    try {
      const templateParams = {
        invoiceno: data.invoice_no,
        booking_date: data.creationDate,
        checkin: homesearch.check_in,
        checkout: homesearch.check_out,
        rooms: data.total_rooms,
        adults: data.total_adults,
        price: data.exchange_currency + ' ' + data.exchange_price,
        status: data.status,
        name:
          gestinfo.title + ' ' + gestinfo.firstname + ' ' + gestinfo.lastname,
        email: gestinfo.email,
        phno: gestinfo.phonenumber
      }

      await emailjs.send(
        'service_d6zrc2l',
        'template_mwxpr8h',
        templateParams,
        'c163bgNie5rW0iU1f'
      )

      console.log('Email sent successfully')
    } catch (error) {
      console.error('Error sending email', error)
    }
  }
  const sendOwnerMessage = async data => {
    var message
    if (checkoutdetail?.rooms_list[0].request_type !== '1') {
      message = `Dear ${gestinfo.title} ${gestinfo.firstname} ${gestinfo.lastname},\nYour booking has been confirmed! Thank you for choosing our service.Below are the details of your booking: \n
      Invoice No: ${data.invoice_no}
      Booking Date: ${data.creationDate}
      Check-in Date: ${homesearch.check_in}
      Check-out Date: ${homesearch.check_out}
      Number of Rooms: ${data.total_rooms}
      Number of Adults: ${data.total_adults}
  
      Room Details:
      Price: ${data.exchange_currency} ${data.exchange_price}
      Room Status: ${data.status}
  
      Customer Details:
      Name: ${gestinfo.title} ${gestinfo.firstname} ${gestinfo.lastname}\n
      Email: ${gestinfo.email}
      Phone Number: ${gestinfo.phonenumber}
      We look forward to providing you with a comfortable and enjoyable stay. Should you have any questions or require further assistance, please do not hesitate to contact us.\nThank you for choosing Alhijaz Rooms.`
    } else {
      message = `Dear ${gestinfo.title} ${gestinfo.firstname} ${gestinfo.lastname},\nYour request for room has been received.\nOne of our agent will contact you shortly with the availability of your required room.\nBelow are the details of your booking: \n
      Invoice No: ${data.invoice_no}
      Booking Date: ${data.creationDate}
      Check-in Date: ${homesearch.check_in}
      Check-out Date: ${homesearch.check_out}
      Number of Rooms: ${data.total_rooms}
      Number of Adults: ${data.total_adults} 
      
      Room Details:
      Price: ${data.exchange_currency} ${data.exchange_price}
      Room Status: ${data.status}
      
      Customer Details:
      Name: ${gestinfo.title} ${gestinfo.firstname} ${gestinfo.lastname}\n
      Email: ${gestinfo.email}
      Phone Number: ${gestinfo.phonenumber}
      We look forward to providing you with a comfortable and enjoyable stay. Should you have any questions or require further assistance, please do not hesitate to contact us.\nThank you for choosing Alhijaz Rooms.`
    }
    const apiKey = 'RUU6Wkzm2JRt'
    const encodedMessage = encodeURIComponent(message)
    const url = `http://api.textmebot.com/send.php?recipient=${gestinfo.phonenumber}&apikey=${apiKey}&text=${encodedMessage}`

    try {
      const response = await fetch(url)

      if (response.ok) {
        const contentType = response.headers.get('content-type')

        if (contentType && contentType.includes('application/json')) {
          const data = await response.json()
          console.log('Message sent:', data)
        } else {
          const text = await response.text()
          console.log('Message sent:', text)
        }
      } else {
        console.error('Error sending message:', response.statusText)
      }
    } catch (error) {
      console.error('Error sending message:', error)
    }
  }

  const CalculateFLightMarkup = (price, check) => {
    var admin = 0
    var client = 0
    if (price !== 'NaN') {
      FlightMarkup.markups.forEach(markup => {
        if (markup.services_type === 'flight') {
          if (markup.added_markup === 'alhijaz') {
            if (markup.markup_type === 'Percentage') {
              const markupValue = Number(markup.markup_value)
              const markupAmount = (Number(price) * markupValue) / 100
              client = markupAmount
            } else {
              client = Number(markup.markup_value)
            }
          } else if (markup.added_markup === 'synchtravel') {
            if (markup.markup_type === 'Percentage') {
              const markupValue = parseFloat(Number(markup.markup_value))
              const markupAmount = (Number(price) * markupValue) / 100
              admin = markupAmount
            } else {
              admin = Number(markup.markup_value)
            }
          }
        }
      })
      if (check === 5) {
        exchangemarkup.admin = admin
        exchangemarkup.client = client
      }
      var total = Number(price) + admin + client
      return total.toFixed(2)
    }
  }

  return (
    <>
      <Layout logout={onLogout}>
        <ToastContainer />
        {loaderLoading && <BookingLoader message={message} />}
        <section class='py-0 mt-2'>
          <div class='container'>
            <div class='card bg-light overflow-hidden px-sm-5'>
              <div class='row align-items-center g-4'>
                <div class='col-sm-9'>
                  <div class='card-body'>
                    <nav aria-label='breadcrumb'>
                      <ol class='breadcrumb breadcrumb-dots mb-0'>
                        <li class='breadcrumb-item'>
                          <p>
                            <i class='bi bi-house me-1'></i> Home
                          </p>
                        </li>
                        <li class='breadcrumb-item'>Hotel detail</li>
                        <li class='breadcrumb-item active'>Booking</li>
                      </ol>
                    </nav>
                    <h1 class='m-0 h2 card-title'>Review your Booking</h1>
                  </div>
                </div>

                <div class='col-sm-3 text-end d-none d-sm-block'>
                  <img src={topimg} class='mb-n4' alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class='container'>
            <div class='row g-4 g-lg-5'>
              <div class='col-xl-8'>
                <div class='vstack gap-5'>
                  <div class='card shadow'>
                    <div class='card-header p-4 border-bottom'>
                      <h3 class='mb-0'>
                        <i class='fa-solid fa-hotel me-2'></i>Hotel Information
                      </h3>
                    </div>
                    <div class='card-body p-4'>
                      <div class='card mb-4'>
                        <div class='row align-items-center'>
                          <div class='col-sm-6 col-md-3'>
                            <img
                              src={hotelimg}
                              style={{ height: '8em', objectFit: 'cover' }}
                              class='card-img'
                              alt=''
                            />
                          </div>

                          <div class='col-sm-6 col-md-9'>
                            <div class='card-body pt-3 pt-sm-0 p-0'>
                              <h5 class='card-title'>
                                <p className=''>
                                  {' '}
                                  {checkoutdetail?.hotel_name}
                                </p>
                              </h5>
                              <p class='small mb-2'>
                                <i class='bi bi-geo-alt me-2'></i>
                                {checkoutdetail?.destinationName}
                              </p>

                              <ul class='list-inline mb-0'>
                                {checkoutdetail?.stars_rating === '' ? (
                                  <li class='list-inline-item me-0 small'>
                                    No Rating
                                  </li>
                                ) : (
                                  Array(checkoutdetail?.stars_rating)
                                    .fill(0)
                                    .map((_, index) => (
                                      <li
                                        key={index}
                                        class='list-inline-item me-0 small'
                                      >
                                        <i class='fa-solid fa-star text-warning'></i>
                                      </li>
                                    ))
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class='row g-4'>
                        <div class='col-lg-4'>
                          <div class='bg-light py-3 px-4 rounded-3'>
                            <h6 class='fw-light small mb-1'>Check-in</h6>
                            <h5 class='mb-1'>
                              {moment(checkoutdetail?.checkIn).format('ll')}
                            </h5>
                            <small>
                              <i class='bi bi-alarm me-1'>
                                <FontAwesomeIcon icon={faClock} />
                              </i>
                              12:30 pm
                            </small>
                          </div>
                        </div>

                        <div class='col-lg-4'>
                          <div class='bg-light py-3 px-4 rounded-3'>
                            <h6 class='fw-light small mb-1'>Check out</h6>
                            <h5 class='mb-1'>
                              {moment(checkoutdetail?.checkOut).format('ll')}
                            </h5>
                            <small>
                              <i class='bi bi-alarm me-1'>
                                <FontAwesomeIcon icon={faClock} />
                              </i>
                              12:30 pm
                            </small>
                          </div>
                        </div>

                        <div class='col-lg-4'>
                          <div class='bg-light py-3 px-4 rounded-3'>
                            <h6 class='fw-light small mb-1'>Rooms & Guests</h6>
                            <h5 class='mb-1'>
                              {homesearch.adult}{' '}
                              <FontAwesomeIcon icon={faMale} /> -{' '}
                              {homesearch.child}{' '}
                              <FontAwesomeIcon icon={faChild} /> -{' '}
                              {homesearch.room} <FontAwesomeIcon icon={faBed} />
                            </h5>
                            <small>
                              <i class='bi bi-brightness-high me-1'>
                                <FontAwesomeIcon icon={faMoon} />
                              </i>
                              {daysBetween} Nights{' '}
                            </small>
                          </div>
                        </div>
                      </div>
                      {checkoutdetail?.rooms_list.map((item, index) => (
                        <div key={index} class='card border mt-4'>
                          <div class='card-header border-bottom d-md-flex justify-content-md-between'>
                            <h5 class='card-title mb-0'>{item.room_name}</h5>
                            <p class='btn btn-link p-0 mb-0'>
                              Meal Type : {item.board_id}
                            </p>
                          </div>
                          <div class='p-2'>
                            {item.request_type === '1' && (
                              <div className=' mt-2 room-request'>
                                <h6>Room on Request</h6>
                              </div>
                            )}
                            <ul class='list-items-2  ps-0 ms-0 h6'>
                              <li>
                                <span>Room:</span>
                                {item.selected_qty} Room
                              </li>
                              {showPrice ? (
                                <li>
                                  <span>Room Price:</span>
                                  {CurrencyRates === undefined
                                    ? baseCName
                                    : CurrencyRates.selectedcurrency}{' '}
                                  {
                                    (hotaltotal = Number(
                                      renderPrice(
                                        calculateMarkup(
                                          item.room_Promotions_Exist === '1'
                                            ? item.rooms_total_price_Promotion
                                            : item.rooms_total_price
                                        ),
                                        checkoutdetail.currency
                                      )
                                    ))
                                  }
                                </li>
                              ) : (
                                <li>
                                  <span>Room Price:</span>
                                  {checkoutdetail.currency}{' '}
                                  {
                                    (hotaltotal = Number(
                                      calculateMarkup(
                                        item.room_Promotions_Exist === '1'
                                          ? item.rooms_total_price_Promotion
                                          : item.rooms_total_price
                                      )
                                    ))
                                  }
                                </li>
                              )}

                              <li>
                                <span>Adults:</span>
                                {item.adults}
                              </li>
                              <li>
                                <span> Children :</span>
                                {item.childs}
                              </li>

                              <li>
                                <span>Stay:</span>
                                {daysBetween} Nights{' '}
                              </li>
                            </ul>
                            {item.cancliation_policy_arr &&
                            item.cancliation_policy_arr.length > 0 ? (
                              item.cancliation_policy_arr.map(
                                (policy, index) => {
                                  const policyDate = new Date(policy.from_date)
                                  const isFutureDate = policyDate > todayDate
                                  const isPriceMatch =
                                    parseFloat(item.rooms_total_price) ===
                                    parseFloat(policy.amount)

                                  if (isFutureDate && isPriceMatch) {
                                    return (
                                      <p
                                        key={index}
                                        className='text-success text-center mb-2 mt-2'
                                      >
                                        Free Cancellation till{' '}
                                        {moment(policyDate)
                                          .subtract(1, 'days')
                                          .utc()
                                          .format('ll')}
                                      </p>
                                    )
                                  } else if (isFutureDate) {
                                    return (
                                      <p
                                        key={index}
                                        className='text-warning text-center mb-2 mt-2'
                                      >
                                        {policy.amount} will be charged from{' '}
                                        {moment(policyDate).utc().format('ll')}
                                      </p>
                                    )
                                  } else if (
                                    index ===
                                    item.cancliation_policy_arr.length - 1
                                  ) {
                                    return (
                                      <p
                                        key={index}
                                        className='text-danger text-center mb-2 mt-2'
                                      >
                                        Non-Refundable
                                      </p>
                                    )
                                  }
                                  return null
                                }
                              )
                            ) : (
                              <p className='text-danger text-center mb-2 mt-2'>
                                Non-Refundable
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div class='card shadow'>
                    <div class='card-header border-bottom p-4'>
                      <h4 class='card-title mb-0'>
                        <i class='bi bi-people-fill me-2'></i>Guest Details
                      </h4>
                    </div>

                    <div class='card-body p-4'>
                      <div class='row g-4'>
                        <div class='col-12'>
                          <div class='bg-light rounded-2 px-4 py-3'>
                            <h6 class='mb-0'>Main Guest</h6>
                          </div>
                        </div>
                        <div class=' mt-2 col-md-6'>
                          <label className='fw-bold'>Title</label>
                          <select
                            id='inputState'
                            name='title'
                            value={gestinfo.title}
                            onChange={handlegestchange}
                            class='form-select js-choice'
                          >
                            <option selected>Select Title</option>
                            <option value='MR'>Mr</option>
                            <option value='MRS'>Mrs</option>
                          </select>
                        </div>

                        <div class='form-group mt-2 col-md-6'>
                          <label className='fw-bold'>First Name</label>
                          <input
                            type='text'
                            class='form-control '
                            value={gestinfo.firstname}
                            name='firstname'
                            onChange={handlegestchange}
                            placeholder='First Name'
                          />
                        </div>

                        <div class='form-group mt-2 col-md-6'>
                          <label className='fw-bold'>Last Name</label>
                          <input
                            type='text'
                            class='form-control'
                            value={gestinfo.lastname}
                            name='lastname'
                            onChange={handlegestchange}
                            placeholder='Last Name'
                          />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                          <label className='fw-bold'>Email</label>
                          <input
                            type='email'
                            class='form-control '
                            value={gestinfo.email}
                            name='email'
                            onChange={handlegestchange}
                            placeholder='Email'
                          />
                        </div>

                        <div class='form-group field-icon-wrap mt-2 col-md-6'>
                          <label className='fw-bold'>Nationality</label>
                          <Select
                            options={countryList}
                            isSearchable={true}
                            className=''
                            onChange={handleCountryChange}
                            value={selectedCountry}
                            getOptionLabel={option => (
                              <div>
                                <img
                                  src={option.flag}
                                  alt={option.label}
                                  style={{ width: '20px', marginRight: '8px' }}
                                />
                                {option.label} ({option.phoneCode})
                              </div>
                            )}
                            getOptionValue={option => option.value}
                          />
                        </div>

                        <div class='form-group mt-2 col-md-6'>
                          <label className='fw-bold'>Phone Number</label>
                          <input
                            type='text'
                            class='form-control '
                            value={gestinfo.phonenumber}
                            name='phonenumber'
                            onChange={handlegestchange}
                            placeholder='Phone Number'
                          />
                        </div>
                        {storedData !== null && (
                          <>
                            <div class='form-group mt-2 col-md-6'>
                              <label className='fw-bold'>Gender</label>
                              <select
                                id='inputState'
                                name='gender'
                                value={gestinfo.gender}
                                onChange={handlegestchange}
                                class='form-control form-select mt-2 select-styling'
                              >
                                <option selected>Select Gender</option>
                                <option value='M'>Male</option>
                                <option value='F'>Female</option>
                              </select>
                            </div>
                            <div class='form-group mt-2 col-md-6'>
                              <label className='fw-bold'>Passport Number</label>
                              <input
                                type='text'
                                class='form-control mt-2'
                                value={gestinfo.pno}
                                name='pno'
                                onChange={handlegestchange}
                                placeholder='Passport Number'
                              />
                            </div>
                            <div class='form-group mt-2 col-md-6'>
                              <label className='fw-bold'>Passport Expiry</label>
                              <input
                                type='date'
                                value={gestinfo.pexpiry}
                                onChange={handlegestchange}
                                class='form-control mt-2'
                                name='pexpiry'
                              />
                            </div>
                            <div class='form-group mt-2 col-md-6'>
                              <label className='fw-bold'>Country</label>
                              <Select
                                options={countryList}
                                isSearchable={true}
                                className='mt-2'
                                onChange={handleCountryChangeother}
                                value={selectedCountryother}
                                getOptionLabel={option => (
                                  <div>
                                    <img
                                      src={option.flag}
                                      alt={option.label}
                                      style={{
                                        width: '20px',
                                        marginRight: '8px'
                                      }}
                                    />
                                    {option.label} ({option.phoneCode})
                                  </div>
                                )}
                                getOptionValue={option => option.value}
                              />
                            </div>
                            <div class='form-group mt-2 col-md-6'>
                              <label className='fw-bold'>Pstal Code</label>
                              <input
                                type='number'
                                class='form-control mt-2'
                                value={gestinfo.postalcode}
                                name='postalcode'
                                onChange={handlegestchange}
                                placeholder='Postal Code'
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {(adultCount - 1 !== 0 ||
                      childCount !== 0 ||
                      infantCount !== 0) && (
                      <div class=''>
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={ShowOtherGuestForm}
                          class='card-header border-bottom p-4 d-flex justify-content-between'
                        >
                          <h4 class='card-title mb-0'>Other Guest Details </h4>
                          <span className='mt-auto mb-auto'>
                            <FontAwesomeIcon icon={faAngleDown} />
                          </span>
                        </div>
                        {otherGuestData && (
                          <div class='card-body p-4'>
                            <div className='row'>
                              <div>
                                {adultsArray.map((_, index) => (
                                  <div key={index} className='row pt-2'>
                                    <h5 className='mb-2'>Guest #{index + 2}</h5>
                                    <div className='col-md-3 mt-2'>
                                      <label className='fw-bold'>Title</label>
                                      <select
                                        value={guests.title}
                                        id={`inputState_${index}`}
                                        name='title'
                                        onChange={e =>
                                          otherGuestInfo(e, index, 1)
                                        }
                                        class='form-control mt-2 form-select select-styling'
                                      >
                                        <option selected>Select Title</option>
                                        <option value='MR'>Mr.</option>
                                        <option value='MRS'>Mrs.</option>
                                      </select>
                                    </div>
                                    <div className='col-md-3 mt-2'>
                                      <label className='fw-bold'>
                                        First Name
                                      </label>
                                      <input
                                        type='text'
                                        class='form-control mt-2'
                                        value={guests.firstname}
                                        name='firstname'
                                        placeholder='First Name'
                                        onChange={e =>
                                          otherGuestFirstName(e, index, 1)
                                        }
                                      />
                                    </div>
                                    <div className='col-md-3 mt-2'>
                                      <label className='fw-bold'>
                                        Last Name
                                      </label>
                                      <input
                                        type='text'
                                        class='form-control mt-2'
                                        value={guests.lastname}
                                        name='lastname'
                                        placeholder='Last Name'
                                        onChange={e =>
                                          otherGuestLastName(e, index, 1)
                                        }
                                      />
                                    </div>
                                    <div className='col-md-3 mt-2'>
                                      <label className='fw-bold'>
                                        Nationality
                                      </label>
                                      <select
                                        value={guests.nationality} // Set the selected value from the state
                                        onChange={e =>
                                          otherGuestNationality(e, index, 1)
                                        }
                                        className='form-control form-select mt-2 select-styling'
                                        aria-label='Default select example'
                                      >
                                        <option selected>Nationality</option>
                                        {countryListsimple.map(
                                          (item, index) => (
                                            <option
                                              key={item.id}
                                              value={item.iso2}
                                            >
                                              {item.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    {storedData !== null && (
                                      <>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Date of Birth
                                          </label>
                                          <input
                                            type='date'
                                            class='form-control mt-2'
                                            value={guests.dob}
                                            name='dob'
                                            onChange={e =>
                                              otherGuestdob(e, index, 1)
                                            }
                                          />
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Gender:
                                          </label>
                                          <select
                                            value={guests.gender}
                                            name='gender'
                                            onChange={e =>
                                              otherGuestGender(e, index, 1)
                                            }
                                            class='form-control  mt-2 form-select select-styling'
                                          >
                                            <option selected>
                                              Select Gender
                                            </option>
                                            <option value='M'>Male</option>
                                            <option value='F'>Female</option>
                                          </select>
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Passport Number
                                          </label>
                                          <input
                                            type='text'
                                            class='form-control mt-2'
                                            value={guests.pnumber}
                                            name='pnumber'
                                            placeholder='Passport Number'
                                            onChange={e =>
                                              otherGuestPNumber(e, index, 1)
                                            }
                                          />
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Passport Expiry
                                          </label>
                                          <input
                                            type='date'
                                            class='form-control mt-2'
                                            value={guests.pexpiry}
                                            name='pexpiry'
                                            onChange={e =>
                                              otherGuestPExpiry(e, index, 1)
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ))}
                                {childsArray.map((_, index) => (
                                  <div key={index} className='row pt-2'>
                                    <h5 className='mb-2'>Child #{index + 1}</h5>
                                    <div className='col-md-3 mt-2'>
                                      <select
                                        value={guests.title}
                                        id={`inputState_${index}`}
                                        name='title'
                                        onChange={e =>
                                          otherGuestInfo(e, index, 2)
                                        }
                                        class='form-control form-select select-styling'
                                      >
                                        <option selected>Select Title</option>
                                        <option value='MSTR'>Mr.</option>
                                        <option value='MSTRS'>Mrs.</option>
                                      </select>
                                    </div>
                                    <div className='col-md-3 mt-2'>
                                      <input
                                        type='text'
                                        class='form-control'
                                        name='firstname'
                                        placeholder='First Name'
                                        onChange={e =>
                                          otherGuestFirstName(e, index, 2)
                                        }
                                      />
                                    </div>
                                    <div className='col-md-3 mt-2'>
                                      <input
                                        type='text'
                                        class='form-control'
                                        name='firstname'
                                        placeholder='Last Name'
                                        onChange={e =>
                                          otherGuestLastName(e, index, 2)
                                        }
                                      />
                                    </div>
                                    <div className='col-md-3 mt-2'>
                                      <select
                                        value={childs.nationality} // Set the selected value from the state
                                        onChange={e =>
                                          otherGuestNationality(e, index, 2)
                                        }
                                        className='form-control form-select select-styling'
                                        aria-label='Default select example'
                                      >
                                        <option selected>Nationality</option>
                                        {countryListsimple.map(
                                          (item, index) => (
                                            <option
                                              key={item.id}
                                              value={item.name}
                                            >
                                              {item.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    {storedData !== null && (
                                      <>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Date of Birth
                                          </label>
                                          <input
                                            type='date'
                                            class='form-control mt-2'
                                            value={guests.dob}
                                            name='dob'
                                            onChange={e =>
                                              otherGuestdob(e, index, 2)
                                            }
                                          />
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Gender:
                                          </label>
                                          <select
                                            value={guests.gender}
                                            name='gender'
                                            onChange={e =>
                                              otherGuestGender(e, index, 2)
                                            }
                                            class='form-control  mt-2 form-select select-styling'
                                          >
                                            <option selected>
                                              Select Gender
                                            </option>
                                            <option value='M'>Male</option>
                                            <option value='F'>Female</option>
                                          </select>
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Passport Number
                                          </label>
                                          <input
                                            type='text'
                                            class='form-control mt-2'
                                            value={guests.pnumber}
                                            name='pnumber'
                                            placeholder='Passport Number'
                                            onChange={e =>
                                              otherGuestPNumber(e, index, 2)
                                            }
                                          />
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Passport Expiry
                                          </label>
                                          <input
                                            type='date'
                                            class='form-control mt-2'
                                            value={guests.pexpiry}
                                            name='pexpiry'
                                            onChange={e =>
                                              otherGuestPExpiry(e, index, 2)
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ))}
                                {infantsArray.map((_, index) => (
                                  <div key={index} className='row pt-2'>
                                    <h5 className='mb-2'>
                                      Infant #{index + 1}
                                    </h5>
                                    <div className='col-md-3 mt-2'>
                                      <select
                                        value={guests.title}
                                        id={`inputState_${index}`}
                                        name='title'
                                        onChange={e =>
                                          otherGuestInfo(e, index, 3)
                                        }
                                        class='form-control form-select select-styling'
                                      >
                                        <option selected>Select Title</option>
                                        <option value='MSTR'>Mr.</option>
                                        <option value='MSTRS'>Mrs.</option>
                                      </select>
                                    </div>
                                    <div className='col-md-3 mt-2'>
                                      <input
                                        type='text'
                                        class='form-control'
                                        name='firstname'
                                        placeholder='First Name'
                                        onChange={e =>
                                          otherGuestFirstName(e, index, 3)
                                        }
                                      />
                                    </div>
                                    <div className='col-md-3 mt-2'>
                                      <input
                                        type='text'
                                        class='form-control'
                                        name='firstname'
                                        placeholder='Last Name'
                                        onChange={e =>
                                          otherGuestLastName(e, index, 3)
                                        }
                                      />
                                    </div>
                                    <div className='col-md-3 mt-2'>
                                      <select
                                        value={infants.nationality} // Set the selected value from the state
                                        onChange={e =>
                                          otherGuestNationality(e, index, 3)
                                        }
                                        className='form-control form-select select-styling'
                                        aria-label='Default select example'
                                      >
                                        <option selected>Nationality</option>
                                        {countryListsimple.map(
                                          (item, index) => (
                                            <option
                                              key={item.id}
                                              value={item.name}
                                            >
                                              {item.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    {storedData !== null && (
                                      <>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Date of Birth
                                          </label>
                                          <input
                                            type='date'
                                            class='form-control mt-2'
                                            value={guests.dob}
                                            name='dob'
                                            onChange={e =>
                                              otherGuestdob(e, index, 3)
                                            }
                                          />
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Gender:
                                          </label>
                                          <select
                                            value={guests.gender}
                                            name='gender'
                                            onChange={e =>
                                              otherGuestGender(e, index, 3)
                                            }
                                            class='form-control  mt-2 form-select select-styling'
                                          >
                                            <option selected>
                                              Select Gender
                                            </option>
                                            <option value='M'>Male</option>
                                            <option value='F'>Female</option>
                                          </select>
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Passport Number
                                          </label>
                                          <input
                                            type='text'
                                            class='form-control mt-2'
                                            value={guests.pnumber}
                                            name='pnumber'
                                            placeholder='Passport Number'
                                            onChange={e =>
                                              otherGuestPNumber(e, index, 3)
                                            }
                                          />
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                          <label className='fw-bold'>
                                            Passport Expiry
                                          </label>
                                          <input
                                            type='date'
                                            class='form-control mt-2'
                                            value={guests.pexpiry}
                                            name='pexpiry'
                                            onChange={e =>
                                              otherGuestPExpiry(e, index, 3)
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div class='card shadow'>
                    <div class='card-header border-bottom p-4'>
                      <h4 class='card-title mb-0'>
                        <i class='bi bi-wallet-fill me-2'></i>Payment Options
                      </h4>
                    </div>

                    <div class='card-body p-4 pb-0'>
                      {checkoutdetail?.rooms_list[0].request_type === '1' ? (
                        <div class='col-12'>
                          <div
                            class='alert alert-success alert-dismissible fade show my-3'
                            role='alert'
                          >
                            <div class='align-items-center '>
                              <h6 class='alert-heading mb-0'>
                                Please note that availability is subject to
                                confirmation. Our team will check the
                                availability and get back to you shortly with
                                the final confirmation.
                              </h6>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <RadioTileGroup
                            defaultValue={
                              checkoutdetail.hotel_provider !==
                                'Custome_hotel' &&
                              checkoutdetail.hotel_provider !==
                                'custom_hotel_provider' &&
                              new Date(
                                checkoutdetail?.rooms_list[0].cancliation_policy_arr[0].from_date
                              ) > todayDate
                                ? 'nopayment'
                                : 'cardpayment'
                            }
                            aria-label='Visibility Level'
                            onChange={setSelectedValue}
                          >
                            {checkoutdetail.hotel_provider !==
                              'Custome_hotel' &&
                              checkoutdetail.hotel_provider !==
                                'custom_hotel_provider' &&
                              new Date(
                                checkoutdetail?.rooms_list[0].cancliation_policy_arr[0].from_date
                              ) > todayDate && (
                                <RadioTile
                                  label='No pre payment required'
                                  value='nopayment'
                                >
                                  Please ensure that payment is made before the
                                  cancellation date to avoid your booking being
                                  canceled.
                                </RadioTile>
                              )}
                            <RadioTile
                              label='Pay with Credit Card'
                              value='cardpayment'
                            >
                              <i class='bi bi-credit-card text-primary me-2'>
                                <FontAwesomeIcon icon={faCreditCard} />
                              </i>
                            </RadioTile>
                            {/* {checkoutdetail.hotel_provider} */}
                            {(checkoutdetail?.hotel_provider ===
                              'Custome_hotel' ||
                              checkoutdetail?.hotel_provider ===
                                'custom_hotel_provider' ||
                              new Date(
                                checkoutdetail?.rooms_list[0].cancliation_policy_arr[0].from_date
                              ) > todayDate) && (
                              <RadioTile
                                label='Bank Transfer Within 24 hours'
                                value='bankpayment'
                              >
                                Please complete your payment via bank transfer
                                within 24 hours to ensure timely processing.
                              </RadioTile>
                            )}
                          </RadioTileGroup>

                          {selectedValue === 'cardpayment' && (
                            <div
                              class='accordion accordion-icon accordion-bg-light'
                              id='accordioncircle'
                            >
                              <div class='accordion-item mb-3'>
                                {/* <h6 class="accordion-header" id="heading-1">
										<button class="accordion-button rounded collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
											<i class="bi bi-credit-card text-primary me-2"><FontAwesomeIcon icon={faCreditCard}/></i>	<span class="me-5">Credit or Debit Card</span>
										</button>
									</h6> */}
                                <div
                                  id='collapse-1'
                                  class='accordion-collapse mt-2 collapse show'
                                  aria-labelledby='heading-1'
                                  data-bs-parent='#accordioncircle'
                                >
                                  <div class='accordion-body'>
                                    <div class='row g-3'>
                                      <div class='col-12'>
                                        <label class='form-label'>
                                          <span class='h6 fw-normal'>
                                            Card Number *
                                          </span>
                                        </label>
                                        <div>
                                          <CardNumberElement
                                            className='form-control'
                                            id='cardNumber'
                                            placeholder='0000 0000 0000 0000'
                                          />
                                        </div>
                                      </div>
                                      <div class='col-md-6'>
                                        <label class='form-label'>
                                          <span class='h6 fw-normal'>
                                            Expiration date *
                                          </span>
                                        </label>
                                        <CardExpiryElement
                                          className='form-control'
                                          id='expiryDate'
                                        />
                                      </div>
                                      <div class='col-md-6'>
                                        <label class='form-label'>
                                          <span class='h6 fw-normal'>
                                            CVV / CVC *
                                          </span>
                                        </label>
                                        <CardCvcElement
                                          className='form-control'
                                          id='cvc'
                                          style={{ width: '40%' }}
                                        />
                                      </div>
                                      <div class='col-12'>
                                        <label class='form-label'>
                                          <span class='h6 fw-normal'>
                                            Name on Card *
                                          </span>
                                        </label>
                                        <input
                                          type='text'
                                          class='form-control'
                                          name='name'
                                          value={cardInfo.name}
                                          onChange={handlecarddetail}
                                          placeholder='Name'
                                        />
                                      </div>
                                      {error && (
                                        <div
                                          style={{ color: 'red' }}
                                          className='error mt-2'
                                          role='alert'
                                        >
                                          {error}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {selectedValue === 'bankpayment' && (
                            <div>
                              <p className='bank-payment-msg'>
                                Please copy the bank details and make the
                                payment within 24 hours. After payment, submit
                                the transaction details through the agent
                                dashboard for verification. Your booking will be
                                confirmed once the payment is verified.
                              </p>

                              <div
                                style={{ flexWrap: 'wrap' }}
                                className='d-flex mt-2 justify-content-between align-items-center'
                              >
                                <h6>Account Holder Name:</h6>
                                <h6> Al Hijaz Tours Ltd</h6>
                              </div>
                              <div
                                style={{ flexWrap: 'wrap' }}
                                className='d-flex mt-2 justify-content-between align-items-center'
                              >
                                <h6>Account Number:</h6>
                                <h6>58516868</h6>
                              </div>
                              <div
                                style={{ flexWrap: 'wrap' }}
                                className='d-flex mt-2 justify-content-between align-items-center'
                              >
                                <h6>Sort Code:</h6>
                                <h6>309950</h6>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      <div class='col-12 mt-3'>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='checkbox'
                            checked={isChecked} // Set the checked state
                            onChange={handleCheckboxContinue}
                            id='invalidCheck'
                            required
                          />
                          <label class='form-check-label' for='invalidCheck'>
                            By continuing, you agree to the{' '}
                            <span style={{ color: 'red' }}>
                              {' '}
                              Terms and conditions
                            </span>
                          </label>
                        </div>
                      </div>
                      <div class='col-12 mb-3'>
                        <div class='d-sm-flex justify-content-sm-between align-items-center'>
                          <h4>
                            {StripeCurrency} {Number(StripePayment).toFixed(2)}{' '}
                          </h4>
                          <button
                            disabled={isBooking}
                            onClick={confirmbooking}
                            class='btn btn-primary mb-0'
                          >
                            {isBooking ? 'Please Wait....' : 'Pay Now'}
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* <div class="card-footer p-4 pt-0">
							<p class="mb-0">By processing, You accept Booking <a href="#">Terms of Services</a> and <a href="#">Policy</a></p>
						</div> */}
                  </div>
                </div>
              </div>
              <aside class='col-xl-4'>
                <div class='row g-4'>
                  <div class='col-md-6 col-xl-12'>
                    <div class='card shadow rounded-2'>
                      <div class='card-header border-bottom'>
                        <h5 class='card-title mb-0'>Price Summary</h5>
                      </div>

                      <div class='card-body'>
                        <ul class='list-group list-group-borderless'>
                          {showPrice ? (
                            <>
                              <li class='list-group-item d-flex justify-content-between align-items-center'>
                                <span class='h6 fw-light mb-0'>Sub Total</span>
                                <span class='fs-5'>
                                  {CurrencyRates === undefined
                                    ? baseCName
                                    : CurrencyRates.selectedcurrency}{' '}
                                  {Number(
                                    renderPrice(
                                      savemarkup.final,
                                      checkoutdetail.currency
                                    )
                                  )}
                                </span>
                              </li>
                              <li class='list-group-item d-flex justify-content-between align-items-center'>
                                <span class='h6 fw-light mb-0'>
                                  Service Charges
                                </span>
                                <span class='fs-5 '>
                                  {CurrencyRates === undefined
                                    ? baseCName
                                    : CurrencyRates.selectedcurrency}{' '}
                                  {
                                    (ServiceCharges = Number(
                                      renderPrice(
                                        (2.5 / 100) * savemarkup.final,
                                        checkoutdetail.currency
                                      )
                                    ))
                                  }
                                </span>
                              </li>
                            </>
                          ) : (
                            <>
                              <li class='list-group-item d-flex justify-content-between align-items-center'>
                                <span class='h6 fw-light mb-0'>Sub Total</span>
                                <span class='fs-5'>
                                  {checkoutdetail.currency} {savemarkup.final}
                                </span>
                              </li>
                              <li class='list-group-item d-flex justify-content-between align-items-center'>
                                <span class='h6 fw-light mb-0'>
                                  Service Charges
                                </span>
                                <span class='fs-5 '>
                                  {checkoutdetail.currency}{' '}
                                  {
                                    (ServiceCharges = (
                                      (2.5 / 100) *
                                      savemarkup.final
                                    ).toFixed(2))
                                  }
                                </span>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>

                      <div class='card-footer border-top'>
                        <div class='d-flex justify-content-between align-items-center'>
                          <span class='h5 mb-0'>Payable Now</span>
                          {showPrice ? (
                            <span class='h5 mb-0'>
                              {CurrencyRates === undefined
                                ? (StripeCurrency = baseCName)
                                : (StripeCurrency =
                                    CurrencyRates.selectedcurrency)}{' '}
                              {(StripePayment =
                                Number(
                                  renderPrice(
                                    savemarkup.final,
                                    checkoutdetail.currency
                                  )
                                ) + Number(ServiceCharges)).toFixed(2)}
                            </span>
                          ) : (
                            <span class='h5 mb-0'>
                              {(StripeCurrency = checkoutdetail.currency)}{' '}
                              {
                                (StripePayment = (
                                  savemarkup.final + Number(ServiceCharges)
                                ).toFixed(2))
                              }
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default BookRoom
