import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import noImage from '../../Images/Hotels/no-img.jpg'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import image from '../../Images/View Detail/1.jpeg'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import { fetchCheckoutDetail } from '../../Redux/Actions/actions'
import Images from '../../Components/Images/images'
import Layout from '../../Components/Layout/Layout'
import RoomFacilities from '../../Components/Hotel/RoomFacility'
import Axios from 'axios'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
function HotelDetail ({ onLogout }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const todayDate = new Date()
  var endpoint = ApiEndPoint()
  const apitoken = Hotelapitoken()
  const { index } = location.state || {}
  const [selectedDate, setSelectedDate] = useState(null)
  const [activeButton, setActiveButton] = useState(null)
  const [selectedRooms, setSelectedRooms] = useState([])
  const [showMoreFacilities, setShowMoreFacilities] = useState(10)
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(false)
  const [showPrice, setShowPrice] = useState(true)
  const [baseCName, setBaseCName] = useState('GBP')
  const [counter, setCounter] = useState(0)
  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([])

  const navigate = useNavigate()
  const hoteldetail = useSelector(state => state.hotels.hoteldetail)
  console.log(hoteldetail)
  const seleectedhotelfromlist2 = useSelector(
    state => state.hotels.hotels.hotels_list
  )
  const seleectedhotelfromlist = seleectedhotelfromlist2.filter(
    item => item.hotel_id === hoteldetail?.hotel_code
  )
  let { id } = useParams()
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const ReduxSearchData = useSelector(state => state.hotels.hsearch)

  let Checkin = moment(ReduxSearchData.check_in)
  let checkout = moment(ReduxSearchData.check_out)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const latitude = hoteldetail.latitude
  const longitude = hoteldetail.longitude
  var mapUrl =
    'https://maps.google.com/maps?width=50%25&height=600&hl=en&q=' +
    latitude +
    ',' +
    longitude +
    '&t=&z=19&ie=UTF8&iwloc=B&output=embed'

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  const handleButtonClick2 = buttonName => {
    setActiveButton(buttonName)
  }

  useEffect(() => {
    let NewArray = []
    let occurrences = {}
    // Iterate over the adults and childs arrays
    for (let i = 0; i < ReduxSearchData.Adults.length; i++) {
      let adult = ReduxSearchData.Adults[i]
      let child = ReduxSearchData.children[i]
      // Create a unique identifier for the pair
      let pairKey = `${adult}-${child}`

      // Update the occurrences object
      occurrences[pairKey] = (occurrences[pairKey] || 0) + 1

      // Only add to the result if the current qty is the maximum
      if (occurrences[pairKey] === 1) {
        NewArray.push({
          adult,
          child,
          qty: occurrences[pairKey],
          status: false
        })
      } else {
        // Update the qty for the existing entry
        NewArray.find(
          entry => entry.adult === adult && entry.child === child
        ).qty = occurrences[pairKey]
      }
    }

    setResult(NewArray)
  }, [ReduxSearchData])
  // const selectedRoomData = (index, event) => {
  //   event.preventDefault()
  //   event.stopPropagation()

  //   if (selectedRooms.some(room => room.index === index)) {
  //     const selectedroom = hoteldetail.rooms_options[index]
  //     setCounter(counter - selectedroom.rooms_qty)
  //     // Check if there's a room with the same index in selectedRooms
  //     const updatedSelectedRooms = selectedRooms.filter(
  //       room => room.index !== index
  //     )
  //     const newroom = hotelSelectedRooms.filter(item => item.index !== index)
  //     // Update the selectedRooms state with the new array
  //     setSelectedRooms(updatedSelectedRooms)
  //     setHotelSelectedRooms(newroom)
  //   } else {
  //     if (counter < ReduxSearchData.room) {

  //       const selectedroom = hoteldetail.rooms_options[index]
  //       const key = { rateKey: selectedroom.booking_req_id, index: index }
  //       const newSelectedRoom = {
  //         room_rate_key: JSON.stringify(key),
  //         rooms_qty: selectedroom.rooms_qty,
  //         index: index,
  //         roomdetail:selectedroom.adults+selectedroom.childs
  //       }
  //       setCounter(counter + selectedroom.rooms_qty)
  //       setSelectedRooms([...selectedRooms, newSelectedRoom])
  //       setHotelSelectedRooms([...hotelSelectedRooms, key])
  //     } else {
  //       toast.error('Select the room according to the search criteria.', {
  //         position: toast.POSITION.TOP_RIGHT
  //     });
  //     }
  //   }
  // };
  const selectedRoomData = (index, event) => {
    event.preventDefault()
    event.stopPropagation()
    const selectedroom = hoteldetail.rooms_options[index]
    for (let i = 0; i < result.length; i++) {
      if (
        result[i].adult === selectedroom.adults &&
        result[i].child === selectedroom.childs &&
        result[i].qty === selectedroom.rooms_qty
      ) {
        if (result[i].status === false) {
          const key = { rateKey: selectedroom.booking_req_id, index: index }
          const newSelectedRoom = {
            room_rate_key: JSON.stringify(key),
            rooms_qty: selectedroom.rooms_qty,
            index: index
          }
          result[i].status = true
          setSelectedRooms([...selectedRooms, newSelectedRoom])
          setHotelSelectedRooms([...hotelSelectedRooms, key])
        } else {
          if (selectedRooms.some(room => room.index === index)) {
            const updatedSelectedRooms = selectedRooms.filter(
              room => room.index !== index
            )
            const newroom = hotelSelectedRooms.filter(
              item => item.index !== index
            )
            setSelectedRooms(updatedSelectedRooms)
            setHotelSelectedRooms(newroom)
            result[i].status = false
          } else {
            toast.error('Select the room according to the search criteria.', {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        }
      }
    }
  }
  const HandleMoreFacilities = () => {
    setShowMoreFacilities(showMoreFacilities + 10)
  }
  const BookRoom = async () => {
    if (hotelSelectedRooms.length !== 0) {
      if (hotelSelectedRooms.length !== result.length) {
        toast.error(
          'Please Select the room according to the search criteria.'
          
        )
        return
      }

      const roomdata = {
        rooms_select_data: JSON.stringify(selectedRooms),
        hotel_index: index,
        hotelbeds_select_room: hotelSelectedRooms
      }

      const finaldata = {
        token: apitoken,
        request_data: JSON.stringify(roomdata),
        selected_hotel: JSON.stringify(seleectedhotelfromlist[0]),
        selected_hotel_details: JSON.stringify({
          check_in: ReduxSearchData.check_in,
          check_out: ReduxSearchData.check_out,
          hotel_address: hoteldetail.hotel_address,
          hotel_country: hoteldetail.hotel_country,
          hotel_city: hoteldetail.hotel_city,
          latitude: hoteldetail.longitude,
          longitude: hoteldetail.latitude
        }),
        req_index: index
      }
      setLoading(true)
      try {
        const response = await Axios.post(
          endpoint + '/api/hotels/checkavailability_Live',
          finaldata,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              // Required for CORS support to work
              'Access-Control-Allow-Credentials': true // Required for cookies, authorization headers with HTTPS
              // 'Access-Control-Allow-Headers':
              //   'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
              // "Access-Control-Allow-Methods": "POST, OPTIONS"
            }
          }
        )
        // Handle the API response here
        if (response.data.status === 'error') {
          setLoading(false)
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          })
          return
        }
        if (response.data !== '') {
          dispatch(fetchCheckoutDetail(response.data.hotels_data))
          navigate('/hotel_checkout')
        }
        setLoading(false)
      } catch (error) {
        // Handle errors here
        setLoading(false)
        console.error('Error:', error)
      }
    } else {
      toast.error('Please Select Room First.', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  const renderPrice = (price,currency) => {
    var currencyData= JSON.parse(localStorage.getItem('AllHotelCurr'))
    var selectedcurr=localStorage.getItem('DefaultCurrency')
    if(selectedcurr===currency || currencyData===null){
      setShowPrice(false)
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2);
      }
      return Number(price)
    }else{
      var filterroomcurrdata=currencyData.filter(item=> item.base_code===currency);
      if (CurrencyRates === undefined) {
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName] // Use square brackets to access the property
        var baseprice = (Number(gbpprice) * Number(price))
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName]
        var baseprice1 = (Number(gbpprice) * Number(price))
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = (Number(gbpprice2) * Number(baseprice1))
      }
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2);
      }
      return baseprice
    }
  };
  const calculateMarkup = price => {
    if (Object.keys(hoteldetail).length !== 0) {
      let markupprice = 0
      let adminmarkupprice = 0
      let clientmarkupprice = 0
      let finalpricemarkup = Number(price)
      if (Number(hoteldetail.admin_markup) !== 0) {
        if (hoteldetail.admin_markup_type === 'Percentage') {
          markupprice = (price * Number(hoteldetail.admin_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.admin_markup)
        }
        adminmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(hoteldetail.customer_markup) !== 0) {
        if (hoteldetail.customer_markup_type === 'Percentage') {
          markupprice =
            (finalpricemarkup * Number(hoteldetail.customer_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.customer_markup)
        }
        clientmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(finalpricemarkup) % 1 !== 0) {
        return Number(finalpricemarkup).toFixed(2)
      }
      return finalpricemarkup
    }
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Layout logout={onLogout}>
        <ToastContainer/>
          <section class='py-0 pt-sm-5'>
            <div class='container position-relative'>
              <div class='row mb-3'>
                <div class='col-12'>
                  <div class='d-lg-flex justify-content-lg-between mb-1'>
                    <div class='mb-2 mb-lg-0'>
                      <h1 class='fs-2'>{hoteldetail.hotel_name}</h1>
                      <p class='fw-bold mb-0'>
                        <i class='bi bi-geo-alt me-2'></i>
                        {hoteldetail.hotel_address}
                        {/* <a class="ms-2 text-decoration-underline" data-bs-toggle="modal" data-bs-target="#mapmodal">
								<i class="bi bi-eye-fill me-1"></i>View On Map
							</a> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Images data={hoteldetail.hotel_gallery} />

          <section class='pt-0'>
            <div class='container' data-sticky-container>
              <div class='row g-4 g-xl-5'>
                <div class='col-xl-7 order-1'>
                  <div class='vstack gap-5'>
                    <div class='card bg-transparent'>
                      <div class='card-header border-bottom bg-transparent px-0 pt-0'>
                        <h3 class='mb-0'>About This Hotel</h3>
                      </div>

                      <div class='card-body pt-4 p-0'>
                        <h5 class='fw-light mb-4'>Main Highlights</h5>
                        <p class='mb-3'> {hoteldetail.description}</p>

                        <h5 class='fw-light fw-bold mb-2'>Segments </h5>
                        <ul class='list-group list-group-borderless mb-0'>
                          {hoteldetail.hotel_segments.map((item, index) => (
                            <li
                              key={index}
                              class='list-group-item h6 fw-light d-flex mb-0'
                            >
                              <i class='bi bi-patch-check-fill text-success me-2'>
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </i>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div class='card bg-transparent'>
                      <div class='card-header border-bottom bg-transparent px-0 pt-0'>
                        <h3 class='card-title mb-0'>Amenities</h3>
                      </div>

                      <div class='card-body pt-4 p-0'>
                        <div class='row g-4'>
                          {hoteldetail.hotel_facilities.length !== 0 &&
                            hoteldetail.hotel_facilities
                              .slice(0, showMoreFacilities)
                              .map((item, index) => (
                                <div key={index} class='col-sm-6 col-md-4'>
                                  <ul class='list-group list-group-borderless mt-2 mb-0'>
                                    <li class='list-group-item pb-0'>
                                      <i class='fa-solid fa-check-circle text-success me-2'></i>
                                      {item}
                                    </li>
                                  </ul>
                                </div>
                              ))}
                          {showMoreFacilities <
                            hoteldetail.hotel_facilities.length && (
                            <p
                              onClick={HandleMoreFacilities}
                              style={{ color: 'blue', cursor: 'pointer' }}
                            >
                              {' '}
                              View More{' '}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class='card bg-transparent' id='room-options'>
                      <div class='card-header border-bottom bg-transparent px-0 pt-0'>
                        <div class='d-sm-flex justify-content-sm-between align-items-center'>
                          <h3 class='mb-2 mb-sm-0'>Room Options</h3>
                        </div>
                      </div>
                      <div class='card-body pt-4 p-0'>
                        <div class='vstack gap-4'>
                          {hoteldetail.rooms_options.map((item, index) => (
                            <div key={index} class='card shadow p-3'>
                              <div class='row g-4'>
                                <div class='col-md-5 position-relative'>
                                  {item.room_supplier_code !== '' && (
                                    <div class='position-absolute top-0 start-0 z-index-1 mt-3 ms-4'>
                                      <div class='badge text-bg-danger'>
                                        {item.room_supplier_code}
                                      </div>
                                    </div>
                                  )}
                                  <div class='tiny-slider arrow-round arrow-xs arrow-dark overflow-hidden rounded-2'>
                                    <div
                                      class='tiny-slider-inner'
                                      data-autoplay='true'
                                      data-arrow='true'
                                      data-dots='false'
                                      data-items='1'
                                    >
                                      <div>
                                        {item.rooms_images &&
                                        item.rooms_images.length > 0 ? (
                                          <img
                                            style={{
                                              height: '12em',
                                              width: '100%',
                                              objectFit: 'cover'
                                            }}
                                            src={item.rooms_images[0]}
                                            alt={item.room_name}
                                          />
                                        ) : (
                                          <img
                                            style={{
                                              height: '12em',
                                              width: '100%',
                                              objectFit: 'cover'
                                            }}
                                            src={noImage}
                                            alt='Card image'
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class='col-md-7'>
                                  <div class='card-body d-flex flex-column h-100 p-0'>
                                    <h5 class='card-title'>
                                      <a>
                                        {' '}
                                        {item.room_name} ({item.board_id})
                                      </a>
                                    </h5>

                                    {item.rooms_facilities &&
                                    item.rooms_facilities.length > 0 ? (
                                      <RoomFacilities item={item} />
                                    ) : null}
                                    {item.request_type === '1' && (
                                      <div className='room-request'>
                                        <h6>Room on Request</h6>
                                      </div>
                                    )}
                                    <div class='d-flex justify-content-between mt-2'>
                                      <h6>{item.adults} Adults </h6>
                                      <h6> {item.childs} children</h6>
                                      <h6> {item.rooms_qty} Rooms</h6>
                                    </div>
                                    {item.cancliation_policy_arr &&
                                    item.cancliation_policy_arr.length > 0 ? (
                                      new Date(
                                        item.cancliation_policy_arr[0].from_date
                                      ) > todayDate ? (
                                        <p class='text-success  mb-2 mt-2'>
                                          Free Cancellation till{' '}
                                          {moment(item.cancliation_policy_arr[0].from_date).subtract(1, 'days').utc().format('ll')} <br/>No credit card needed
                                        </p>
                                      ) : (
                                        <p class='text-danger text-center mb-2 mt-2'>
                                          Non-Refundable
                                        </p>
                                      )
                                    ) : (
                                      <p class='text-danger text-center mb-2 mt-2'>
                                        Non-Refundable
                                      </p>
                                    )}
                                    <div class='d-sm-flex justify-content-sm-between align-items-center mt-auto'>
                                      <div class='d-flex align-items-center'>
                                        {showPrice ? (
                                          <h5 class='fw-bold mb-0 me-1'>
                                            {CurrencyRates === undefined
                                              ? baseCName
                                              : CurrencyRates.selectedcurrency}{' '}
                                            {renderPrice(
                                              calculateMarkup(
                                                item.rooms_total_price
                                              ),
                                              hoteldetail.hotel_curreny
                                            )}
                                          </h5>
                                        ) : (
                                          <h5 class='fw-bold mb-0 me-1'>
                                            {hoteldetail.hotel_curreny}{' '}
                                            {calculateMarkup(
                                              item.rooms_total_price
                                            )}
                                          </h5>
                                        )}
                                        <span class='mb-0 me-2'>
                                          / {daysBetween} Nights
                                        </span>
                                        {/* <span class="text-decoration-line-through mb-0">$1000</span> */}
                                      </div>
                                      <div class='mt-3 mt-sm-0'>
                                        {hotelSelectedRooms.some(
                                          item => item.index === index
                                        ) ? (
                                          <p
                                            onClick={event =>
                                              selectedRoomData(index, event)
                                            }
                                            class='btn btn-sm btn-danger mb-0'
                                          >
                                            Remove
                                          </p>
                                        ) : (
                                          <p
                                            onClick={event =>
                                              selectedRoomData(index, event)
                                            }
                                            class='btn btn-sm btn-primary mb-0'
                                          >
                                            Select Room
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <aside class='col-xl-5 order-xl-2'>
                  <div
                    class='sticky-element'
                    data-sticky
                    data-margin-top='100'
                    data-sticky-for='1199'
                  >
                    <div class='card card-body border'>
                      <div class='d-sm-flex justify-content-sm-between align-items-center mb-3'>
                        <div>
                          <h5 class='card-title mb-0'>Selected Rooms</h5>
                        </div>
                      </div>
                      {hotelSelectedRooms.length === 0 ? (
                        <div>
                          <p className='text-center'>No Room Selected.</p>
                        </div>
                      ) : (
                        <>
                          {hotelSelectedRooms.map((item, index) => (
                            <div className='d-flex justify-content-between align-items-center'>
                            <p key={index}>
                              {index+1+')'} {hoteldetail.rooms_options[item.index].room_name}
                              {/* Adjust this to display the relevant property */}
                            </p>
                            {showPrice ? (
                              <>
                               {CurrencyRates === undefined
                                ? baseCName
                                : CurrencyRates.selectedcurrency}{' '}
                                 {renderPrice(
                              calculateMarkup(
                                hoteldetail.rooms_options[item.index].rooms_total_price),
                              hoteldetail.hotel_curreny
                            )}
                                </>
                            ):(
                              <>
                              {hoteldetail.hotel_curreny} {hoteldetail.rooms_options[item.index].rooms_total_price} 
                              </>
                            )}
                          </div>
                          ))}
                        </>
                      )}

                      <div className='d-flex justify-content-between align-items-center mt-2'>
                        <h6>Total:</h6>
                        {showPrice ? (
                          <h6 >
                            {CurrencyRates === undefined
                              ? baseCName
                              : CurrencyRates.selectedcurrency}{' '}
                            {renderPrice(
                              calculateMarkup(
                                hotelSelectedRooms.reduce(
                                  (sum, item) =>
                                    sum +
                                    Number(
                                      hoteldetail.rooms_options[item.index]
                                        .rooms_total_price
                                    ),
                                  0
                                )
                              ),
                              hoteldetail.hotel_curreny
                            )}
                          </h6>
                        ) : (
                          <h6 class='fw-bold mb-0 me-1'>
                            {hoteldetail.hotel_curreny}{' '}
                            {calculateMarkup(hotelSelectedRooms.reduce(
                            (sum, item) =>
                              sum +
                              Number(
                                hoteldetail.rooms_options[item.index]
                                  .rooms_total_price
                              ),
                            0
                          ))}
                          </h6>
                        )}
                      </div>
                      <div class='d-grid mt-2'>
                        <p  onClick={BookRoom} class='btn btn-lg btn-primary-soft mb-0'>Book Now</p>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </>
  )
}

export default HotelDetail
