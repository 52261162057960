import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Style/NewStyle.css'
import 'rsuite/dist/rsuite.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import logo from '../src/Images/newlogo.png'
 import { BrowserRouter as Router, Route, Routes,Switch  } from "react-router-dom";
import Contact from './Pages/Contactus/ContactusNew';
import About from './Pages/Aboutus/AboutusNew';

import Hotels from './Pages/Hotels/Hotel';
import HotelDetail from './Pages/Hotels/HotelDetail';
import BookRoom from './Pages/BookRoom/BookRoom';

import FaqPage from './Pages/FooterPages/faqPage';
import PrivacyPolicy from './Pages/FooterPages/PrivacyPolicyPage';
import TermsConditions from './Pages/FooterPages/TermsConditions';
import ComplaintPolicy from './Pages/FooterPages/ComplaintPolicy';
import Confirmation from './Pages/BookingConfirmation/Confirmation';
import HomeNew from './Pages/Home/HomeNew';
import Login from './Pages/AuthPages/Login';
import Dashboard from './Pages/Dashboard/index'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import HotelInvoice from './Pages/BookingConfirmation/HotelInvoice';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  
  if(!token) {
    return <Login setToken={setToken} />
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(false);
  };
  return (
   <>
   <FloatingWhatsApp
      phoneNumber="+447309803307"
      accountName="AlHijaz Rooms"
      statusMessage="Online"
      chatMessage="Hello! How can we help you today?"
      avatar={logo}
      style={{ left: '15px', right: 'auto' }}
      notification
    />
   <Router>
    <Routes>
    <Route path="/login" element={<Login />} />
      <Route path="/" element={<HomeNew onLogout={handleLogout}/>} />
      <Route path="/contact-us" element={<Contact onLogout={handleLogout}/>} />
      <Route path="/about-us" element={<About />} onLogout={handleLogout}/>
      <Route path="/hotels" element={<Hotels onLogout={handleLogout}/>} />
      <Route path="/hotel_detail/:id" element={<HotelDetail onLogout={handleLogout}/>} />
      <Route path="/hotel_checkout" element={<BookRoom onLogout={handleLogout}/>} />
      <Route path="/faqs" element={<FaqPage onLogout={handleLogout} />} />
      <Route path="/complaint_policy" element={<ComplaintPolicy onLogout={handleLogout}/>} />
      <Route path="/privacy_policy" element={<PrivacyPolicy onLogout={handleLogout}/>} />
      <Route path="/terms_and_conditions" element={<TermsConditions onLogout={handleLogout}/>} />
      <Route path="/hotel_booking_invoice/:id" element={<HotelInvoice />} />
      <Route path="/hotel_voucher/:id" element={<Confirmation/>} />
      <Route path="/agent_dashboard/*" element={<Dashboard onLogout={handleLogout} />} />
    </Routes>
   
    </Router>
   
   </>
  );
}

export default App;
